import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { ArrowRightIcon } from "@mui/x-date-pickers";

const SelectedListItemButton = styled(ListItemButton)(({ theme }) => ({
  // backgroundColor: "#002868",
  color: "#fff",
  paddingRight: "0px",
  paddingLeft: "0px",
  "&:hover": {
    backgroundColor: "#003a99",
  },
  "&.Mui-selected": {
    // backgroundColor: "#003a99",
    // "&:hover": {
    //   backgroundColor: "#004bb3",
    // },
  },
}));
const SmallListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "30px", // Adjust the size as needed
  "& .MuiSvgIcon-root": {
    fontSize: "30px", // Adjust the icon size as needed
  },
}));
const EachAssignmentSidebar = ({
  assignment,
  index,
  setLessonIndex,
  setArrowIndex,
  arrowIndex,
  asIndex,
  lessonIndex,
}) => {
  const { courseId } = useParams();
  const navigate = useNavigate();

  // // const course = useSelector(state => getCourseById(state, courseId))
  // const lessonIds =
  //   (course?.assignedLessons && Object.keys(course.assignedLessons)) || [];
  // const lessons = useSelector((state) => getLessonsByIds(state, lessonIds));

  // const lessonWithAssignemt = lessons.filter(
  //   (lesson) => lesson?.assignedAssignments
  // );
  // const assignmentsId = lessonWithAssignemt
  //   .map((lesson) => Object.keys(lesson.assignedAssignments))
  //   .flat();

  // const assignments = useSelector((state) =>
  //   getAssignmentsByIds(state, assignmentsId)
  // );
  // const assignments = useSelector((state) =>
  //     getAssignmentsByIds(
  //       state,
  //       lesson.assignedAssignments ? Object.keys(lesson.assignedAssignments) : []
  //     )
  //   );
  const changeAssignment = (as, index) => {
    setLessonIndex(index);
    setArrowIndex(asIndex + 1);
    navigate(`assignments/${courseId}/${as.info.assignmentId}`);
  };

  return (
    <div>
      {/* {assignments?.map((as, index) => ( */}
      {/* <div
        className="flex justify-between"
        onClick={() => {
          changeAssignment(assignment, index);
        }}
      > */}
      <SelectedListItemButton
        onClick={() => {
          changeAssignment(assignment, index);
        }}
        // selected={lessonIndex === index}
      >
        <SmallListItemIcon>
          {arrowIndex === asIndex + 1 && lessonIndex === index && (
            <ArrowRightIcon sx={{ color: "white" }} />
          )}
        </SmallListItemIcon>
        <ListItemText primary={assignment.info.title} />
      </SelectedListItemButton>

      {/* <Button
        sx={{ paddingY: "10px", width: "100%", color: "white" }}
        size="small"
      >
        {assignment?.info?.title}
      </Button> */}
      {assignment?.releaseDate.date > Date.now() ||
      !assignment?.releaseDate.immediately ? (
        <LockIcon className="text-cu-red" style={{ fontSize: "20px" }} />
      ) : (
        ""
      )}
      {/* </div> */}
    </div>
  );
};

export default EachAssignmentSidebar;
