import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { readSingleRecord } from "../../firebase.config";
import {
  addCourse,
  addMultipleCourse,
  getCourseIds,
} from "../../redux/coursesSlice";

export const useLoadMultipleCourse = (trigger, ids) => {
  const dispatch = useDispatch();
  const courseIds = useSelector(getCourseIds); // Adjust according to your state shape

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("object");
    if (!trigger) return;
    const idsToGet = ids.filter((id) => !courseIds.includes(id));
    const readMultipleCourse = async () => {
      setLoading(true);
      try {
        const coursesPromise = idsToGet.map(async (id) => {
          return await readSingleRecord({ tn: "courses2", recId: id });
        });
        const courses = await Promise.all(coursesPromise);
        dispatch(addMultipleCourse(courses));
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    readMultipleCourse();
  }, [courseIds, dispatch, ids, trigger]);

  return loading;
};
