import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const groupsAdapter = createEntityAdapter();

const initialState = groupsAdapter.getInitialState();

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    addgroups: groupsAdapter.setAll,
    addgroup: groupsAdapter.addOne,
    removegroup: groupsAdapter.removeOne,
    updateGroup: groupsAdapter.updateOne,
  },
});

export const { addgroups, updateGroup, addgroup, removegroup } =
  groupsSlice.actions;
export const getAllGroups = createSelector(
  [(state) => state.groups],
  (groupsState) =>
    groupsAdapter
      .getSelectors()
      .selectAll(groupsState)
      .sort((a, b) => a.id - b.id)
);

export const getGroupById = (state, id) =>
  groupsAdapter.getSelectors().selectById(state.groups, id);

export const getGroupByField = (state, fieldName, fieldValue) => {
  return state.groups.entities
    ? Object.values(state.groups.entities).find(
        (group) => group[fieldName] === fieldValue
      )
    : undefined;
};

export default groupsSlice.reducer;
