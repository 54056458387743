// import React, { useEffect, useState } from "react";
// import { createEmptyCourseInDb } from "../../../../firebase.config";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   addOtherInfo,
//   getCourseById,
//   updateCourseStatus,
// } from "../../../../redux/coursesSlice";
// import { useParams } from "react-router-dom";
// import InputImageStorage from "./InputImageStorage";
// import { Button } from "@mui/material";
// import UploadFileComp from "../../sharedComp/UploadFileComp";

// const CourseImageSettings = ({ setImageTempUrl, imageTempUrl }) => {
//   const { recordId } = useParams();
//   const currentCourse = useSelector((state) => getCourseById(state, recordId));
//   const courseImage = currentCourse?.course_image;
//   const dispatch = useDispatch();
//   // const [imageUrl, setImageUrl] = useState(currentCourse.files.courseImage);
//   //   const [fileInMemory, setFileInMemory] = useState("");
//   const [changeImage, setChangeImage] = useState(false);

//   // const uploadPhoto = () => {
//   // useEffect(() => {
//   //     // if (!fileInMemory) return
//   //     // console.log("ran")
//   // }, [dispatch, fileInMemory, profile.uid, profileUrl])
//   // }
//   // useEffect(() => {
//   //   if (!imageUrl) return;
//   //   dispatch(addCourseImage(imageUrl));
//   // }, [dispatch, imageUrl]);
//   console.log(courseImage);
//   return (
//     <div className="flex flex-col gap-y-2  w-full">
//       {courseImage ? (
//         <img src={courseImage[0].thumbnails?.large.url} />
//       ) : (
//         <div>
//           jpeg, jpg, png, gif, svg, bmp, tif, tiff, heic format would be
//           acceptable
//           <UploadFileComp
//             acceptedType={
//               ".jpeg, .jpg, .png, .gif, .svg, .bmp, .tif, .tiff, .heic"
//             }
//             tempFileUrl={imageTempUrl}
//             setTempFileUrl={setImageTempUrl}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default CourseImageSettings;
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  CardActions,
} from "@mui/material";
import UploadFileComp from "../../sharedComp/UploadFileComp";
import { getCourseById } from "../../../../redux/coursesSlice";
import LogoImage from "../../resourses/LogoImage";
import { simplifyAttachmentType } from "../../../../shared/utils/simplifyAttachmentType";
import LogoPdf from "../../resourses/LogoPdf";

const CourseImageSettings = ({
  setTempUrl,
  tempUrl,
  initFile,
  acceptedType,
}) => {
  const { recordId } = useParams();
  const dispatch = useDispatch();
  const currentCourse = useSelector((state) => getCourseById(state, recordId));

  const [isEditing, setIsEditing] = useState(false);

  const handleChangeImage = () => {
    setIsEditing(true);
  };
  const handleCancle = () => {
    if (tempUrl) {
      if (
        window.confirm("if you proceed your uploaded file will be aborted") ===
        false
      )
        return;
    }
    setTempUrl("");
    setIsEditing(false);
  };
  console.log(initFile[0]?.type);
  return (
    <Box className="flex flex-col">
      {initFile && !isEditing ? (
        <Card className="shadow-lg rounded-md flex flex-col items-end">
          {initFile[0]?.type &&
          simplifyAttachmentType(initFile[0].type) === "image" ? (
            <LogoImage attachments={initFile} size={300} />
          ) : (
            <LogoPdf attachments={initFile} size={300} />
          )}
          <CardActions>
            <Button
              className="justify-end"
              variant="outlined"
              color="primary"
              onClick={handleChangeImage}
              size="small"
            >
              Change File
            </Button>
          </CardActions>
        </Card>
      ) : (
        <Box className="flex flex-col">
          <Typography variant="body2" color="textSecondary" className="mb-2">
            Acceptable formats: {acceptedType}
          </Typography>
          <div className="self-start">
            <UploadFileComp
              acceptedType={acceptedType}
              tempFileUrl={tempUrl}
              setTempFileUrl={setTempUrl}
            />
          </div>
          {initFile && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancle}
              className="mt-4 w-20 self-end"
            >
              Cancel
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CourseImageSettings;
