import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  getUsersByGroupcode,
  getUsersByIds,
} from "../../../redux/usersSlice";

import {
  Select,
  MenuItem,
  Button,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {} from "@mui/material";
import { getCoursesByIds } from "../../../redux/coursesSlice";

import EachGradeToStudentRow from "./EachGradeToStudentRow";
import UserReportInfo from "./UserReportInfo";
import ReportCardSelect from "./ReportCardSelect";
import { useLoadCourses } from "../../../shared/customHooks/useLoadCourses";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";

const GradeToStudent = () => {
  // const [courseName, setCourseName] = useState("");
  const user = useSelector((state) => state.user);
  const role = user.profile.role;
  // const instructorCoursesId = user.assignedCourses
  //   ? Object.keys(user.assignedCourses)
  //   : [];

  // const instructorCourses = useSelector((state) =>
  //   getCoursesByIds(state, instructorCoursesId)
  // );

  // const instructorStudentId = [];
  // instructorCourses?.forEach((course) => {
  //   const courseStudents = course?.assignedStudents
  //     ? Object.keys(course.assignedStudents)
  //     : [];

  //   courseStudents.forEach((student) => {
  //     !instructorStudentId.includes(student) &&
  //       instructorStudentId.push(student);
  //   });
  //   // instructorCourses.includes();
  // });

  // const instructorUsersUnfiltered = useSelector((state) =>
  //   getUsersByIds(state, instructorStudentId)
  // );
  // const instructorUsers = instructorUsersUnfiltered.filter(
  //   (user) => user !== undefined
  // );

  const [selectedUser, setSelectedUser] = useState(
    role === "student" ? user : null
  );
  const users = useSelector(getAllUsers);

  // const userInOneGroup = useSelector((state) =>
  //   getUsersByGroupcode(state, user.profile.groupCode)
  // );

  const [assignedCourses, setAssignedCourses] = useState(
    selectedUser?.assignedCourses && Object.values(selectedUser.assignedCourses)
  );

  const changeUser = useCallback((e) => {
    const user = users.find((user) => user.profile.uid === e.target.value);
    setSelectedUser(user);
    if (user?.assignedCourses) {
      // if (role === "instructor") {
      //   const coursesToShow = [];
      //   Object.keys(user.assignedCourses).forEach((course) => {
      //     Object.keys(user.assignedCourses).includes(course) &&
      //       coursesToShow.push(course);
      //   });
      //   setAssignedCourses(coursesToShow);
      // } else if (role === "admin") {
      //   setAssignedCourses(Object.keys(user.assignedCourses));
      // }
      setAssignedCourses(Object.values(user.assignedCourses));
    } else {
      setAssignedCourses([]);
    }
  }, []);

  return (
    <div className=" m-auto mt-20">
      {role !== "student" && (
        <ReportCardSelect
          changeUser={changeUser}
          users={
            // role === "admin"
            //   ?
            users
            // : role === "leadInstructor" || role === "instructor"
            // ? instructorUsers
            // : []
          }
        />
      )}
      <Container>
        {selectedUser && <UserReportInfo item={selectedUser} />}
      </Container>

      <Container>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Instructor</TableCell>
                <TableCell>Publish Date</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Grade</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignedCourses?.map((asCourse) => (
                <EachGradeToStudentRow
                  asCourse={asCourse}
                  selectedUser={selectedUser}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default GradeToStudent;
