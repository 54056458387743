import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  assignCourseToSt,
  removeAssignedCourseToSt,
} from "../../../firebase.config";
import {
  Box,
  Typography,
  Switch,
  Card,
  FormControlLabel,
  duration,
} from "@mui/material";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";
import { useSelector } from "react-redux";
import { getGroupByField } from "../../../redux/groupsSlice";
import { getUserById } from "../../../redux/usersSlice";
import { serverTimestamp } from "firebase/database";

const EachCourseAssign = ({ course, selectedUser }) => {
  // Helper to determine the initial assigned state
  const user = useSelector((state) => state.user);
  const instructor = useSelector((state) =>
    getUserById(state, course?.assigned_instructor)
  );
  const group = useSelector((state) =>
    getGroupByField(state, "code", course?.group_code)
  );

  const assignedCourses = selectedUser.assignedCourses
    ? Object.values(selectedUser.assignedCourses)
    : [];
  // const determineAssignedState = () => {
  //   const userId = selectedUser?.profile?.uid;
  //   if (!userId) return false;
  //   return (

  //     // (course?.assigned_students &&
  //     //   course.assigned_students.includes(userId)) ||
  //     // (course?.assignedInstructor &&
  //     //   course.assigned_instructors.includes(userId))
  //   );
  // };

  const [assigned, setAssigned] = useState(
    assignedCourses.some((crs) => crs.id === course.id)
  );
  const [loading, setLoading] = useState(false);

  // Update assigned state when `selectedUser` or `course` changes
  // useEffect(() => {
  //   setAssigned(determineAssignedState());
  // }, [selectedUser, course]);

  const handleToggle = async () => {
    if (!selectedUser?.profile?.uid || !course) {
      toast.error("Invalid user or course information");
      return;
    }

    setLoading(true); // Show loading overlay
    try {
      if (assigned) {
        // Unassign the course
        await removeAssignedCourseToSt({
          user: selectedUser,
          recId: course.id,
          courseId: course.course_id,
        });
        toast.success("Course unassigned successfully!");
      } else {
        // Assign the course
        const courseData = {
          id: course.id,
          Cours_code: course.title,
          Cours_Name: course.name,
          Course_ID: course.course_id,
          Number: course.course_id,
          L1_video_link: "",
          Lead_Instructor: instructor
            ? instructor.profile?.name + " " + instructor.profile?.family
            : "",
          Major: group.e_title,
          course_material_pdf:
            (course?.course_material && course?.course_material[0].url) || "",
          description: course?.explain,
          image_Cours:
            (course?.course_image && course?.course_image[0].url) || "",
          icon_Cours: (course?.icon_course && course?.icon_course[0].url) || "",
          major_code: course.group_code,
          duration: course.duration,
          duration_unit: course.duration_unit,
          unit: course.unit,
          level: course.level,
          date_assigned: serverTimestamp(),
          course_assigner_email: user.profile.email,
        };
        if (selectedUser.profile.role === "student") {
          courseData.Grade = "";
          courseData.grade_registerer_email = "";
          courseData.grade_registeration_date = "";
        }

        await assignCourseToSt({ user: selectedUser, course: courseData });
        toast.success("Course assigned successfully!");
      }
      setAssigned(!assigned); // Toggle the assigned state
    } catch (error) {
      console.error("Error updating course assignment:", error);
      toast.error(error.message || "Failed to update course assignment.");
    } finally {
      setLoading(false); // Hide loading overlay
    }
  };

  return (
    <>
      <LoadingOverlay
        loading={loading}
        message="Updating course assignment..."
      />
      <Card
        sx={{
          padding: 2,
          margin: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          borderRadius: 4,
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "primary.main" }}
          >
            {course?.course_id}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {course?.title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {course?.name}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControlLabel
            control={
              <Switch
                checked={assigned}
                onChange={handleToggle}
                color="primary"
              />
            }
            label={assigned ? "Unassign" : "Assign"}
            labelPlacement="start"
            sx={{
              "& .MuiTypography-root": {
                fontWeight: "bold",
                fontSize: "0.9rem",
                color: assigned ? "error.main" : "primary.main",
              },
            }}
          />
        </Box>
      </Card>
    </>
  );
};

export default EachCourseAssign;
