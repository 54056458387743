const PdfShow = ({ pdf }) => {
  // useEffect(() => {
  //   const controller = new AbortController();
  //   const { signal } = controller;
  //   const getBlob = async () => {
  //     const bbb = await getUrlBlob(pdf?.fileUrl, signal, setProgress);
  //     setBlob(bbb);
  //   };
  //   getBlob();
  //   return () => {
  //     controller.abort();
  //   };
  // }, [pdf?.fileUrl]);

  return (
    <div className="flex flex-col w-full h-full z-20">
      {/* {blob ? ( */}
      <embed type="application/pdf" src={pdf?.url} className="w-full h-full" />
      {/* ) : (
        <div className="m-auto">
          <CircularProgress disableShrink sx={{ color: "#002868" }} />
        </div>
      )} */}
      {/* <div className="border-t-2 w-full flex items-center justify-center">
        {pdf?.pdfId && (
          <ShowModalGroupButton
            itemId={pdf?.pdfId}
            type={"pdf"}
            itemCreatorId={pdf?.creatorId}
            groupCode={pdf?.groupCode}
          />
        )}
      </div> */}
    </div>
  );
};

export default PdfShow;
