import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { readTable } from "../../firebase.config";
import { addCourses, getAllCourses } from "../../redux/coursesSlice";

export const useLoadCourses = (trigger) => {
  const dispatch = useDispatch();
  const allCourses = useSelector(getAllCourses); // Adjust according to your state shape
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (allCourses && allCourses.length) return;
    if (!trigger) return;
    const readCoursesTable = async () => {
      setLoading(true);
      try {
        const courses = await readTable({ tn: "courses2" });
        dispatch(addCourses(courses));
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    readCoursesTable();
  }, [allCourses, dispatch, trigger]);

  return loading;
};
