import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
const EachLessonContentDrip = ({
  lesson,
  setLessonDateList,
  lessonDateList,
}) => {
  // const [selectedDate, setSelectedDate] = useState(
  //   lesson?.release_date && new Date(lesson?.release_date)
  // );
  const [immediately, setImmediately] = useState(
    new Date(lesson?.release_date).getTime() <= Date.now() ? true : false
  );

  const changePublishType = (e) => {
    setImmediately(!immediately);
    if (!immediately) {
      setLessonDateList((prev) => ({ ...prev, [lesson.id]: new Date() }));
    }
    // if (e.target.value === "immediately") {
    //   setImmediately(true);
    //   currentImmediate = true;
    // } else {
    //   setImmediately(false);
    //   currentImmediate = false;
    // }
    // setLessonDateList((prev) => {
    //   const lessonInList = prev?.find((ll) => ll.id === lesson.info.lessonId);

    //   if (lessonInList && currentImmediate === lesson.releaseDate.immediately) {
    //     return prev?.filter((ll) => ll.id !== lesson.info.lessonId);
    //   } else if (
    //     !lessonInList &&
    //     currentImmediate !== lesson.releaseDate.immediately
    //   ) {
    //     return [
    //       ...prev,
    //       {
    //         id: lesson.id,
    //         date: Date.now(selectedDate),
    //         immediately: currentImmediate,
    //       },
    //     ];
    //   } else {
    //     return prev;
    //   }
    // });
  };

  const changeDate = (date) => {
    setLessonDateList((prev) => ({ ...prev, [lesson.id]: new Date(date) }));
    // if (!immediately) {
    //   setSelectedDate(new Date(date));
    //   setLessonDateList((prev) => {
    //     const lessonInList = prev?.find((ll) => ll.id === lesson.info.lessonId);

    //     if (lessonInList && date.getTime() === lesson?.releaseDate.date) {
    //       return prev?.filter((ll) => ll.id !== lesson.info.lessonId);
    //     } else if (
    //       lessonInList &&
    //       date.getTime() !== lesson?.releaseDate.date
    //     ) {
    //       return prev?.map((ll) =>
    //         ll.id === lesson.info.lessonId
    //           ? {
    //               ...ll,
    //               date: date.getTime(),
    //             }
    //           : ll
    //       );
    //     } else if (
    //       !lessonInList &&
    //       date.getTime() !== lesson?.releaseDate.date
    //     ) {
    //       return [
    //         ...prev,
    //         {
    //           id: lesson.info.lessonId,
    //           date: date.getTime(),
    //           immediately,
    //         },
    //       ];
    //     } else {
    //       return prev;
    //     }
    //   });
    // }
  };

  //   useEffect(() => {

  //   }, [
  //     immediately,
  //     lesson.info.lessonId,
  //     lesson.releaseDate.immediately,
  //     selectedDate,
  //     setLessonDateList,
  //   ]);

  return (
    <Box p={2} borderBottom={1} borderColor="divider">
      <Grid container justifyContent="space-between" alignItems="center">
        {/* Lesson Details */}
        <Grid item xs={12} sm={5} md={4}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">{lesson?.lesson_id}</Typography>
            <Typography variant="body1" fontWeight="bold">
              {lesson?.title}
            </Typography>
          </Box>
        </Grid>

        {/* Publish Options */}
        <Grid item xs={12} sm={7} md={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControl
              variant="outlined"
              size="small"
              className="w-36 shrink-0"
            >
              <InputLabel id={`publish-type-label-${lesson?.lesson_id}`}>
                Publish Type
              </InputLabel>
              <Select
                labelId={`publish-type-label-${lesson?.lesson_id}`}
                value={immediately ? "immediately" : "specificDate"}
                onChange={changePublishType}
                label="Publish Type"
              >
                <MenuItem value="immediately">Published</MenuItem>
                <MenuItem value="specificDate">Specific Date</MenuItem>
              </Select>
            </FormControl>
            {!immediately && (
              <DatePicker
                selected={
                  (lessonDateList[lesson.id] &&
                    new Date(lessonDateList[lesson.id])) ||
                  (lesson?.release_date && new Date(lesson?.release_date)) ||
                  new Date()
                }
                onChange={(date) => {
                  changeDate(date);
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="Pp"
              />
              // <LocalizationProvider dateAdapter={AdapterDateFns}>
              //   <DateTimePicker
              //     value={selectedDate}
              //     onChange={(date) => changeDate(date)}
              //     renderInput={(props) => (
              //       <Box ml={2} width="100%">
              //         <TextField {...props} fullWidth size="small" />
              //       </Box>
              //     )}
              //   />
              // </LocalizationProvider>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EachLessonContentDrip;
{
  /* <div className="flex flex-col">
<div className="flex justify-between py-3">
  <div className="flex justify-around w-96">
    <p>{lesson?.lesson_id}</p>
    <p>{lesson?.title}</p>
  </div>
  <div className="w-96 flex justify-between">
    <select
      onChange={changePublishType}
      defaultValue={immediately ? "immediately" : "specificDate"}
    >
      <option value="immediately">Published</option>
      <option value="specificDate">Specific Date</option>
    </select>
    {!immediately ? (
      <DatePicker
        selected={
          (lessonDateList[lesson.id] &&
            new Date(lessonDateList[lesson.id])) ||
          (lesson?.release_date && new Date(lesson?.release_date)) ||
          new Date()
        }
        onChange={(date) => {
          changeDate(date);
        }}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="Pp"
      />
    ) : (
      <p></p>
    )}
  </div>
</div>
</div> */
}
