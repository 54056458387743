import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CrouseGeneralSettings from "./courseSettings/CrouseGeneralSettings";
import {
  createSectionId,
  createSingleRecord,
  pollAttachmentMetadata,
  updateSignleRecord,
} from "../../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addCourse, getCourseById } from "../../../redux/coursesSlice";
import { Button } from "@mui/material";
import TextAreaComponent from "../sharedComp/TextAreaComponent";
import { areArraystEqual } from "../../../shared/utils/areArraystEqual";

const AddNewCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { recordId } = useParams();
  const user = useSelector((state) => state.user);
  let course = useSelector((state) => getCourseById(state, recordId));
  const {
    course_id,
    active: initActive = false,
    unit: initUnit = "",
    categories: initCategories = "",
    necessity: initNecessity = "optional",
    duration: initDuration = "",
    duration_unit: initDurationUnit = "week",
    prerequisites: initPrerequisites = [],
    assigned_instructor: initAssignedInstructor = "",
    level: initLevel = "",
    title: initTitle = "",
    name: initName = "",
    name_direction: initNameDirection = "",
    title_direction: initTitleDirection = "",
    explain: initExplain = "",
    group_code: initGroupCode = "",
    creator_uid: initCreatorUid = "",
    create_at: initCreateAt = "",
    edit_at: initEditAt = "",
    course_image: initCourseImage = "",
    course_material: initCourseMaterial = "",
    assigned_lessons: initAssignedLessons = [],
  } = { ...course } || {};
  const [materialTempUrl, setMaterialTempUrl] = useState("");
  const [imageTempUrl, setImageTempUrl] = useState("");
  const [active, setActive] = useState(course?.active || false);
  const [unit, setUnit] = useState(initUnit);
  const [level, setLevel] = useState(initLevel);
  const [categories, setCategories] = useState(initCategories);
  const [necessity, setNecessity] = useState(initNecessity);
  const [duration, setDuration] = useState(initDuration);
  const [durationUnit, setDurationUnit] = useState(initDurationUnit);
  const [prerequisites, setPrerequisites] = useState(initPrerequisites);
  const [assignedInstructor, setAssignedInstructor] = useState(
    initAssignedInstructor
  );
  const [assignedLessons, setAssignedLessons] = useState(initAssignedLessons);
  const [groupCode, setGroupCode] = useState(
    initGroupCode ||
      (user.profile.role === "instructor" ? user.profile.group_code : null)
  );
  const [titleDirection, setTitleDirection] = useState(
    initTitleDirection || "rtl"
  );

  const location = useLocation();

  const lastpart =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const [title, setTitle] = useState(initTitle);
  const [explain, setExplain] = useState(initExplain);
  const [nameDirection, setNameDirection] = useState(
    initNameDirection || "rtl"
  );
  const [name, setName] = useState(initName);
  // const [courseId, setCourseId] = useState(course.info.courseId);

  const publishFunc = async () => {
    if (!recordId) {
      await createNewCourse();
    } else {
      await editCourse();
    }
  };

  const createNewCourse = async () => {
    const data = {
      active,
      unit: +unit,
      categories: categories.length ? categories.join(", ") : "",
      necessity,
      duration: +duration,
      duration_unit: durationUnit,
      prerequisites: prerequisites.filter((p) => p !== undefined),
      assigned_instructor: assignedInstructor,
      level,
      title,
      name,
      name_direction: nameDirection,
      title_direction: titleDirection,
      explain,
      group_code: groupCode,
      creator_uid: user.profile.uid,
      course_image: [{ url: imageTempUrl }],
      course_material: [{ url: materialTempUrl }],
      assigned_lessons: assignedLessons,
    };
    try {
      data.course_id = await createSectionId({ section: "courses" });
      const newRec = await createSingleRecord({ tn: "courses2", data });
      if (newRec) {
        const record = pollAttachmentMetadata({
          tn: "courses2",
          recId: newRec.id,
          attachmentNames: ["course_image", "course_material"],
        });
        dispatch(addCourse(record));
        navigate(`/${user.profile.role}panel/lessons`);
      }
      navigate(`/${user.profile.role}panel/courses`);
    } catch (error) {
      console.log(error);
    }
  };

  const editCourse = async () => {
    if (title.length < 3) {
      toast.error("title must have 4 letter or more");
      return;
    }
    const data = {};

    if (initGroupCode !== groupCode) {
      data.group_code = groupCode;
    }
    if (initExplain !== explain) {
      data.explain = explain;
    }
    if (initTitleDirection !== titleDirection) {
      data.title_direction = titleDirection;
    }
    if (initNameDirection !== nameDirection) {
      data.name_direction = nameDirection;
    }
    if (initName !== name) {
      data.name = name;
    }
    if (initTitle !== title) {
      data.title = title;
    }
    if (initLevel !== level) {
      data.level = level;
    }
    if (initAssignedInstructor !== assignedInstructor) {
      data.assigned_instructor = assignedInstructor;
    }
    if (!areArraystEqual(initPrerequisites, prerequisites)) {
      data.prerequisites = prerequisites;
    }
    if (!areArraystEqual(initAssignedLessons, assignedLessons)) {
      data.assigned_lessons = assignedLessons;
    }
    if (initDurationUnit !== durationUnit) {
      data.duration_unit = durationUnit;
    }
    if (initDuration !== duration) {
      data.duration = +duration;
    }
    if (initNecessity !== necessity) {
      data.necessity = necessity;
    }
    if (initCategories !== categories) {
      data.categories = categories;
    }
    if (initUnit !== unit) {
      data.unit = +unit;
    }
    if (imageTempUrl) {
      data.course_image = [{ url: imageTempUrl }];
    }
    if (materialTempUrl) {
      data.course_material = [{ url: materialTempUrl }];
    }
    if (initCreatorUid !== user.profile.uid) {
      data.creator_uid = user.profile.uid;
    }
    if (!course_id) {
      let courseId = await createSectionId({ section: "courses2" });
      data.course_id = courseId;
    }
    // if (initAssignedStudents !== assignedStudents) {
    //   data.assigned_students = assignedStudents;
    // }

    const updatedRec = await updateSignleRecord({
      tn: "courses2",
      recId: recordId,
      data,
    });
    if (updatedRec) {
      const record = pollAttachmentMetadata({
        tn: "courses2",
        recId: recordId,
        attachmentNames: ["course_image", "course_material"],
      });
      dispatch(addCourse(record));
      navigate(`/${user.profile.role}panel/lessons`);
    }

    toast.success("successfully add new course");
    navigate(`/${user.profile.role}panel/courses`);
  };

  return (
    <div className="mx-5 mt-5">
      <div className="mb-3 flex justify-between">
        <div className="flex items-center gap-x-3">
          <h2>
            {location.pathname.includes("addnewcourse")
              ? "Add New Course"
              : "Edit Course"}
          </h2>
        </div>
        <h2>{recordId}</h2>

        <Button className="border w-28 self-end" onClick={publishFunc}>
          {course?.course_id ? "edit course" : "publish"}
        </Button>
      </div>
      <TextAreaComponent
        props={{
          explain,
          setTitle,
          title,
          titleDirection,
          setTitleDirection,
          setExplain,
          groupCode: course?.group_code,
          name,
          nameDirection,
          setName,
          setNameDirection,
        }}
      />
      {/* <ShowAddedResources
          section={course}
          sectionName={"courses"}
          sectionId={courseId}
          status={course.info?.status}
          groupCode={course.info?.groupCode}
        /> */}
      <div className="border border-slate-400 flex">
        {/* <ul class="flex flex-col bg-slate-100 w-60 border text-blue-500 pb-40">

          <NavigationLink path={"./general"} name="Settings" />
          <NavigationLink path={"./addresourse"} name="Add Resources" />
          <NavigationLink path={"./coursematerial"} name="Course Material" />
        </ul> */}
        <div className="py-4 w-full">
          {/* {lastpart === "general" && ( */}
          <CrouseGeneralSettings
            generalProps={{
              unit,
              setUnit,
              level,
              setLevel,
              categories,
              setCategories,
              necessity,
              setNecessity,
              duration,
              setDuration,
              durationUnit,
              setDurationUnit,
              prerequisites,
              setPrerequisites,
              assignedInstructor,
              setAssignedInstructor,
              groupCode,
              setGroupCode,
              imageTempUrl,
              setImageTempUrl,
              assignedLessons,
              setAssignedLessons,
              initCourseImage,
              initCourseMaterial,
              materialTempUrl,
              setMaterialTempUrl,
            }}
          />
          {/* )} */}
          {/* {lastpart === "addresourse" && (
            <ShowAddedResources
              section={course}
              sectionName={"courses"}
              sectionId={recordId}
              groupCode={course?.info?.groupCode}
            />
          )}
          {lastpart === "coursematerial" && (
            <CourseMaterials groupCode={groupCode} />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AddNewCourse;
