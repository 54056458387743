import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
// import { getUserById, getUsersByIds } from "../../../redux/usersSlice";

// import ViewAddedResources from "../sharedComp/showResources/ViewAddedResources";
import { getPublicInfoById } from "../../redux/publicInfoSlice";
import TimeShow from "../../shared/utils/TimeShow";
import { getLessonsByIds } from "../../redux/lessonsSlice";
import { getUserById, getUsersByIds } from "../../redux/usersSlice";

const ReviewHomeCourse = () => {
  const { courseId } = useParams();
  const courses = useSelector((state) =>
    getPublicInfoById(state, "homeCourses")
  );
  const modiCourse = courses
    ? Object.values(courses).filter((course) => typeof course !== "string")
    : [];
  const course = modiCourse.find((crs) => crs.info.courseId === courseId);
  const { title, explain, createAt, editAt, status } = course.info;
  const { duration, durationUnit, prerequisites, level, unit } = course.general;
  // const { courseCreator, instructors } = course.author;
  const assignedStudents = course.assignedStudents
    ? Object.keys(course.assignedStudents)
    : [];
  console.log(course.info.creatorId);
  const courseCreator = useSelector((state) =>
    getUserById(state, course.info.creatorId)
  );

  const instructorsId = course.assignedInstructor
    ? Object.keys(course.assignedInstructor)
    : [];

  const instructorsArr = useSelector((state) =>
    getUsersByIds(state, instructorsId)
  );

  const assignedLessons = course.assignedLessons
    ? Object.keys(course.assignedLessons)
    : [];
  const StuAsFull = useSelector((state) =>
    getUsersByIds(state, assignedStudents)
  );
  const lsnAsFull = useSelector((state) =>
    getLessonsByIds(state, assignedLessons)
  );
  console.log(StuAsFull);
  return (
    <Card className="w-11/12 mx-auto my-4">
      <CardContent>
        {/* Group One */}
        <Typography variant="h5" component="div" className="text-center">
          {title}
        </Typography>
        <div className="flex">
          <div className="w-2/3">
            <Grid container spacing={1} className="pt-8">
              <Grid item xs={6}>
                <Typography variant="body2">Course ID: {courseId}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className="flex gap-x-3">
                  Publish Date: <TimeShow date={new Date(createAt)} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Status: {status}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Necessity: {course?.general?.necessity}
                </Typography>
              </Grid>
            </Grid>
            {/* Group Two */}
            <Typography variant="h6" component="div" className="pt-8">
              Course Details
            </Typography>
            <Grid container spacing={1} className="mt-2">
              <Grid item xs={6}>
                <Typography variant="body2">
                  Duration: {duration} {durationUnit}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Prerequisites: {prerequisites}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Level: {level}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Unit: {unit}</Typography>
              </Grid>
            </Grid>
            {/* Group Three */}
            {/* <Typography variant="h6" component="div" className="pt-8">
              Pricing & Dates
            </Typography>
            <Grid container spacing={1} className="mt-2">
              <Grid item xs={6}>
                <Typography variant="body2">
                  Regular Price: {course?.pricing?.regularPrice}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Sale Price: {course?.pricing?.salePrice}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Start Date: {course?.pricing?.startDate}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  End Date: {course?.pricing?.endDate}
                </Typography>
              </Grid>
            </Grid> */}
          </div>
          <div className="w-1/3">
            <img
              src={course?.files?.courseImage?.url}
              alt={title}
              className="w-full h-64 object-contain"
            />
          </div>
        </div>
        {/* Group Four */}
        <Typography variant="h4" className="text-center pt-10">
          {course.info?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" className="pt-3">
          Explain: <ReactQuill value={explain} readOnly={true} theme="bubble" />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReviewHomeCourse;
