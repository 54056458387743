import React, { useEffect, useState } from "react";
import { Button, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserById, getUsersByIds } from "../../redux/usersSlice";
import ViewFile from "../../layout/ViewFile";
import ShowCourseContent from "./ShowCourseContent";
import { useNavigate } from "react-router-dom";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";

const CourseRowSt = ({ course }) => {
  const user = useSelector((state) => state.user);
  const initGrade =
    (user?.reportCards &&
      user?.reportCards[course?.course_id] &&
      user?.reportCards[course?.course_id]?.grade) ||
    "";
  const currentStatus =
    initGrade === "" ? "under process" : initGrade >= 60 ? "passed" : "failed";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeComp = (comp) => {
    setCurrentComp(comp);
  };
  const [currentComp, setCurrentComp] = useState(
    <ShowCourseContent changeComp={changeComp} courseId={course?.id} />
  );
  const navigate = useNavigate();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const changeComp = (comp) => {
  //   setCurrentComp(comp);
  // };
  // const [currentComp, setCurrentComp] = useState("");
  // const closeModal = () => {
  //   document.body.style.overflowY = "scroll";
  //   setIsModalOpen(false);
  // };

  const openCourse = () => {
    navigate(`/studentpanel/courses/viewcourse/${course?.id}`);
  };

  const openCourseContent = () => {
    setIsModalOpen(true);
    setCurrentComp(
      <ShowCourseContent changeComp={changeComp} courseId={course.id} />
    );
    // window.history.pushState(
    //   {},
    //   "",
    //   `/studentpanel/courses/viewcourse/${course?.info.courseId}`
    // );
  };

  const instructor =
    user?.assignedCourses &&
    user?.assignedCourses[course?.course_id] &&
    user?.assignedCourses[course?.course_id].instructor;

  const closeModal = () => {
    document.body.style.overflowY = "scroll";
    setIsModalOpen(false);
  };

  return (
    <TableRow key={course?.id}>
      <TableCell>{course?.title}</TableCell>
      <TableCell>{instructor}</TableCell>
      {/* <TableCell>{course?.categoriesName}</TableCell> */}
      {/* <TableCell>{course?.info?.createAt}</TableCell> */}
      <TableCell>
        {course?.duration} {course?.duration_unit}
      </TableCell>
      {/* <TableCell>{course?.general?.prerequisite}</TableCell> */}
      <TableCell>{course?.level}</TableCell>
      <TableCell>{course?.unit}</TableCell>
      <TableCell>
        {currentStatus === "passed" ? (
          <div>
            <CheckBoxIcon color="success" />
            {currentStatus}
          </div>
        ) : currentStatus === "failed" ? (
          <div>
            <CloseIcon sx={{ color: "red" }} />
            {currentStatus}
          </div>
        ) : (
          <div>
            <HourglassTopIcon />
            {currentStatus}
          </div>
        )}
      </TableCell>
      <TableCell>{initGrade || "---"}</TableCell>
      <TableCell>
        {/* <div className="flex  gap-x-2 "> */}
        <Button title="continue to course" size="small" onClick={openCourse}>
          <VisibilityIcon className="text-cu-blue" />
        </Button>
      </TableCell>

      <TableCell>
        <Button
          title="continue to content of course"
          size="small"
          onClick={openCourseContent}
        >
          <SmartDisplayIcon className="text-cu-blue" />
        </Button>
      </TableCell>
      <ViewFile
        isOpen={isModalOpen}
        onClose={closeModal}
        title={course?.title || ""}
      >
        {currentComp}
      </ViewFile>
      {/* </div> */}
      {/* <ViewFile
          isOpen={isModalOpen}
          onClose={closeModal}
          title={course?.info?.title || ""}
        >
          {currentComp}
        </ViewFile> */}
    </TableRow>
  );
};

export default CourseRowSt;
