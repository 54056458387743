import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Button,
  InputAdornment,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  createSingleRecord,
  deleteRealItem,
  deleteStorageRecursively,
  pollAttachmentMetadata,
  readSingleRecord,
  updateSignleRecord,
  uploadGroup,
} from "../../../firebase.config";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  addgroup,
  getAllGroups,
  getGroupById,
} from "../../../redux/groupsSlice";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { getDocument } from "pdfjs-dist";
import { pdfjs } from "react-pdf";
import { GlobalWorkerOptions } from "pdfjs-dist";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LoadingButton from "../../../shared/comp/LoadingButton";
import {
  getUserById,
  getUsersByGroupcode,
  getUsersByRole,
} from "../../../redux/usersSlice";
import AddSemester from "./AddSemester";
import ViewFile from "../../../layout/ViewFile";
import axios from "axios";
import { useDispatch } from "react-redux";
import UploadFileComp from "../sharedComp/UploadFileComp";

// GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const AddNewGroup = () => {
  const dispatch = useDispatch();
  const groups = useSelector(getAllGroups);
  const [loading, setLoading] = useState(false);
  let { recordId } = useParams();
  const group = useSelector((state) => getGroupById(state, recordId));
  const {
    color: initColor = "",
    code: initCode = "",
    id = "",
    p_title = "",
    e_title = "",
    lead_instructor = "",
    active = "",
    catalog_url = "",
    creator_uid = "",
    create_at = "",
    edit_at = "",
    ...rest
  } = group || {};
  // const initLeadInstructor = useSelector((state) =>
  //   getUserById(state, lead_instructor)
  // );

  const initCourses = [];
  const coursesLentgh = rest ? Object.keys(rest).length / 2 : [];
  for (let i = 1; i <= coursesLentgh; i++) {
    initCourses.push([rest[`course${i}`], rest[`course${i}_sg_sem`]]);
  }
  const [color, setColor] = useState(initColor);
  const [code, setCode] = useState(initCode);
  const [persianName, setPersianName] = useState(p_title);
  const [englishName, setEnglishName] = useState(e_title);
  // const fff = useSelector((state) => getUserById(state, group?.leadInstructor));
  const [leadInstructor, setleadInstructor] = useState(lead_instructor);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [tempFileUrl, setTempFileUrl] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [majorCourses, setMajorCourses] = useState(initCourses || []);
  const allLeadInstructor = useSelector((state) =>
    getUsersByRole(state, "leadInstructor")
  );

  const changeUser = (e) => {
    setleadInstructor(e.target.value);
  };

  const submitForm = async () => {
    try {
      setLoading(true);
      let i = 0;
      const coursesObj = majorCourses.reduce((acc, curr) => {
        i++;
        acc[`course${i}`] = curr[0];
        acc[`course${i}_sg_sem`] = String(curr[1]);
        return acc;
      }, {});

      let newRec;
      if (recordId) {
        const restEmpty = { ...rest };
        if (rest) {
          Object.keys(restEmpty).forEach((key) => {
            restEmpty[key] = ""; // Set each field to an empty string
          });
        }

        let data = {};

        // Add properties conditionally
        if (color !== initColor) {
          data.color = color;
        }
        if (p_title !== persianName) {
          data.p_title = persianName;
        }
        if (e_title !== englishName) {
          data.e_title = englishName;
        }
        if (lead_instructor !== leadInstructor) {
          data.lead_instructor = leadInstructor;
        }
        if (creator_uid !== user.profile.uid) {
          data.creator_uid = user.profile.uid;
        }
        if (tempFileUrl) {
          data.catalog_url = [
            {
              url: tempFileUrl, // Use the URL from file.io
            },
          ];
        }
        if (JSON.stringify(rest) !== JSON.stringify(coursesObj)) {
          Object.assign(data, restEmpty, coursesObj);
        }
        newRec = await updateSignleRecord({
          tn: "majors",
          data,
          recId: recordId,
        });
      } else {
        newRec = await createSingleRecord({
          tn: "majors",
          data: {
            color,
            p_title: persianName,
            e_title: englishName,
            lead_instructor: leadInstructor,
            code,
            creator_uid: user.profile.uid,
            catalog_url: [
              {
                url: tempFileUrl, // Use the URL from file.io
              },
            ],
            active: true,
            ...coursesObj,
          },
        });
      }
      if (!tempFileUrl) {
        dispatch(addgroup(newRec));
        toast.success(
          `group ${newRec.id} ${!recordId ? "added" : "edited"} by code ${
            newRec.code
          } successfully`
        );
      } else {
        const newRecord = await pollAttachmentMetadata({
          recId: newRec.id,
          tn: "majors",
          fileSize,
          attachmentName: ["catalog_url"],
        });
        dispatch(addgroup(newRecord));
        toast.success(
          `group ${newRecord.id} ${!recordId ? "added" : "edited"} by code ${
            newRecord.code
          } successfully`
        );
      }
      navigate(`/${user.profile.role}panel/groups`);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        width: "400px",
        margin: "auto",
        padding: 3,
        borderRadius: 2,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
        marginTop: "20px",
      }}
    >
      <Typography variant="h5" sx={{ textAlign: "center", marginBottom: 2 }}>
        Major Form
      </Typography>

      <TextField
        disabled={user.profile.role !== "admin"}
        error={!color.startsWith("#") && color}
        helperText={
          !color.startsWith("#") && color && "start with # (hexodecimal format)"
        }
        label="Color (Hex)"
        variant="outlined"
        value={color}
        onChange={(e) => setColor(e.target.value)}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: color,
                  border: "1px solid #ccc",
                }}
              />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        disabled={recordId}
        label="Import Code"
        variant="outlined"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        fullWidth
        error={groups.some((g) => code === g.code) && !recordId}
        helperText={
          groups.some((g) => code === g.code) && !recordId
            ? "This code already exists. Please choose a different one."
            : ""
        }
      />

      <TextField
        label="Persian Name"
        variant="outlined"
        value={persianName}
        onChange={(e) => setPersianName(e.target.value)}
        fullWidth
      />

      <TextField
        label="English Name"
        variant="outlined"
        value={englishName}
        onChange={(e) => setEnglishName(e.target.value)}
        fullWidth
      />

      <FormControl fullWidth>
        <InputLabel id="user-select-label">Select Lead Instructor</InputLabel>
        <Select
          disabled={user.profile.role !== "admin"}
          labelId="user-select-label"
          onChange={changeUser}
          label="Select Lead Instructor"
          className="mb-10"
          value={leadInstructor}
        >
          {allLeadInstructor.map((user, index) => (
            <MenuItem key={index} value={user.profile.uid}>
              {user.profile.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        create semester
      </Button>
      <ViewFile
        isOpen={isOpen}
        onClose={onClose}
        title={"Select Courses For Major"}
      >
        <AddSemester
          groupCode={code}
          majorCourses={majorCourses}
          setMajorCourses={setMajorCourses}
          onClose={onClose}
        />
      </ViewFile>
      <UploadFileComp
        acceptedType={".pdf"}
        setFileSize={setFileSize}
        setTempFileUrl={setTempFileUrl}
        tempFileUrl={tempFileUrl}
      />
      <div onClick={submitForm}>
        <LoadingButton
          loading={loading}
          disabled={!color || !code || !persianName || !englishName}
          name={"SUBMIT"}
        />
      </div>
    </Box>
  );
};

export default AddNewGroup;
