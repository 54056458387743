import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  IconButton,
  CardContent,
  Card,
  styled,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import LinkIcon from "@mui/icons-material/Link";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ClearIcon from "@mui/icons-material/Clear";

const AddResourceLink = ({ formData, handleInputChange, text }) => {
  const [link, setLink] = useState(
    formData.videoLink || "https://yplayer-b0a75.web.app/?url="
  );
  const [openModal, setOpenModal] = useState(false);
  const handleClickOpen = () => setOpenModal(true);

  const handleClose = () => setOpenModal(false);

  const handleOk = () => {
    if (!link.includes("youtube")) {
      toast.error("only video url accepted");
      return;
    }
    handleInputChange(link);
    handleClose();
  };
  //   const clearSelected = () => {
  //     setLink("");
  //     handleInputChange("");
  //   };
  return (
    <Grid item xs={12} className="flex justify-between border p-3">
      <div className="w-96 flex items-center">
        <div className="w-28 flex">
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className="w-24"
          >
            {text}
          </Typography>
          <YouTubeIcon color="disabled" />
        </div>
        <div className="ms-4">
          {formData[text] ? (
            <div className="text-green-500 flex">
              <Typography noWrap className="capitalize w-40">
                link selected
              </Typography>
              <CheckIcon />
            </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No Link Selected
            </Typography>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Button
          size="small"
          component="label"
          title="add video link"
          onClick={handleClickOpen}
        >
          <AddLinkIcon
            sx={{ fontSize: "40px" }}
            className="text-cu-blue cursor-pointer"
          />
        </Button>
      </div>
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle>Enter Video Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide the URL for the video you want to upload.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Video URL"
            type="url"
            fullWidth
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AddResourceLink;
