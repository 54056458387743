import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const resourcesAdaptor = createEntityAdapter();

const initialState = resourcesAdaptor.getInitialState();

const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {
    addResources: resourcesAdaptor.setAll,
    addResource: resourcesAdaptor.setOne,
    updateResource: resourcesAdaptor.upsertOne,
  },
});

export const { addResources, updateResource, addResource } =
  resourcesSlice.actions;
export const getAllResources = (state) =>
  resourcesAdaptor
    .getSelectors()
    .selectAll(state.resources)
    .sort((a, b) => b?.create_at - a?.create_at);
// .sort((a, b) => b.info.name.localeCompare(a.info.name));
export const getResourceById = (state, resourceId) =>
  resourcesAdaptor.getSelectors().selectById(state.resources, resourceId);
export default resourcesSlice.reducer;
