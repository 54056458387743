import { faLock, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOutUser, userAuthChange } from "../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import { getUserDocs, logoutUser } from "../../redux/userSlice";
import { toast } from "react-toastify";
import { Avatar, Badge, IconButton } from "@mui/material";
const NavbarUserStatus = () => {
  const user = useSelector((state) => state.user);
  const profile = user?.profile;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = async () => {
    try {
      if (window.confirm("are you sure you want to sign out")) {
        await signOutUser();
        dispatch(logoutUser());
        toast.success("you sign out from your account");
        navigate("/");
      }
    } catch (error) {
      toast.error(error + "something is wrong");
    }
  };
  console.log(profile);
  return profile?.name ? (
    <div className=" flex items-center">
      <button
        className="px-4  h-8 bg-cu-red text-white rounded-lg"
        onClick={() => navigate("/register")}
      >
        Register
      </button>
      <button className="text-cu-red cursor-pointer mx-4" onClick={logout}>
        Log Out
      </button>
      <div className="myc-profileBox text-left flex flex-col items-center relative">
        <IconButton aria-label="avatar">
          <Avatar
            sx={{ height: "30px", width: "30px" }}
            src={profile?.photoUrl.url}
          />
        </IconButton>
        <div className="mt-3 flex flex-col absolute p-8 hidden">
          <div className="bg-white flex flex-col gap-y-2 border p-4 rounded-xl w-full">
            <div className="text-blue-500 text-start">
              <Link to={`/${profile.role}panel`}>Panel</Link>
            </div>
            <div>
              {profile.name} {profile.family}
            </div>
            <div className="text-md">{profile.email}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="myc-homeAuth text-white ">
      <button className="px-4 py-1" onClick={() => navigate("/register")}>
        Register
      </button>
      <button className="px-4 py-1" onClick={() => navigate("/login")}>
        <FontAwesomeIcon className="pr-1" icon={faLock} />
        LOGIN
      </button>
    </div>
  );
};

export default NavbarUserStatus;
