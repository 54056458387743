import { Typography, Box, Button, Paper, Container } from "@mui/material";
import ReleaseDateCounter from "../../shared/utils/ReleaseDateCounter";
import ShowCourseContent from "./ShowCourseContent";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PdfShow from "../shared/resourses/showFile/PdfShow";

const ShowAssignment = ({ assignment, changeComp }) => {
  const backToCourse = () => {
    changeComp(<ShowCourseContent changeComp={changeComp} />);
  };

  return (
    <div className="h-full">
      <div className="flex items-center h-14 gap-5 border-b-2">
        <Button onClick={backToCourse} sx={{ color: "#002868" }}>
          <ArrowBackIcon />
        </Button>
        <div className="h-full flex items-center">
          <Typography variant="h6" align="center" gutterBottom>
            {assignment?.filename}
          </Typography>
        </div>
      </div>
      <div className="w-full h-full-minus-14">
        <PdfShow pdf={assignment} />
      </div>
    </div>
  );
};

export default ShowAssignment;
