// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
//   Box,
//   Button,
//   Container,
//   List,
//   Typography,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { getLessonsByIds } from "../../redux/lessonsSlice";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { getCourseById } from "../../redux/coursesSlice";
// import EachLessonAcc from "./EachLessonAcc";
// const ShowCourseContent = ({ changeComp, courseId: id }) => {
//   const [expanded, setExpanded] = useState(false);
//   // const location = useLocation();
//   // const navigate = useNavigate();
//   let { courseId } = useParams();
//   courseId = courseId === undefined ? id : courseId;
//   const course = useSelector((state) => getCourseById(state, courseId));
//   const lessons = useSelector((state) =>
//     getLessonsByIds(
//       state,
//       course?.assignedLessons ? Object.keys(course.assignedLessons) : []
//     )
//   );

//   return (
//     <Container
//       component="div"
//       disablePadding
//       className="flex flex-col gap-y-3 mt-6 pb-6"
//     >
//       {Object.values(lessons).map((lesson, index) => (
//         <EachLessonAcc
//           lesson={lesson}
//           index={index}
//           changeComp={changeComp}
//           expanded={expanded}
//           setExpanded={setExpanded}
//           courseId={courseId}
//         />
//       ))}
//     </Container>
//   );
// };

// export default ShowCourseContent;
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCourseById } from "../../redux/coursesSlice";
import { getLessonsByIds, addLessons } from "../../redux/lessonsSlice";
import { ref, get } from "firebase/database"; // Firebase utilities
import EachLessonAcc from "./EachLessonAcc";
import { Container } from "@mui/material";
import { db, getMissingLessonSt } from "../../firebase.config";
import { useLoadMultipleLesson } from "../../shared/customHooks/useLoadMultipleLessons";
import LoadingOverlay from "../../shared/comp/LoadingOverlay";

const ShowCourseContent = ({ changeComp, courseId: id }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  let { courseId } = useParams();
  courseId = courseId === undefined ? id : courseId;
  const course = useSelector((state) => getCourseById(state, courseId));
  const lessonIds = useMemo(() => {
    return course?.assigned_lessons
      ? Object.values(course?.assigned_lessons)
      : [];
  }, []);

  const loading = useLoadMultipleLesson(true, lessonIds);
  // Get the course and lessons from Redux
  const lessons = useSelector((state) => getLessonsByIds(state, lessonIds));

  // Fetch lessons from Firebase if not present in Redux
  useEffect(() => {
    const fetchMissingLessons = async () => {
      if (course?.assignedLessons) {
        const lessonIdsGr = Object.entries(course.assignedLessons);

        // Filter out lessons that are not already in Redux
        const missingLessonIdsGr = lessonIdsGr.filter(
          (idGr) =>
            !lessons.some((lesson) => lesson?.info?.lessonId === idGr[0])
        );

        if (missingLessonIdsGr.length > 0) {
          try {
            // Fetch missing lessons from Firebase
            const lessonPromises = missingLessonIdsGr.map(async (idGr) => {
              return await getMissingLessonSt({
                groupCode: "04",
                lessonId: idGr[0],
              });
            });

            const fetchedLessons = await Promise.all(lessonPromises);

            // Dispatch action to add fetched lessons to Redux
            dispatch(addLessons(fetchedLessons));
          } catch (error) {
            console.error("Failed to fetch lessons:", error);
          }
        }
      }
    };

    fetchMissingLessons();
  }, [dispatch]);

  return (
    <Container
      component="div"
      disablePadding
      className="flex flex-col gap-y-3 mt-6 pb-6"
    >
      <LoadingOverlay loading={loading} />
      {lessons.map((lesson, index) => (
        <EachLessonAcc
          key={lesson?.info?.lessonId}
          lesson={lesson}
          index={index}
          changeComp={changeComp}
          expanded={expanded}
          setExpanded={setExpanded}
          courseId={courseId}
        />
      ))}
    </Container>
  );
};

export default ShowCourseContent;
