import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const coursesAdapter = createEntityAdapter();

const initialState = coursesAdapter.getInitialState();

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    addCourses: coursesAdapter.setAll,
    addCourse: coursesAdapter.setOne,
    addMultipleCourse: coursesAdapter.setMany,
    removeCourse: coursesAdapter.removeOne,
    updateCourse: coursesAdapter.updateOne,
  },
});

export const {
  addCourses,
  addCourse,
  addMultipleCourse,
  removeCourse,
  updateCourse,
} = coursesSlice.actions;
export const getAllCourses = (state) =>
  coursesAdapter
    .getSelectors()
    .selectAll(state.courses)
    .sort((a, b) => b?.create_at - a?.create_at);

export const getCourseIds = (state) =>
  coursesAdapter.getSelectors((state) => state.courses).selectIds(state);

export const getCourseById = (state, courseId) =>
  coursesAdapter.getSelectors().selectById(state.courses, courseId);

export const getCoursesEntities = (state) =>
  coursesAdapter.getSelectors().selectEntities(state.courses);

export const getCoursesByIds = createSelector(
  [getCoursesEntities, (state, ids) => ids],
  (entities, ids) => ids?.map((id) => entities[id])
);

export default coursesSlice.reducer;
