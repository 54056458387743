import React, { useEffect, useState } from "react";
import EachCourseAssign from "./EachCourseAssign";
import { toast } from "react-toastify";
import { assignStudentToCourse } from "../../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssignedCourses,
  getAllUsers,
  getUsersByGroupcode,
} from "../../../redux/usersSlice";
import { getAllCourses } from "../../../redux/coursesSlice";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Card,
  CardContent,
} from "@mui/material";
import { useLoadCourses } from "../../../shared/customHooks/useLoadCourses";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";
import { useLoadMajors } from "../../../shared/customHooks/useLoadMajors";

const CourseAssign = () => {
  // const [courseName, setCourseName] = useState("");
  const loading = useLoadCourses(true);
  const loadingmjr = useLoadMajors(true);
  const user = useSelector((state) => state.user);
  const [selectedUser, setSelectedUser] = useState("");
  const users = useSelector(getAllUsers);
  const [selectedRole, setSelectedRole] = useState("student");
  const filteredUsers = users.filter(
    (user) => user.profile.role === selectedRole
  );
  const courses = useSelector(getAllCourses);

  const changeUser = (e) => {
    const user = users.find((user) => user.profile.uid === e.target.value);
    setSelectedUser(user);
  };

  // useEffect(() => {
  //   const filteredCourses =
  //   user.profile.role === "admin"
  //     ? allCourses
  //     : user.profile.role === "leadInstructor"
  //     ? courses.filter((c) => c.info.groupCode === user.profile.groupCode)
  //         : [];
  //   setCourses(filteredCourses)
  // },[allCourses, courses, user.profile.groupCode, user.profile.role])

  // const studentAddToCourse = async () => {
  //   try {
  //     await assignStudentToCourse({
  //       uid: selectedUser.profile.uid,
  //       // email: selectedUser.profile.email,
  //       list,
  //       assignedCourses,
  //       role: selectedUser.profile.role,
  //       groupCode: selectedUser.profile.groupCode,
  //     });
  //     toast.success("course assigned successfully");
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  return (
    <Card className="mt-5 w-11/12 m-auto" elevation={3}>
      <CardContent>
        <Typography variant="h5" gutterBottom textAlign="center">
          Assign Courses
        </Typography>
        <LoadingOverlay loading={loading && loadingmjr} />
        <Box mb={3} display="flex" justifyContent="center" gap={2}>
          <Button
            variant={selectedRole === "student" ? "contained" : "outlined"}
            onClick={() => {
              setSelectedRole("student");
              setSelectedUser(null);
            }}
          >
            Students
          </Button>
          <Button
            variant={selectedRole === "instructor" ? "contained" : "outlined"}
            onClick={() => {
              setSelectedRole("instructor");
              setSelectedUser(null);
            }}
          >
            Instructors
          </Button>
        </Box>
        <FormControl fullWidth>
          <InputLabel id="user-select-label">Select User</InputLabel>
          <Select
            labelId="user-select-label"
            onChange={changeUser}
            label="Select User"
            className="mb-10"
          >
            {filteredUsers.map((user, index) => (
              <MenuItem key={index} value={user.profile.uid}>
                {user.profile.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="flex flex-col rounded-lg">
          {selectedUser &&
            courses.map((course, index) => (
              <div
                className={`${index % 2 ? "bg-slate-100" : "bg-slate-200"} p-3`}
              >
                <EachCourseAssign
                  key={course.id}
                  course={course}
                  selectedUser={selectedUser}
                  // assignedCourses={assignedCourses}
                  // course={course}
                  // uid={selectedUser.profile.uid}
                />
              </div>
            ))}
        </div>
        {/* {selectedUser && courses?.length ? (
        <div className="mt-10 flex justify-center">
          <Button onClick={studentAddToCourse}>update user courses</Button>
        </div>
      ) : (
        ""
      )} */}
      </CardContent>
    </Card>
  );
};

export default CourseAssign;
