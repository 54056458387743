import React, { useCallback, useMemo } from "react";
import {
  faArrowDown,
  faArrowDown19,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUp,
  faArrowUpAZ,
  faClock,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { db, readTable } from "../../../firebase.config";
import CourseRow from "./CourseRow";
import { get, ref, update } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  addCourses,
  getAllCourses,
  getCoursesByIds,
} from "../../../redux/coursesSlice";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  Paper,
} from "@mui/material";
import SearchItems from "../sharedComp/SearchItems";
import { useLoadCourses } from "../../../shared/customHooks/useLoadCourses";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";
import { tableTheme } from "../sharedComp/tableTheme";
import { ThemeProvider } from "@emotion/react";
import SearchBox from "../sharedComp/SearchBox";

const ShowAllCourses = () => {
  const loading = useLoadCourses(true);
  const user = useSelector((state) => state.user);
  const courses = useSelector(getAllCourses);
  const [searchText, setSearchText] = useState("");
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const navigate = useNavigate();

  const addNewCourse = async () => {
    navigate(`/${user.profile.role}panel/addnewcourse`);
  };

  useEffect(() => {
    setFilteredCourses(
      courses.filter(
        (course) =>
          course.title.toLocaleLowerCase().includes(searchText) ||
          course.name.toLocaleLowerCase().includes(searchText)
      )
    );
  }, [courses, searchText]);

  return (
    <div className="w-7xl mx-auto mt-10 flex flex-col px-10">
      <LoadingOverlay loading={loading} />

      <div className="flex justify-between">
        <div className="mb-5 flex items-center">
          <span className="text-lg font-semibold">Courses:</span>
          <IconButton onClick={addNewCourse} className="ms-3">
            <AddCircleIcon className="text-blue-500" />
          </IconButton>
        </div>
        <div className="w-60">
          <SearchBox setSearchText={setSearchText} />
        </div>
      </div>

      {/* Courses Table */}
      <ThemeProvider theme={tableTheme}>
        <TableContainer component={Paper} className="shadow-md">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <strong>Code</strong>
                </TableCell>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Categories</strong>
                </TableCell>
                <TableCell>
                  <strong>Instructors</strong>
                </TableCell>
                <TableCell>
                  <strong>Creation Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Group</strong>
                </TableCell>
                <TableCell>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCourses?.map((item) => (
                <CourseRow key={`itemname${item.id}`} item={item} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  );
};

export default ShowAllCourses;
