// import { Button, Paper } from "@mui/material";
// import React from "react";
// import GoogleIcon from "@mui/icons-material/Google";
// import { authWithGoogle } from "../../../../firebase.config";
// import { toast } from "react-toastify";
// const GoogleAuthChat = () => {
//   const googleSignIn = async () => {
//     try {
//       await authWithGoogle();
//     } catch (error) {
//       console.log(error);
//       toast.error(error.message);
//     }
//   };

//   return (
//     <Paper className="p-2 mt-3 ps-4 pe-4 underline">
//       <Button
//         variant="contained"
//         onClick={googleSignIn}
//         sx={{
//           backgroundColor: "#bf0a30",
//           color: "white",
//           "&:hover": {
//             backgroundColor: "#bf0a30",
//           },
//           width: "100%",
//         }}
//       >
//         <GoogleIcon className="me-2" fontSize="small" /> SIGN IN WITH GOOGLE
//       </Button>
//     </Paper>
//   );
// };

// export default GoogleAuthChat;
import React from "react";
import { Button, Paper, Typography, Stack } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { authWithGoogle } from "../../../../firebase.config";
import { toast } from "react-toastify";

const GoogleAuthChat = () => {
  const googleSignIn = async () => {
    try {
      await authWithGoogle();
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      toast.error("Failed to sign in. Please try again.");
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        maxWidth: 400,
        margin: "0 auto",
        textAlign: "center",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Cyrus Univercity Chat App!
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ marginBottom: 2 }}
      >
        Sign in with Google to start chatting instantly.
      </Typography>
      <Stack direction="column" spacing={2}>
        <Button
          variant="contained"
          onClick={googleSignIn}
          startIcon={<GoogleIcon />}
          sx={{
            backgroundColor: "#bf0a30",
            color: "white",
            "&:hover": {
              backgroundColor: "#a10a28",
            },
          }}
        >
          Sign in with Google
        </Button>
        <Typography variant="caption" color="textSecondary">
          Your information is safe and secure with us.
        </Typography>
      </Stack>
    </Paper>
  );
};

export default GoogleAuthChat;
