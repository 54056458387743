import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLessonById } from "../../../redux/lessonsSlice";
import ReactQuill from "react-quill";
import TimeShow from "../../../shared/utils/TimeShow";
import { formatDate } from "../../../shared/utils/formatTime";
import { getUserById } from "../../../redux/usersSlice";
import { getCoursesByIds } from "../../../redux/coursesSlice";

const ReviewLesson = () => {
  const { recordId } = useParams();
  const lesson = useSelector((state) => getLessonById(state, recordId));
  const {
    title,
    explain,
    create_at,
    edit_at,
    creator_uid,
    lesson_id,
    assigned_courses,
  } = lesson;
  const courses = useSelector((state) =>
    getCoursesByIds(state, assigned_courses)
  );

  const lessonCreator = useSelector((state) => getUserById(state, creator_uid));

  return (
    <Card className="w-11/12 mx-auto my-4">
      <CardContent>
        {/* Group One */}
        <Typography variant="h6" component="div" className="pt-8">
          Lesson Details
        </Typography>
        <Typography variant="h5" component="div" className="text-center">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Explain: <ReactQuill value={explain} readOnly={true} theme="bubble" />
        </Typography>

        <Grid container spacing={1} className="pt-8">
          <Grid item xs={6}>
            <Typography variant="body2">Lesson ID: {lesson_id}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="flex gap-x-3">
              Publish Date: <TimeShow date={new Date(create_at)} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="flex gap-x-3">
              Edit At: <p>{formatDate(edit_at)}</p>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="flex gap-x-3">
              Create At:
              <p>{formatDate(create_at)}</p>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              Lesson Creator: {lessonCreator?.profile?.name}{" "}
              {lessonCreator?.profile?.family}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" component="div" className="pt-8">
          Assigned Courses
        </Typography>
        <Grid container spacing={1} className="mt-2">
          {courses.map((course) => (
            <Grid item xs={6}>
              <Typography variant="body2">{course.title}</Typography>
              <div variant="body2" className="text-xs text-slate-500">
                {course.name}
              </div>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      {/* <ViewAddedResources /> */}
    </Card>
  );
};

export default ReviewLesson;
