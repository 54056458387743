import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
    updatePassword,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { deleteObject, getBlob, getDownloadURL, getMetadata, getStorage, listAll, ref as storageRef, updateMetadata, uploadBytesResumable } from "firebase/storage";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { getDatabase, ref, update, serverTimestamp, set, remove, get, equalTo, startAt, limitToLast, child, endAt, endBefore } from "firebase/database";
import { toast } from "react-toastify";
import Airtable from "airtable";
import { gapi } from "gapi-script";
import axios from "axios";


const firebaseConfig = {
    apiKey: "AIzaSyA_q-ywKAkZVXg5HPITxGpU-QQiaOnJrhA",
    authDomain: "cyrus-university-website.firebaseapp.com",
    projectId: "cyrus-university-website",
    storageBucket: "cyrus-university-website.appspot.com",
    messagingSenderId: "777209034475",
    appId: "1:777209034475:web:4624afc4848c141da9f0ae",
    measurementId: "G-SPZ0ZB4QFH"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app);
const firestoreDb = getFirestore(app);


export const auth = getAuth();
export const getUser = () => {
    return auth.currentUser;
};


export const userAuthChange = (cb) => {
    return onAuthStateChanged(auth, cb);
};

export const getUrlBlob = async (path, signal, onProgress) => {
    try {
        const storage = getStorage();
        const fileRef = storageRef(storage, path);
        const downloadURL = await getDownloadURL(fileRef);

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.open('GET', downloadURL, true);

        xhr.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                onProgress(progress);
            }
        };

        xhr.onload = () => {
            const url = URL.createObjectURL(xhr.response);
            return url;
        };

        xhr.onerror = (error) => {
            if (signal.aborted) {
                console.log('Request canceled');
            } else {
                console.log(error);
            }
        };

        xhr.send();

        return new Promise((resolve, reject) => {
            xhr.onload = () => resolve(URL.createObjectURL(xhr.response));
            xhr.onerror = () => reject(xhr.statusText);
            signal.addEventListener('abort', () => {
                xhr.abort();
                reject('Request canceled');
            });
        });
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Request canceled');
        } else {
            console.log(error);
        }
    }
};

export const createACourse = async (courseData) => {
    if (!courseData.info.courseId || !courseData.info.groupCode) {
        throw new Error("course id is not exists")
    }
    const courseRef = ref(db, `/courses/${courseData.info.groupCode}/${courseData.info.courseId}`)
    try {
        // const ifCourseExists = (await get(courseRef)).exists()
        // if (ifCourseExists) {
        //     throw new Error("course already exists with the same id!!!")
        // }
        await update(courseRef, courseData)
    } catch (error) {
        throw new Error(error)
    }
}

export const sendImagesToDb = async (imageFile) => {
    const docRef = ref(db, `/resources/images/${imageFile.info.imageId}`)
    try {
        await update(docRef, imageFile)
    } catch (error) {
        console.log(error)
    }
}


export const createALesson = async (lesson) => {
    if (!lesson.info.lessonId || !lesson.info.groupCode) {
        throw new Error("lesson id is not exists")
    }
    const lessonInfoRef = ref(db, `/la/lessons/${lesson.info.groupCode}/${lesson.info.lessonId}`)
    try {
        await update(lessonInfoRef, lesson)
    } catch (error) {
        throw new Error(error)
    }

    const aaa = ref(db, `/la/lessons/${lesson.info.groupCode}/${lesson.info.lessonId}/releaseDate`)
    try {
        await update(aaa, { immediately: true, date: serverTimestamp() },
        )
    } catch (error) {
        throw new Error(error)
    }
}
export const createAAssignment = async (assignment) => {
    if (!assignment.info.assignmentId || !assignment.info.groupCode) {
        throw new Error("assignment id is not exists")
    }
    const assignmentInfoRef = ref(db, `/la/assignments/${assignment.info.groupCode}/${assignment.info.assignmentId}`)
    try {
        await update(assignmentInfoRef, assignment)
    } catch (error) {
        throw new Error(error)
    }
    const aaa = ref(db, `/la/assignments/${assignment.info.groupCode}/${assignment.info.assignmentId}/releaseDate`)
    try {
        await update(aaa, { immediately: true, date: serverTimestamp() },
        )
    } catch (error) {
        throw new Error(error)
    }
}


export const sendGrade = async ({ courseId, grade, uid, gradeEmailGiver }) => {
    if (!uid || !courseId) throw new Error("id is missing")
    const userRef = ref(db, `/users/${uid}/assignedCourses/${courseId}`)
    try {
        await update(userRef, {
            Grade: grade, grade_registerer_email: gradeEmailGiver,
            grade_registeration_date: serverTimestamp()
        })
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export const checkIfPathExists = async (path) => {
    const docRef = ref(db, path)
    try {
        const dataSnapShot = await get(docRef)
        return dataSnapShot.exists()
    } catch (error) {
        console.log("there was an error in check if data exists", error)
    }
}


export const assignLessonToAssignment = async ({ list, lesson, assignedAssignments }) => {
    if (!lesson.info.lessonId || !lesson.info.groupCode) {
        throw new Error("lesson id doesn't exists")
    }

    const assignmentIdInLesson = ref(db, `/la/lessons/${lesson.info.groupCode}/${lesson.info.lessonId}/assignedAssignments`)

    try {
        const listObj = list.reduce((acc, item) => {
            if (!item) {
                throw new Error("assignment id not found")
            }
            acc[item] = lesson.info.groupCode;
            return acc;
        }, {});
        await set(assignmentIdInLesson, listObj)

        const onlyInAssignedAssignments = assignedAssignments.filter(assignment =>
            !list.some(item => item === assignment)
        );

        const onlyInList = list.filter(item =>
            !assignedAssignments.some(assignment => assignment === item)
        );
        const removePromise = onlyInAssignedAssignments.map((assignmentId) => {
            const assignmentidInLesson = ref(db, `/la/assignments/${lesson.info.groupCode}/${assignmentId}/assignedLessons/${lesson.info.lessonId}`)
            return remove(assignmentidInLesson)
        })

        const addPromise = onlyInList.map((assignmentId) => {
            const assignmentidInLesson = ref(db, `/la/assignments/${lesson.info.groupCode}/${assignmentId}/assignedLessons`)
            return update(assignmentidInLesson, { [lesson.info.lessonId]: lesson.info.groupCode })
        })

        await Promise.all(removePromise, addPromise)

        return { message: "changes successfully done" }

    } catch (error) {
        throw new Error(error)
    }
}
// export const updateContentDripToDb = async (lessonList, groupCode) => {
//     //each lesson recid , releasedate
// }

export const assignCourseToLesson = async ({ list, courseId, assignedLessons, groupCode }) => {
    if (!courseId || !groupCode) {
        throw new Error("course id doesn't exists")
    }

    const lessonidInCourse = ref(db, `/courses/${groupCode}/${courseId}/assignedLessons`)

    try {
        const listObj = list.reduce((acc, item) => {
            if (!item) {
                throw new Error(item + "lesson id not found")
            }
            acc[item] = groupCode;
            return acc;
        }, {});
        await set(lessonidInCourse, listObj)

        const onlyInAssignedLessons = assignedLessons.filter(lesson =>
            !list.some(item => item === lesson)
        );

        const onlyInList = list.filter(item =>
            !assignedLessons.some(lesson => lesson === item)
        );

        const removePromise = onlyInAssignedLessons.map((lessonId) => {

            const courseidInLesson = ref(db, `/la/lessons/${groupCode}/${lessonId}/assignedCourses/${courseId}`)
            return remove(courseidInLesson)
        })

        const addPromise = onlyInList.map((lessonId) => {
            const courseidInLesson = ref(db, `/la/lessons/${groupCode}/${lessonId}/assignedCourses`)
            return update(courseidInLesson, { [courseId]: groupCode })
        })

        await Promise.all(removePromise, addPromise)

        return { message: "changes successfully done" }



    } catch (error) {
        throw new Error(error)
    }
}


export const saveAboutMe = async ({ uid, aboutMe, groupCode }) => {
    if (!uid || !groupCode) throw new Error("id does not exists")
    if (uid !== auth.currentUser.uid) {
        throw new Error("you are not authorized for this action")
    }
    const docRef = ref(db, `/users/${uid}/importedInfo/aboutMe`)
    try {
        await set(docRef, aboutMe)
    } catch (error) {
        console.log(error)
    }
}

// export const assignStudentToCourse = async ({ uid, role, groupCode,
//     list,
//     assignedCourses }) => {
//     if (!uid || !role || !groupCode) {
//         throw new Error("uid or role doesn't exists")
//     }
//     console.log(list, assignedCourses)

//     let updatePart = (role === "instructor" || role === "leadInstructor") ? "assignedInstructor" : "assignedStudents"
//     // if (role === "student") {
//     //     updatePart = "assignedStudents"
//     // } else if (role === "instructor") {
//     //     updatePart = "assignedInstructor"
//     // } else {

//     // }

//     const courseIdInUser = ref(db, `/users/${uid}/assignedCourses`)

//     try {
//         const listObj = list.reduce((acc, lcid) => {
//             if (!lcid) {
//                 throw new Error("course id doesnt exists")
//             }
//             acc[lcid] = groupCode;
//             return acc;
//         }, {});

//         await set(courseIdInUser, listObj)


//         const onlyInAssignedCourses = assignedCourses.filter(course =>
//             !list.some(item => item.courseId === course[0])
//         );

//         const onlyInList = list.filter(item =>
//             !assignedCourses.some(course => course[0] === item.courseId)
//         );

//         const removePromise = onlyInAssignedCourses.map((courseId) => {
//             const userIdInCourse = ref(db, `/courses/${groupCode}/${courseId}/${updatePart}/${uid}`)
//             return remove(userIdInCourse)
//         })


//         const addPromise = onlyInList.map((courseId) => {
//             const userIdInCourse = ref(db, `/courses/${groupCode}/${courseId}/${updatePart}`)
//             return update(userIdInCourse, { [uid]: groupCode })
//         })

//         await Promise.all(removePromise, addPromise)

//         return listObj


//     } catch (error) {
//         throw new Error(error)
//     }
// }

export const assignCourseToSt = async ({ user, course }) => {
    if (!course?.Course_ID || !user.profile.uid) throw new Error("one of id is missing")
    let updatePart = (user.profile.role === "instructor" || user.profile.role === "leadInstructor") ? "assigned_instructor" : "assigned_students"
    const userRef = ref(db, `/users/${user.profile.uid}/assignedCourses`)
    try {
        await update(userRef, { [course.Course_ID]: course })
        let newUids
        if (updatePart === "assigned_students") {
            const existedRec = await readSingleRecord({ tn: "courses2", recId: course.id })
            newUids = existedRec.assigned_students ? (existedRec.assigned_students + "," + user.profile.uid) : user.profile.uid
        }
        await updateSignleRecord({ tn: "courses2", recId: course.id, data: { [updatePart]: updatePart === "assigned_instructor" ? user.profile.uid : newUids } })
    } catch (error) {
        throw new Error(error)
    }
}
export const removeAssignedCourseToSt = async ({ user, recId, courseId }) => {

    if (!user.profile.uid || !courseId) throw new Error("ids not found")
    const userRef = ref(db, `/users/${user.profile.uid}/assignedCourses/${courseId}`)
    let updatePart = (user.profile.role === "instructor" || user.profile.role === "leadInstructor") ? "assigned_instructor" : "assigned_students"
    try {
        await remove(userRef)
        let newUids
        if (updatePart === "assigned_students") {
            const existedRec = await readSingleRecord({ tn: "courses2", recId })
            newUids = existedRec.assigned_students.split(",").map(u => u.trim()).filter(uid => uid !== user.profile.uid)
        }
        await updateSignleRecord({ tn: "courses2", recId, data: { [updatePart]: updatePart === "assigned_instructor" ? user.profile.uid : newUids.join(",") } })
    } catch (error) {
        throw new Error(error)
    }
}


export const checkIfStoragePathExists = async (path) => {
    const storage = getStorage();
    const fileRef = storageRef(storage, path);

    try {
        const res = await getDownloadURL(fileRef);
        console.log(res)
        return true; // File exists
    } catch (error) {
        if (error.code === 'storage/object-not-found') {
            return false; // File does not exist
        } else {
            throw error; // Handle other errors
        }
    }

}



// export const getDocumentUser = async (uid) => {
//     const docRef = ref(db, `/lact/${groupCode}/users/${uid}`);

//     const snapshot = await get(docRef)
//     return snapshot.val()
// };
export const getUserInfo = async (uid) => {
    const docRef = ref(db, `/users/${uid}`);
    const snapshot = await get(docRef)
    return snapshot.val()
};


export const getUsersQuery = () => {
    const usersRef = ref(db, "users");
    return usersRef
}






export const signInWithEmail = async (email, password) => {
    if (!auth || !email || !password) return
    try {
        const userCredetial = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        const idTokenResult = await userCredetial.user.getIdTokenResult();
        const groupCode = idTokenResult.claims.groupCode;
        if (!groupCode) throw new Error("there is a problem with claim")
        const userProfileRef = ref(db, `/users/${userCredetial.user.uid}/profile`)
        await update(userProfileRef, { lastLogin: serverTimestamp() })
        const userInfo = await get(userProfileRef)
        return userInfo.val()
    } catch (error) {
        throw new Error(error);
    }
};




export const changePassword = async ({ email, password, newPassword }) => {
    if (!email || !password || !newPassword) throw new Error("one field is missed")
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        const idTokenResult = await userCredential.user.getIdTokenResult();
        const groupCode = idTokenResult.claims.groupCode;
        if (!groupCode) throw new Error("there is a problem with group code")
        await updatePassword(userCredential.user, newPassword)

        const docRef = ref(db, `/users/${userCredential.user.uid}/profile`)
        await update(docRef, { mustChangePassword: false })
        // const userInfo = await getDocumentUser(userCredential.user.uid)
        // return userInfo
        return true
    } catch (error) {
        throw new Error(error)
    }
}

export const signOutUser = async () => {
    await signOut(auth);
};


// export const uploadPicture = async ({ uid, url ,  groupCode}) => {
//     if (!uid|| !groupCode) throw new Error("an error take place in update picture")
//     try {
//         const docRef = ref(db, `/lact/${groupCode}/users/${uid}/profile`)
//         // const photoUrlRef = child(docRef, "photoUrl")
//         await update(docRef, { photoUrl: url })
//         return { message: "successfully upload image" }
//     } catch (error) {
//         throw new Error(error)
//     }
// }




export const updateRegisteryForm = async ({ uid, pdf, groupCode }) => {
    if (!uid || !groupCode || !pdf.name) throw new Error("an error take place in update forms")

    try {
        const docRef = ref(db, `/users/${uid}/documents/registeryForm/${pdf.name}`)
        const success = await update(docRef, pdf)
        return { message: "successfully upload image", success }
    } catch (error) {
        throw new Error(error)
    }
}
export const updatePdfRealPath = async ({ pdf, path }) => {
    if (!path) throw new Error("an error take place in update forms")

    try {
        const docRef = ref(db, `${path}/${pdf.name}`)
        await update(docRef, pdf)
    } catch (error) {
        throw new Error(error)
    }
}
export const removeSinleRealPath = async (path) => {
    const docRef = ref(db, path)
    try {
        await remove(docRef)
    } catch (error) {
        throw new Error(error)
    }
}

export const updateAddress = async ({ uid, addressInfo, groupCode }) => {
    if (!uid) throw new Error("an error take place in update forms")
    const docRef = ref(db, `/users/${uid}`)
    await update(docRef, { address: addressInfo })
}

export const changeRowIdInDb = async ({ lessonId, rowId, groupCode }) => {
    if (!lessonId || !rowId) {
        throw new Error("ids are not found")
    }
    const docRef = ref(db, `/la/lessons/${groupCode}/${lessonId}/info`)
    try {
        await update(docRef, { rowId })
    } catch (error) {
        console.log(error)
    }
}


export const postPdfUserEssay = async (pdf) => {
    if (!pdf.info.pdfId) throw new Error("pdf id not found")
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    const groupCode = idTokenResult.claims.groupCode;
    if (!groupCode) throw new Error("there is problem in user claim")
    const docRef = ref(db, `/users/${auth.currentUser.uid}/importedInfo/essays/${pdf.info.pdfId}`)
    try {
        await update(docRef, pdf)
        return ({ message: "successfully send pdf to DB" })
    } catch (error) {
        console.log(error)
        throw new Error(`${error} , error in send pdf`)
    }
}
export const postVideoDataToDb = async (video) => {
    if (!video.info.videoId || !video.info.groupCode) throw new Error("video id not found")

    const docRef = ref(db, `/resources/video/${video.info.videoId}`)
    try {
        await update(docRef, video)
        return ({ message: "successfully send video to DB" })
    } catch (error) {
        return new Error(`${error} , error in send video`)
    }
}

export const postPdfDataToDb = async (pdf) => {
    if (!pdf.info.pdfId || !pdf.info.groupCode) throw new Error("pdf id not found")

    const docRef = ref(db, `/resources/pdf/${pdf.info.pdfId}`)
    try {
        await update(docRef, pdf)
        return ({ message: "successfully send pdf to DB" })
    } catch (error) {
        console.log(error)
        throw new Error(`${error} , error in send pdf`)
    }
}

export const uploadGroup = async ({
    color,
    persianName,
    englishName,
    leadInstructor,
    id,
    catalog,
    courses
}) => {
    if (!id) throw new Error("group id not found")
    const docRef = ref(db, `/groups/${id}`)
    try {
        await set(docRef, {
            color,
            id,
            persianName,
            englishName,
            leadInstructor,
            catalog,
            courses
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const postImageDataToDb = async (image) => {
    if (!image.info.imageId || !image.info.groupCode) throw new Error("image id not found")

    const docRef = ref(db, `/resources/images/${image.info.imageId}`)
    try {
        await update(docRef, image)
        return ({ message: "successfully send image to DB" })
    } catch (error) {
        return new Error(`${error} , error in send image`)
    }
}

export const addResourceToLCADB = async ({ resourceData, sectionId, sectionName, groupCode }) => {
    if (!sectionName || !sectionId || !resourceData.resourceId || !groupCode) {
        throw new Error("one of element is missing")
    }
    let docRef = ref(db, `${sectionName === "courses" ? "/" : "/la/"}${sectionName}/${groupCode}/${sectionId}/resources/${resourceData.resourceId}`)
    // if (sectionName === "courses") {
    //     docRef = `/${sectionName}/${groupCode}/${sectionId}/resources/${resourceData.resourceId}`)
    // } else {
    //     docRef = ref(db, `/la/${sectionName}/${groupCode}/${sectionId}/resources/${resourceData.resourceId}`)
    // }
    try {
        await set(docRef, resourceData)
        resourceData.type && toast.success(`${resourceData.type} set for resource number ${resourceData.resourceId} for ${sectionName}`)
    } catch (error) {
        throw new Error(error)
    }
}

// export const uploadCoursePic = async ({ uid, url }) => {
//     if (!uid) throw new Error("an error take place in update picture")
//     try {
//         const docRef = ref(db, "/courses/" + uid + "/files")
//         // const photoUrlRef = child(docRef, "photoUrl")
//         await update(docRef, { courseImage: url })
//         return { message: "successfully upload image" }
//     } catch (error) {
//         throw new Error(error)
//     }
// }


const addIdByOne = async (section) => {
    let sectionId;
    const updateRef = ref(db, `/managementInfo/idCounter`);
    const getRef = ref(db, `/managementInfo/idCounter/${section}`);
    try {
        let snap = await get(getRef);
        if (!snap.exists()) {
            await update(updateRef, { [section]: 1 });
            sectionId = section + "_1";
        } else {
            let lastNum = await snap.val();
            lastNum += 1;
            await update(updateRef, { [section]: lastNum });
            sectionId = section + "_" + lastNum;
        }
        return sectionId
    } catch (error) {
        toast.error(error.message, "problem assign course id");
    }
};


export const sendNotificationToDb = async ({ title, text, selectedUsers, notificationAlert, groupCode,
    selectedCourses,
    selectedRole,
    customizeSelection,
    creatorId, titleDirection,
    textDirection,
}) => {
    if (!selectedUsers.length) throw new Error("please choose user to send notification")
    const notificationId = await addIdByOne("notification")
    if (!notificationId) throw new Error("notification ID not found")
    const promisedNotification = selectedUsers.map((selectedUser) => {
        if (!selectedUser.profile.uid) throw new Error("user id not found")
        // const docRef = ref(db, `/notifications/content/${selectedUser.profile.uid}`)
        const docRef = ref(db, `/notification/users/${selectedUser.profile.uid}/${notificationId}`)
        return update(docRef, {
            title, text, createAt: serverTimestamp(), alert: notificationAlert, notificationId, seen: false, titleDirection,
            textDirection,
        })
    })
    const docRef = ref(db, `/notification/manage/${notificationId}`)

    try {
        await update(docRef, {
            users: selectedUsers.map(user => user.profile.uid), info: {
                notificationId, title, text, createAt: serverTimestamp(), alert: notificationAlert, creatorId, titleDirection,
                textDirection
            }, sendList: { selectedCourses, selectedRole, groupCode, customizeSelection }
        })
        await Promise.all(promisedNotification)
    } catch (error) {
        toast.error(error.message)
        console.log(error)
    }
}

export const removeNotificationFromDb = async (notification) => {
    const manageNoteRef = ref(db, `/notification/manage/${notification.info.notificationId}`)
    const notiUserPromise = notification.users.map((userId) => {
        const docRef = ref(db, `/notification/users/${userId}/${notification.info.notificationId}`)
        if (!userId) throw new Error("user id not found")
        return remove(docRef)
    })
    try {
        await remove(manageNoteRef)
        await notiUserPromise
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export const editNotificationInDb = async ({ title, text, selectedUsers, notificationAlert, groupCode,
    selectedCourses,
    selectedRole, notificationId, customizeSelection, creatorId, titleDirection,
    textDirection, }) => {
    if (!selectedUsers.length) throw new Error("please choose user to send notification")
    if (!notificationId) throw new Error("notification ID not found")
    const userOldRef = ref(db, `/notification/manage/${notificationId}/users`)
    const userOldSnap = await get(userOldRef)
    const usersToDelete = userOldSnap.val().filter((oldUser) => !selectedUsers.map(user => user.profile.uid).includes(oldUser))
    const removeUsersPromise = usersToDelete.map((user) => {
        const docRef = ref(db, `/notification/users/${user}/${notificationId}`)
        remove(docRef)
    })
    await Promise.all(removeUsersPromise)

    const sendUserPromise = selectedUsers.map((selectedUser) => {
        if (!selectedUser.profile.uid) throw new Error("user id not found")
        const docRef = ref(db, `/notification/users/${selectedUser.profile.uid}/${notificationId}`)
        return set(docRef, {
            title, text, createAt: serverTimestamp(), alert: notificationAlert, notificationId, seen: false, titleDirection,
            textDirection,
        })
    })
    const docRef = ref(db, `/notification/manage/${notificationId}`)
    try {
        await set(docRef, {
            users: selectedUsers.map(user => user.profile.uid), info: {
                notificationId, title, text, createAt: serverTimestamp(), alert: notificationAlert, creatorId, titleDirection,
                textDirection,
            }, sendList: { selectedCourses, selectedRole, groupCode, customizeSelection }
        })
        await Promise.all(sendUserPromise)
    } catch (error) {
        toast.error(error.message)
        console.log(error)
    }
}

export const setNotificationSeen = async ({ notification, uid }) => {
    try {
        const docRef = ref(db, `/notification/users/${uid}/${notification.notificationId}`)
        if (!uid || !notification.notificationId) throw new Error("id not found")
        await update(docRef, { seen: true })
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export const deleteRealItem = async (path) => {
    const docRef = ref(db, path)
    try {
        await remove(docRef)
        return "successfully remove data"
    } catch (error) {
        throw new Error(error)
    }
}
export const deleteStorageRecursively = async (folderPath) => {
    const storage = getStorage()
    const folderRef = storageRef(storage, folderPath);
    const listResult = await listAll(folderRef);

    // Delete all files in the folder
    try {

        const deletePromises = listResult.items.map((itemRef) => deleteObject(itemRef));
        await Promise.all(deletePromises);

        // Recursively delete all subfolders
        const subfolderPromises = listResult.prefixes.map((subfolderRef) => deleteStorageRecursively(subfolderRef.fullPath));
        await Promise.all(subfolderPromises);

        console.log("Folder and all its subfolders and files deleted successfully");
    } catch (error) {
        throw new Error(error)
    }
};

export const setResourcesIdInPath = async ({ path, pdf }) => {
    const docRef = ref(db, path)
    try {
        await update(docRef, { pdf })
        console.log("successfully set")
    } catch (error) {
        throw new Error(error)
    }
}
export const setResourcesVideoIdInPath = async ({ path, video }) => {
    const docRef = ref(db, path)
    try {
        await update(docRef, { video })
        console.log("successfully set")
    } catch (error) {
        throw new Error(error)
    }
}
export const setResourcesImagesIdInPath = async ({ path, images }) => {
    const docRef = ref(db, path)
    try {
        await update(docRef, { images })
        console.log("successfully set")
    } catch (error) {
        throw new Error(error)
    }
}


export const createEmptyLessonInDb = async ({ lesson }) => {
    if (!lesson.info.lessonId || !lesson.info.groupCode) {
        throw new Error("id or group not found")
    }
    // const { id, ...rawLesson } = lesson
    const lessonCopy = JSON.parse(JSON.stringify(lesson));
    lessonCopy.info.status = "draft"
    const docRef = ref(db, `/la/lessons/${lesson.info.groupCode}/${lesson.info.lessonId}`)
    try {
        await set(docRef, lessonCopy)
    } catch (error) {
        throw new Error("there is a problem to set empty lesson")
    }
}
export const createTableInDb = async ({ table }) => {
    if (!table.info.tableId || !table.info.groupCode) {
        throw new Error("group code or id is not specified")
    }
    // const { id, ...rawtable } = table
    const docRef = ref(db, `resources/table/${table.info.tableId}`)
    try {
        await set(docRef, table)
    } catch (error) {
        throw new Error("there is a problem to set table")
    }
}


export const createEmptyCourseInDb = async ({ course }) => {
    console.log(course)
    if (!course.info.courseId) {
        throw new Error("id not found")
    }
    if (!course.info.groupCode) {
        throw new Error("please specify groupCode and try again later")
    }
    // const { id, ...rawcourse } = course
    const courseCopy = JSON.parse(JSON.stringify(course));
    courseCopy.info.status = "draft"
    const docRef = ref(db, `/courses/${course.info.groupCode}/${course.info.courseId}/`)
    try {
        await set(docRef, courseCopy)
    } catch (error) {
        throw new Error("there is a problem to set empty course")
    }
}
export const createEmptyAssignmentInDb = async ({ assignment }) => {
    if (!assignment.info.assignmentId) {
        throw new Error("id not found")
    }
    if (!assignment.info.groupCode) {
        throw new Error("please specify groupCode and try again later")
    }
    // const { id, ...rawassignment } = assignment
    const assignmentCopy = JSON.parse(JSON.stringify(assignment));
    assignmentCopy.info.status = "draft"
    const docRef = ref(db, `/la/assignments/${assignment.info.groupCode}/${assignment.info.assignmentId}`)
    try {
        await set(docRef, assignmentCopy)
    } catch (error) {
        throw new Error("there is a problem to set empty assignment")
    }
}

const googleProvider = new GoogleAuthProvider()
googleProvider.setCustomParameters({ prompt: "consent" })


googleProvider.addScope("https://www.googleapis.com/auth/chat.spaces");
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
// googleProvider.addScope('profile');
// googleProvider.addScope('email');
// googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
// googleProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
// googleProvider.addScope("https://www.googleapis.com/auth/chat.spaces");
googleProvider.addScope('https://www.googleapis.com/auth/chat.messages');
googleProvider.addScope('https://www.googleapis.com/auth/chat.messages.readonly');
googleProvider.addScope('https://www.googleapis.com/auth/chat.spaces');
googleProvider.addScope('https://www.googleapis.com/auth/chat.spaces.readonly');

export const authWithGoogle = async () => {
    // const getData = async (accessToken) => {
    //     await fetch("https://chat.googleapis.com/v1/spaces", {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             // Authorization: "Bearer " + accessToken,
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((data) => console.log(data))
    //         .catch((error) => console.error("Error:", error));
    // };
    const auth = getAuth()

    try {
        const result = await signInWithPopup(auth, googleProvider);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        // console.log("refresh  token", result.user.stsTokenManager.refreshToken)
        const expiresIn = credential.expires_in || 3600;
        const expirationDate = Date.now() + expiresIn * 1000;
        const tDocRef = doc(firestoreDb, "users", result.user.uid)
        await setDoc(tDocRef, { refreshToken: result.user.stsTokenManager.refreshToken, accessToken: { token: accessToken, expirationDate } });

        // console.log("OAuth2 Access Token:", { accessToken, timeEx: new Date(expirationTime) });
        // const accessToken = userCredetial.user.stsTokenManager; // Access token from Google OAuth
        // const accessToken = userCredetial.user.accessToken;
        // const tokenInfo = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`)
        // .then(response => response.json());

        // console.log("Token Info:", tokenInfo);

        // const somedata = await getData(accessToken)

        const idTokenResult = await result.user.getIdTokenResult();
        const groupCode = idTokenResult.claims.groupCode;

        // console.log(accessToken1, accessToken2)
        // const response = await axios.get(
        //     'https://chat.googleapis.com/v1/spaces',
        //     {
        //         headers: {
        //             Authorization: `Bearer ${idTokenResult}`,
        //         },
        //     }
        // );
        // console.log("Spaces:", response.data.spaces);
        if (!groupCode) throw new Error("there is a problem with claim")

        const docRef = ref(db, `/users/${auth.currentUser.uid}`)
        const userSnapshot = await get(docRef)

        if (!userSnapshot.exists()) {
            if (!result.user.uid) throw new Error("id not found")
            // const usersRef = ref(db, "/users")
            // const users = await get(usersRef)
            // const sameUser = Object.values(users).find((user) => user.profile.email === userCredetial.user.email)
            // if (sameUser) {
            //     const docRef = ref(db, `/users/${userCredetial.user.uid}`)
            //     await set(docRef, sameUser)
            //     await remove(`/users/${sameUser.profile.uid}`)
            // } else {
            throw new Error("ask university team to create account for you")
            // }
        }
        const profileRef = ref(db, `/users/${auth.currentUser.uid}/profile`)
        await update(profileRef, { lastLogin: serverTimestamp(), email: result.user.email })

    } catch (error) {
        console.log(error.message)
        await signOutUser()
        throw new Error(error)
    }

}

// AIzaSyA_q-ywKAkZVXg5HPITxGpU-QQiaOnJrhA

export const chatWithGapi = (accessToken) => {
    gapi.load("client", () => {
        gapi.client.setToken({ access_token: accessToken });
        gapi.client.init({
            apiKey: "AIzaSyA_q-ywKAkZVXg5HPITxGpU-QQiaOnJrhA",
            discoveryDocs: ["https://chat.googleapis.com/$discovery/rest?version=v1"],
        })
            .then(() => {
                console.log("GAPI client initialized successfully");
                listSpaces();
            })
            .catch((error) => {
                console.error("Error initializing GAPI client:", error);
            });
    });
}

function listMessages(spaceName) {
    gapi.client.chat.spaces.messages.list({
        parent: spaceName,
    })
        .then((response) => {
            const messages = response.result.messages || [];
            console.log(`Messages in ${spaceName}:`, messages);
        })
        .catch((error) => {
            console.error("Error fetching messages:", error);
        });
}

export function listSpaces() {
    gapi.client.chat.spaces.list()
        .then((response) => {
            const spaces = response.result.spaces || [];
            console.log("Spaces:", spaces);

            // Example: Fetch messages from the first space
            if (spaces.length > 0) {
                const firstSpaceName = spaces[0].name;
                listMessages(firstSpaceName);
            }
        })
        .catch((error) => {
            console.error("Error fetching spaces:", error);
        });
}



export const getSingleUser = async ({ groupCode, uid }) => {
    const docRef = ref(db, `/users/${uid}`)
    const resSnap = await get(docRef)
    return resSnap.val()
}

export const sendErrorToDb = async (error) => {
    const uid = auth?.currentUser?.uid
    const location = window.location.href
    const lastpart =
        location.split("/")[location.split("/").length - 1];
    if (!uid || !lastpart) {
        return
    }
    const errorRef = ref(db, `/errors/${uid}/${lastpart}`)
    try {
        // console.log(error.message)
        await update(errorRef, { error: error.message, route: location })
    } catch (error) {
        console.log(error)
    }
}


export const sendHomeData = async (data, path) => {
    const courseRef = ref(db, path)
    try {
        await set(courseRef, data)

    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export const sendHomeImage = async (imageBlob, path) => {
    const storage = getStorage()
    const getDlUrl = async () => {
        return new Promise((resolve, reject) => {
            const imgRef = storageRef(storage, path);
            const uploadTask = uploadBytesResumable(imgRef, imageBlob);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    console.log("uploading image ...");
                },
                (error) => {
                    toast.error(`error in upload image${error}`);
                    reject(error);
                },
                () => {
                    console.log("image successfully uploaded");
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL);
                    });
                }
            );
        });
    };
    const dlUrl = await getDlUrl()
    await set(ref(db, path), { path, url: dlUrl })

}


export const changeCourseActive = async ({ groupCode, courseId, active }) => {
    if (!groupCode || !courseId) throw new Error("there is a problem with ID")
    const docRef = ref(db, `/courses/${groupCode}/${courseId}/info`)
    try {
        await update(docRef, { active })
    } catch (error) {
        throw new Error(error)
    }
}
export const changeGroupActive = async ({ groupCode, active }) => {
    if (!groupCode) throw new Error("there is a problem with ID")
    const docRef = ref(db, `/groups/${groupCode}`)
    try {
        await update(docRef, { active })
    } catch (error) {
        throw new Error(error)
    }
}
export const addPrerequisiteToCourse = async ({ groupCode, courseId, prerequisiteCourses }) => {

    const docRef = ref(db, `/courses/${groupCode}//${courseId}/`)
    try {
        await update(docRef, { prerequisiteCourses })
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export const sendMajorCourses = async ({ groupCode, majorCourses }) => {
    if (!groupCode) { throw new Error("group code is not exist") }
    const docRef = ref(db, `/groups/${groupCode}/courses`)
    try {
        await set(docRef, majorCourses)
    } catch (error) {
        throw new Error(error)
    }
}


export const editMajorInDb = async ({ groupCode, uid, majors }) => {
    if (!groupCode || !uid) throw new Error("id not found")
    const docRef = ref(db, `/users/${uid}/profile/majors`)
    try {
        await set(docRef, majors)
    } catch (error) {
        throw new Error(error)
    }
}


export const getMissingLessonSt = async ({ groupCode, lessonId }) => {
    if (!groupCode || !lessonId) throw new Error("one of id is missing!!!")
    try {
        const lessonRef = ref(db, `/la/lessons/${groupCode}/${lessonId}`);
        const snapshot = await get(lessonRef);
        return snapshot.val()
    } catch (error) {
        throw new Error(error)
    }
}

export const getMissingAssignmentSt = async ({ groupCode, assignmentId }) => {
    if (!groupCode || !assignmentId) throw new Error("one of id is missing!!!")
    try {
        const assignmentRef = ref(db, `/la/assignments/${groupCode}/${assignmentId}`);
        const snapshot = await get(assignmentRef);
        return snapshot.val()
    } catch (error) {
        throw new Error(error)
    }
}


export const base = new Airtable({ apiKey: 'patpHVMd6WayOA0BO.48f8187fdc591dd26c01e17a6daf0570270e6906afe0c4395e14e01db48bf6b8' }).base('appIEw4wOEzyoCuLL');

export const readTable = async ({ tn }) => {
    try {
        if (!tn) throw new Error("please select table name")
        const records = await base(tn)
            .select({ view: "Grid view" })
            .firstPage();

        const allFields = records.map(record => record.fields);
        return allFields
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}
export const readAllTable = async ({ tn }) => {
    try {
        if (!tn) throw new Error("please select table name")
        const records = await base(tn)
            .select({ view: "Grid view" })
            .all();

        const allFields = records.map(record => record.fields);
        return allFields
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export const createSingleRecord = async ({ tn, data }) => {
    try {

        const createdRecord = await base(tn).create(data);
        return createdRecord.fields
    } catch (error) {
        throw new Error(Error)
    }
}

export const readConditionalRecord = async ({ tn, logic }) => {
    try {
        const records = await base(tn)
            .select({ filterByFormula: logic })
            .all
        return records.map(record => record.fields)
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}
//fetch  page by page
// async function fetchActiveRecords() {
//     const records = [];
//     await base(tableName)
//       .select({ filterByFormula: `{active} = TRUE()` })
//       .eachPage((pageRecords, fetchNextPage) => {
//         records.push(...pageRecords);
//         fetchNextPage();
//       });
//     return records;
//   }

//   fetchActiveRecords()
//     .then((records) => {
//       records.forEach((record) => console.log(record.fields));
//     })
//     .catch((error) => console.error('Error fetching records:', error));


export const updateChunkRecord = async ({ tn, data }) => {
    // const recordsToUpdate = [
    //     { id: 'rec12345', fields: { Name: 'Updated Name 1', Status: 'Complete' } },
    //     { id: 'rec67890', fields: { Name: 'Updated Name 2', Status: 'In Progress' } },
    //     // Add more records here
    // ];
    const chunkSize = 10; // Airtable API allows a max of 10 records per request.
    const recordChunks = [];
    for (let i = 0; i < data.length; i += chunkSize) {
        recordChunks.push(data.slice(i, i + chunkSize));
    }

    const updatedRecords = [];
    for (const chunk of recordChunks) {
        try {
            const response = await base(tn).update(chunk);
            updatedRecords.push(...response);
        } catch (error) {
            console.log(error)
        }
    }

    return updatedRecords.map(rec => rec.fields);
}

export const readSingleRecord = async ({ tn, recId }) => {
    try {
        const fetchedRecord = await base(tn).find(recId);
        return fetchedRecord.fields
    } catch (error) {
        throw new Error(Error)
    }
}

// export const pollAttachmentMetadata = async ({ recId, tn, fileSize, attachmentName }) => {
//     // Constants for polling
//     const AVERAGE_SPEED_MB_PER_SEC = 0.5; // Average Airtable processing speed in MB/s
//     const POLLING_MULTIPLIER = 2; // Multiplier for polling frequency to ensure metadata availability
//     const BASE_POLLING_INTERVAL = 3000; // Minimum polling interval in ms
//     const MAX_RETRIES = 10; // Max attempts to check for metadata

//     // Calculate polling interval based on file size
//     const fileSizeInMB = fileSize / (1024 * 1024);
//     const estimatedProcessingTime = Math.ceil((fileSizeInMB / AVERAGE_SPEED_MB_PER_SEC) * 1000);
//     const pollingInterval = Math.max(BASE_POLLING_INTERVAL, estimatedProcessingTime * POLLING_MULTIPLIER);

//     let attempts = 0;
//     while (attempts < MAX_RETRIES) {
//         try {
//             await new Promise((resolve) => setTimeout(resolve, pollingInterval));
//             const attachments = await base(tn).find(recId);


//             // Check if the attachment has been fully processed
//             if (attachments && attachments[0]?.thumbnails) {
//                 console.log("Attachment metadata available:", attachments);
//                 toast.success("Attachment successfully processed!");
//                 return attachments; // Return the full record with processed attachment
//             }
//         } catch (error) {
//             console.error("Error polling Airtable:", error);
//         }
//         attempts++;
//     }

//     throw new Error("Attachment metadata processing timed out after max retries.");
// };


export const pollAttachmentMetadata = async ({ recId, tn, fileSize, attachmentNames }) => {
    // Constants for polling
    const AVERAGE_SPEED_MB_PER_SEC = 0.5; // Average Airtable processing speed in MB/s
    const POLLING_MULTIPLIER = 2; // Multiplier for polling frequency to ensure metadata availability
    const BASE_POLLING_INTERVAL = 3000; // Minimum polling interval in ms
    const MAX_RETRIES = 4; // Max attempts to check for metadata

    // Calculate polling interval based on file size
    const fileSizeInMB = fileSize / (1024 * 1024);
    const estimatedProcessingTime = Math.ceil((fileSizeInMB / AVERAGE_SPEED_MB_PER_SEC) * 1000);
    const pollingInterval = Math.max(BASE_POLLING_INTERVAL, estimatedProcessingTime * POLLING_MULTIPLIER);

    let attempts = 0;
    while (attempts < MAX_RETRIES) {
        try {
            await new Promise((resolve) => setTimeout(resolve, pollingInterval));
            const record = await readSingleRecord({
                tn,
                recId,
            });

            const attachments = attachmentNames.map(attName => record[attName]).filter((att) => Boolean(att));

            const isAllAttRecieved = attachments.every(
                (att) => att && att[att.length - 1]?.thumbnails
            );
            if (isAllAttRecieved) {
                return record
            }
        } catch (error) {
            console.error("Error polling Airtable:", error);
            throw new Error(error)
        }
        attempts++;
    }

    throw new Error("Attachment metadata processing timed out after max retries.");
};


export const deleteSignleRecord = async ({ tn, recId }) => {
    try {
        const deletedRecord = await base(tn).destroy(recId); // Returns a promise
        return deletedRecord.id
    } catch (err) {
        throw new Error(err)
    }
};

export const updateSignleRecord = async ({ tn, recId, data }) => {
    try {
        const updatedRecord = await base(tn).update(recId, data); // Returns a promise
        return updatedRecord.fields
    } catch (err) {
        throw new Error(err)
    }
};

export const addAttachment = async ({ tn, recId, setRecId, fieldName, file }) => {
    const uploadFileIo = async (fileToUpload) => {
        const formData = new FormData();
        formData.append("file", fileToUpload);

        try {
            const response = await axios.post("https://file.io/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        const percent = (progressEvent.loaded / progressEvent.total) * 100;
                        console.log(Math.round(percent));
                    }
                },
            });

            if (response.data.success) {
                console.log(response.data.size)
                return response.data.link
            } else {
                console.error("File upload failed");
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };
    const updateFileAttachment = async ({ recId, fieldName, tempUrl }) => {
        let recordInfo
        console.log([{ url: tempUrl }], fieldName)
        try {
            if (!recId) {
                recordInfo = await createSingleRecord({
                    tn, data: {
                        [fieldName]: [{ url: tempUrl }]
                    }
                })
                setRecId(recordInfo.id)
            } else {
                console.log(recId, "update")
                const record = await base(tn).find(recId);
                const existingAttachments = record.get(fieldName) || [];

                // Step 2: Append the new file to the existing attachments
                const updatedAttachments = [
                    ...existingAttachments.map(attachment => ({
                        id: attachment.id, // Include the `id` to retain existing files
                        url: attachment.url
                    })),
                    { url: tempUrl } // Add the new file
                ];

                // Step 3: Update the record with the new attachment list
                recordInfo = await updateSignleRecord({
                    tn, recId, data: {
                        [fieldName]: updatedAttachments
                    }
                })
                console.log(`Attachment updated successfully for record ${recId}`);
            }
            return recordInfo
        } catch (error) {
            throw new Error(error)
        }
    }


    try {
        const tempUrl = await uploadFileIo(file)

        const recordInfo = await updateFileAttachment({ recId, fieldName, tempUrl })
        const record = await pollAttachmentMetadata({ tn, recId: recordInfo.id, fileSize: 1000, attachmentName: [fieldName] })

        return record
    } catch (error) {
        throw new Error(error)
    }
}

//OR use to check multiple logic,here we have array of logic that seperated with , 
// const filterFormula = `OR(${lessonIds.map(id => `{lessonId} = ${id}`).join(', ')})`;

// Fetch all records from the table
// const fetchAllRecords = async () => {
//     const allRecords = [];

//     // Use Airtable's `select` method and `eachPage` to handle pagination
//     await base("your_table_name")
//         .select({
//             // Optional: Define fields or filters here
//             pageSize:10,
//             maxRecords: 1000, // Limit (optional)
//             fields: ["Field1", "Field2"], // Only fetch specific fields
//             filterByFormula: "{Status} = 'Active'" // Optional filtering
//         })
//         .eachPage(
//             (records, fetchNextPage) => {
//                 // Add current page's records to the array
//                 allRecords.push(...records);

//                 // Fetch the next page
//                 fetchNextPage();
//             },
//             (err) => {
//                 if (err) {
//                     console.error("Error fetching records:", err);
//                     throw err;
//                 }
//             }
//         );

//     return allRecords;
// };

// Call the function
// fetchAllRecords()
//     .then((records) => {
//         console.log(`Fetched ${records.length} records!`);
//         // Process the records as needed
//     })
//     .catch((error) => console.error("Error:", error));




// encrypt and decrypt token
// import CryptoJS from "crypto-js";

// const SECRET_KEY = "your-secret-key"; // Replace with a securely generated key

// // Encrypt the token
// const encryptToken = (token) => {
//   return CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
// };

// // Decrypt the token
// const decryptToken = (ciphertext) => {
//   const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
//   return bytes.toString(CryptoJS.enc.Utf8);
// };

// // Example Usage
// const accessToken = "your-access-token";
// const encryptedToken = encryptToken(accessToken);
// console.log("Encrypted Token:", encryptedToken);

// const decryptedToken = decryptToken(encryptedToken);
// console.log("Decrypted Token:", decryptedToken);


// const getAccessToken = async ({ uid }) => {
//     const docRef = doc(firestoreDb, "users", uid)
//     const docSnap = await getDoc(docRef)
//     if (!docSnap.exists()) throw new Error("something went wrong")
//     const data = docSnap.data()
//     if (data.accessToken.token === null) {
//         return ({ token: null, expirationDate: null })
//     }
//     const isTokenValid = await validateAccessToken(data.accessToken.token)
//     if (!isTokenValid || !data.accessToken.expirationDate < Date.now()) {
//         const idToken = await auth.currentUser.getIdToken();
//         const response = await axios.post(
//           "https://us-central1-cyrus-university-website.cloudfunctions.net/refreshTokenHandler",
//           {uid},
//           {
//             headers: {
//               Authorization: "Bearer " + idToken,
//               "Content-Type": "application/json",
//             },
//           }
//         );
//         console.log(response.data.message)
//         const docSnap = await getDoc(docRef)
//         const data = docSnap.data()
//         if (data.accessToken.token === null) {
//             return ({ token: null, expirationDate: null })
//         }
//         return data.accessToken
//     }
//     return data.accessToken
// }
export const getAccessToken = async ({ uid }) => {
    try {
        // Fetch the user document from Firestore
        const docRef = doc(firestoreDb, "users", uid);
        const docSnap = await getDoc(docRef);

        // Check if the document exists
        if (!docSnap.exists()) {
            throw new Error("User document not found");
        }

        const { accessToken } = docSnap.data();

        // If token is null, return early with null values
        if (!accessToken?.token) {
            return { token: null, expirationDate: null };
        }

        // Validate the token
        const isTokenValid = await validateAccessToken(accessToken.token);

        // If the token is invalid or expired, attempt to refresh it
        if (!isTokenValid || accessToken.expirationDate < Date.now()) {
            const idToken = await auth.currentUser.getIdToken();

            const response = await axios.post(
                "https://us-central1-cyrus-university-website.cloudfunctions.net/refreshTokenHandler",
                { uid },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log(response.data.message);

            // Re-fetch the updated user document
            const updatedDocSnap = await getDoc(docRef);
            const updatedData = updatedDocSnap.data();

            if (!updatedData?.accessToken?.token) {
                return { token: null, expirationDate: null };
            }

            return updatedData.accessToken;
        }

        // Return the valid token
        return accessToken;

    } catch (error) {
        console.error("Error in getAccessToken:", error.message || error);
        throw new Error("Failed to retrieve access token");
    }
};



async function validateAccessToken(accessToken) {
    try {
        // Call the tokeninfo endpoint to get details about the token
        const response = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${accessToken}`);

        // Parse the JSON response
        const data = await response.json();

        // Check if there is an error (e.g., expired token or invalid token)
        if (data.error) {
            throw new Error(data.error_description);
        }

        // Token is valid, log or process token data
        console.log('Token is valid');
        console.log('Token Info:', data);

        // Example: Check if the audience matches your app's client ID
        if (data.aud !== 'YOUR_CLIENT_ID.apps.googleusercontent.com') {
            throw new Error('Token audience mismatch');
        }

        return true; // Token is valid
    } catch (error) {
        console.error('Error validating token:', error);
        return false; // Token is invalid
    }
}

export const createSectionId = async ({ section }) => {
    const updateRef = ref(db, `/managementInfo/counter`);
    const getRef = ref(db, `/managementInfo/counter/${section}`);
    try {
        let snap = await get(getRef);
        let lastCourseNum = await snap.val();
        lastCourseNum++;
        await update(updateRef, { [section]: lastCourseNum });
        return lastCourseNum
    } catch (error) {
        throw new Error(error)
    }
}