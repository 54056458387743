import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  createSectionId,
  createSingleRecord,
  pollAttachmentMetadata,
  updateSignleRecord,
} from "../../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addLesson,
  getAllLessons,
  getLessonById,
} from "../../../redux/lessonsSlice";
import GroupCodeSection from "../sharedComp/GroupCodeSection";
import { Box, Button, InputLabel } from "@mui/material";
import TextAreaComponent from "../sharedComp/TextAreaComponent";
import AddLessonResources from "./AddLessonResources";

const AddNewLesson = () => {
  const user = useSelector((state) => state.user);

  const { recordId } = useParams();
  const lessons = useSelector(getAllLessons);
  let lesson = useSelector((state) => getLessonById(state, recordId));
  const {
    lesson_id,
    title: initTitle = "",
    name: initName = "",
    name_direction: initNameDirection = "rtl",
    title_direction: initTitleDirection = "rtl",
    explain: initExplain = "",
    group_code: initGroupCode = "",
    creator_uid: initCreatorUid = "",
    create_at: initCreateAt = "",
    edit_at: initEditAt = "",
    resource1: initResource1 = "",
    resource2: initResource2 = "",
    resource3: initResource3 = "",
    assignment: initAssignment = "",
    assigned_courses: initAssignedCourses = [],
  } = { ...lesson } || {};
  const [formData, setFormData] = useState({
    book1: null,
    book2: null,
    assignment: null,
    videoLink: initResource2,
  });
  const [groupCode, setGroupCode] = useState(
    lesson?.group_code ||
      (user.profile.role === "instructor" ||
      user.profile.role === "leadInstructor"
        ? user.profile.groupCode
        : "")
  );

  const [titleDirection, setTitleDirection] = useState(initTitleDirection);
  const [nameDirection, setNameDirection] = useState(initNameDirection);
  const [name, setName] = useState(initName);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [title, setTitle] = useState(initTitle);
  const [explain, setExplain] = useState(initExplain);

  const publishFunc = async () => {
    if (!recordId) {
      await createNewLesson();
    } else {
      await editLesson();
    }
  };
  const createNewLesson = async () => {
    const data = {
      title,
      name,
      name_direction: nameDirection,
      title_direction: titleDirection,
      explain,
      group_code: groupCode,
      creator_uid: user.profile.uid,
      resource1: formData.book1 && [{ url: formData.book1 }],
      resource2: formData.videoLink,
      resource3: formData.book2 && [{ url: formData.book2 }],
      assignment: formData.assignment && [{ url: formData.assignment }],
    };

    console.log(data);
    try {
      data.lesson_id = await createSectionId({ section: "lessons" });
      const newRec = await createSingleRecord({
        tn: "lessons",
        data,
      });

      if (newRec) {
        const record = pollAttachmentMetadata({
          tn: "lessons",
          recId: newRec.id,
          attachmentNames: ["resource1", "resource3", "assignment"],
        });
        dispatch(addLesson(record));
        navigate(`/${user.profile.role}panel/lessons`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editLesson = async () => {
    if (title.length < 3) {
      toast.error("title must have 4 letter or more");
      return;
    }
    const data = {};

    if (initGroupCode !== groupCode) {
      data.group_code = groupCode;
    }
    if (initExplain !== explain) {
      data.explain = explain;
    }
    if (initTitleDirection !== titleDirection) {
      data.title_direction = titleDirection;
    }
    if (initNameDirection !== nameDirection) {
      data.name_direction = nameDirection;
    }
    if (initName !== name) {
      data.name = name;
    }
    if (initTitle !== title) {
      data.title = title;
    }
    if (formData.book1) {
      data.resource1 = [{ url: formData.book1 }];
    }
    if (formData.book2) {
      data.resource3 = [{ url: formData.book2 }];
    }
    if (formData.assignment) {
      data.assignment = [{ url: formData.assignment }];
    }
    if (formData.videoLink === initResource2) {
      data.resource2 = [{ url: formData.assignment }];
    }

    if (initCreatorUid !== user.profile.uid) {
      data.creator_uid = user.profile.uid;
    }
    const updatedRec = await updateSignleRecord({
      tn: "lessons",
      recId: recordId,
      data,
    });

    if (updatedRec) {
      const record = pollAttachmentMetadata({
        tn: "lessons",
        recId: recordId,
        attachmentNames: ["resource1", "resource3", "assignment"],
      });
      dispatch(addLesson(record));
      navigate(`/${user.profile.role}panel/lessons`);
    }
    toast.success("successfully edit lesson");
    navigate(`/${user.profile.role}panel/lessons`);
  };

  return (
    <div className="mx-5 mt-5">
      <div className="mb-3 flex items-center justify-between">
        <h2>Add New Lesson</h2>
        <h2>{recordId}</h2>
        <Button
          variant="contained"
          className="w-24"
          sx={{ marginTop: "20px" }}
          onClick={publishFunc}
        >
          Publish
        </Button>
      </div>
      <TextAreaComponent
        props={{
          explain,
          setTitle,
          title,
          titleDirection,
          setTitleDirection,
          setExplain,
          groupCode,
          name,
          nameDirection,
          setName,
          setNameDirection,
        }}
      />

      <div className="border border-slate-400 p-10 pt-4 space-y-8">
        {user.profile.role === "admin" && (
          <Box className="flex items-center space-x-4">
            <InputLabel className="w-44 shrink-0">Group Code</InputLabel>
            <div className="min-w-80">
              <GroupCodeSection
                disabled={false}
                groupCode={groupCode}
                setGroupCode={setGroupCode}
              />
            </div>
          </Box>
        )}

        <AddLessonResources
          formData={formData}
          setFormData={setFormData}
          initAssignment={initAssignment}
          initResource1={initResource1}
          initResource3={initResource3}
        />
      </div>
    </div>
  );
};

export default AddNewLesson;
