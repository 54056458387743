import React from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Select,
  MenuItem,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

const semesters = Array.from({ length: 8 }, (_, i) => i + 1);

const EachCourseSem = ({ course, majorCourses, setMajorCourses }) => {
  const isChecked = majorCourses.map((c) => c[0]).includes(course.course_id);
  // console.log(course);
  const toggleCourseCheck = (course) => {
    setMajorCourses((prevSelections) => {
      const courseId = course.course_id;
      if (isChecked) {
        const updatedSelection = prevSelections.filter(
          (c) => c[0] !== courseId
        );
        return updatedSelection;
      } else {
        if (majorCourses.length >= 5) {
          toast.error("you reach maximum number of course for a major");
          return prevSelections;
        }
        return [...prevSelections, [courseId, 0]];
      }
    });
  };

  const handleSemesterChange = (e) => {
    setMajorCourses((prev) => {
      return prev.map((c) => {
        if (c[0] === course.course_id) {
          return [c[0], e.target.value];
        } else {
          return c;
        }
      });
    });
  };

  return (
    <Box className="flex h-12 border-b items-center px-4 hover:bg-gray-100">
      {/* Image */}
      <Box className="w-1/12">
        <Avatar
          src={course.course_image}
          alt={course.title}
          variant="rounded"
          className="h-10 w-10"
        />
      </Box>

      {/* Group Code */}
      <Box className="w-1/12">
        <Typography variant="body2">{course.group_code}</Typography>
      </Box>
      <Box className="w-2/12">
        <Typography variant="body2">{course.title}</Typography>
      </Box>

      <Box className="w-2/12">
        <Typography variant="body2">{course.name}</Typography>
      </Box>

      <Box className="w-2/12">
        <Typography variant="body2">{course.categories}</Typography>
      </Box>
      <Box className="w-2/12 text-center">
        <Button
          variant="contained"
          color={isChecked ? "success" : "primary"}
          onClick={() => toggleCourseCheck(course)}
          size="small"
          className="capitalize"
          startIcon={isChecked ? <CheckIcon /> : <CloseIcon />}
        >
          {isChecked ? "Checked" : "Check"}
        </Button>
      </Box>

      <Box className="w-2/12 text-center">
        <Select
          value={
            isChecked
              ? majorCourses.find((c) => c[0] === course.course_id)[1]
              : ""
          }
          onChange={handleSemesterChange}
          displayEmpty
          size="small"
          disabled={!isChecked}
          className="w-full"
        >
          <MenuItem value="0" disabled>
            Choose Semester
          </MenuItem>
          {semesters.map((sem) => (
            <MenuItem key={sem} value={sem}>
              Semester {sem}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default EachCourseSem;
