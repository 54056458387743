import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import ShowAssignment from "./ShowAssignment";
import ShowLesson from "./ShowLesson";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ReactQuill from "react-quill";
import ReleaseDateCounter from "../../shared/utils/ReleaseDateCounter";

const EachLessonAcc = ({
  lesson,
  changeComp,
  index,
  expanded,
  setExpanded,
  courseId,
}) => {
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const showAssignment = (assignment) => {
    changeComp(
      <ShowAssignment assignment={assignment[0]} changeComp={changeComp} />
    );
  };
  const showLesson = (lesson) => {
    changeComp(
      <ShowLesson lesson={lesson} changeComp={changeComp} courseId={courseId} />
    );
  };

  return (
    <Accordion
      key={index}
      expanded={expanded === lesson?.id}
      onChange={handleChange(lesson?.id)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${lesson?.id}-content`}
        id={`${lesson?.id}-header`}
      >
        <Typography>{lesson?.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          noWrap
          variant="body2"
          color="text.secondary"
          className="h-20"
        >
          <ReactQuill value={lesson?.explain} readOnly={true} theme="bubble" />
        </Typography>

        {(
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#002868" }}
              onClick={() => {
                showAssignment(lesson?.assignment);
              }}
              disabled={!lesson?.assignment}
            >
              Show Assignment
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#002868" }}
              onClick={() => {
                showLesson(lesson);
              }}
            >
              Show Lesson
            </Button>
          </Box>
        ) || <ReleaseDateCounter date={lesson?.releaseDate?.date} />}
      </AccordionDetails>
    </Accordion>
  );
};

export default EachLessonAcc;
