import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteRealItem,
  deleteSignleRecord,
  deleteStorageRecursively,
} from "../../../firebase.config";
import { getUserById, getUsersByIds } from "../../../redux/usersSlice";
import { useSelector } from "react-redux";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Avatar, TableCell, TableRow, Typography } from "@mui/material";
import TimeShow from "../../../shared/utils/TimeShow";
import { getGroupById } from "../../../redux/groupsSlice";
import ActionsMenu from "../sharedComp/ActionsMenu";
import ViewFile from "../../../layout/ViewFile";
import ImageShow from "../resourses/showFile/ImageShow";
import { formatDate } from "../../../shared/utils/formatTime";
import { useDispatch } from "react-redux";
import { getCoursesByIds, removeCourse } from "../../../redux/coursesSlice";
import ActionsMenuLesson from "./ActionsMenuLesson";
import { removeLesson } from "../../../redux/lessonsSlice";

const LessonRow = ({ item }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const recId = item.id;
  const navigate = useNavigate();
  const assignCourses = useSelector((state) =>
    getCoursesByIds(state, item?.assigned_courses)
  );
  const goToEditLesson = useCallback(() => {
    navigate(`/${user.profile.role}panel/editlesson/${recId}`);
  }, []);

  const deleteLesson = useCallback(async () => {
    if (
      window.confirm(
        `are you willing to proceed removing lesson ${item?.title} ${item?.name} ?`
      ) === false
    ) {
      return;
    }
    try {
      const removedId = await deleteSignleRecord({ tn: "lessons", recId });
      toast.success(`record ${removedId} successfully deleted`);
      dispatch(removeLesson(item.id));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  }, []);

  const viewLesson = useCallback(() => {
    navigate(`/${user.profile.role}panel/lessons/viewlesson/${item.id}`);
  }, [navigate, user.profile.role, item.id]);

  return (
    <TableRow hover>
      <TableCell>{item?.title}</TableCell>
      <TableCell>{item?.name || "---"}</TableCell>
      <TableCell>{item?.row_id}</TableCell>
      <TableCell>
        {assignCourses?.map((course) => (
          <p>{course?.title}</p>
        ))}
      </TableCell>
      <TableCell>{formatDate(item?.create_at)}</TableCell>
      <TableCell>
        {item?.group_code}
        {/* <Avatar
          sx={{
            bgcolor: item?.group,
            width: 15,
            height: 15,
            mx: "auto",
          }}
        /> */}
      </TableCell>
      <TableCell>
        <ActionsMenuLesson
          onView={viewLesson}
          onEdit={goToEditLesson}
          onDelete={deleteLesson}
          lesson={item}
        />
      </TableCell>
    </TableRow>
  );
};

export default LessonRow;
