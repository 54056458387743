import React, { useCallback, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  IconButton,
  CardContent,
  Card,
  styled,
  LinearProgress,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AddResourceFile = ({
  formData,
  initResource,
  setFormData,
  text,
  enEditRe,
  setEnEditRe,
  // clearSelectedFile,
}) => {
  const [progress, setProgress] = useState(0);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [file, setFile] = useState("");

  const handleFileChange = useCallback(
    (key) => async (event) => {
      setUploadingFile(true);
      const tempFormData = new FormData();
      tempFormData.append("file", event.target.files[0]);
      try {
        const response = await axios.post("https://file.io/", tempFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const percent =
                (progressEvent.loaded / progressEvent.total) * 100;
              setProgress(Math.round(percent));
            }
          },
        });

        if (response.data.success) {
          setFile(event.target.files[0]);
          setFormData({ ...formData, [key]: response.data.link });
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setUploadingFile(false);
      }
    },
    [formData, setFormData]
  );

  const clearSelectedFile = useCallback(
    (key) => () => {
      if (
        window.confirm(
          "your uploaded file will be remove, are you want to proceed?"
        ) === false
      )
        return;
      setFormData({ ...formData, [key]: null });
    },
    [formData, setFormData]
  );
  const abortFile = useCallback(
    (key) => () => {
      if (formData[key]) {
        if (
          window.confirm(
            "your uploaded file will be remove, are you want to proceed?"
          ) === false
        )
          return;
      }
      setFormData({ ...formData, [key]: null });
      setEnEditRe(false);
    },
    [formData, setFormData]
  );
  return (
    <Grid item xs={12} className="flex justify-between border p-3">
      <div className="w-96 flex items-center">
        <div className="w-28 flex">
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className="w-24"
          >
            {text}
          </Typography>
          <PictureAsPdfIcon color="disabled" />
        </div>
        <div className="flex items-center justify-center ms-4">
          {uploadingFile ? (
            <Box sx={{ width: "100%", marginBottom: 1 }}>
              <LinearProgress variant="determinate" value={progress} />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginTop: 1 }}
              >
                Uploading: {progress}%
              </Typography>
            </Box>
          ) : formData[text] ? (
            <div className="flex justify-center items-center text-green-500">
              <Typography variant="body2" className="capitalize w-40" noWrap>
                {file.name || "file uploaded"}
              </Typography>
              <CheckIcon />
            </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No file selected
            </Typography>
          )}
        </div>
      </div>
      <div className="flex items-center" title="clear uploaded file">
        {formData[text] && (
          <IconButton onClick={clearSelectedFile(text)}>
            <ClearIcon className="text-cu-red" />
          </IconButton>
        )}
        <Button component="label" role={undefined} tabIndex={-1} size="small">
          <CloudUploadIcon sx={{ fontSize: "40px" }} className="text-cu-blue" />
          <VisuallyHiddenInput
            type="file"
            onChange={handleFileChange(text)}
            accept={"/*"}
            multiple={false}
          />
        </Button>
        {initResource && enEditRe && (
          <Button title="back to initial file" onClick={abortFile(text)}>
            <p className="text-cu-red">Abort</p>
          </Button>
        )}
      </div>
    </Grid>
  );
};

export default AddResourceFile;
