import { TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchBox = ({ setSearchText }) => {
  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder="Search"
      fullWidth
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      InputProps={{
        style: { borderRadius: "20px", paddingRight: "8px" }, // Rounded edges and compact padding
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end">
              <SearchIcon style={{ color: "#6b7280" }} /> {/* Subtle gray */}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBox;
