import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getAllCourses,
  getCourseById,
  getCoursesByIds,
} from "../../../redux/coursesSlice";
import { useSelector } from "react-redux";
import { getLessonsByIds } from "../../../redux/lessonsSlice";
import EachLessonContentDrip from "./EachLessonContentDrip";
import { toast } from "react-toastify";
import {
  updateChunkRecord,
  updateContentDripToDb,
} from "../../../firebase.config";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
// import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "../../../shared/comp/LoadingButton";
import { useLoadCourses } from "../../../shared/customHooks/useLoadCourses";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";
import { useLoadLessons } from "../../../shared/customHooks/useLoadLessons";

const ContentDrip = () => {
  const crsLoading = useLoadCourses(true);
  const lsnLoading = useLoadLessons(true);
  const user = useSelector((state) => state.user);
  const courses = useSelector(getAllCourses);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [postLoading, setPostLoading] = useState(false);

  const assignedLessons = selectedCourse?.assigned_lessons || [];

  const lessonInCourse = useSelector((state) =>
    getLessonsByIds(state, [...assignedLessons])
  );
  const initLessonDate =
    lessonInCourse.reduce((acc, curr) => {
      if (curr?.release_date) {
        acc[curr.id] = new Date(curr?.release_date);
      }
      return acc;
    }, {}) || {};

  const [lessonDateList, setLessonDateList] = useState({});

  const filteredCourses =
    user.profile.role === "admin"
      ? courses
      : user.profile.role === "leadInstructor"
      ? courses.filter((course) => course.group_code === user.profile.groupCode)
      : user.profile.role === "instructor"
      ? courses.filter(
          (course) =>
            user.assignedCourses &&
            Object.keys(user.assignedCourses).includes(course.course_id)
        )
      : [];

  const changeCourse = (e) => {
    const course = courses.find((course) => course.id === e.target.value);
    if (Object.keys(lessonDateList).length > 0) {
      if (
        window.confirm(
          "previos selected date will be abort if you proceed ?"
        ) === false
      ) {
        return;
      }
    }
    setLessonDateList({});
    setSelectedCourse(course);
  };

  const updateReleaseDates = async () => {
    if (!Object.keys(lessonDateList).length) {
      toast.error("there is nothing to update");
      return;
    }
    try {
      setPostLoading(true);
      const data = [];
      for (let p in lessonDateList) {
        data.push({
          id: p,
          fields: { release_date: lessonDateList[p] },
        });
      }

      const res = await updateChunkRecord({ tn: "lessons", data });
      setLessonDateList({});
      toast.success("release date updated");
    } catch (error) {
      toast.error(error.message);
    }
    setPostLoading(false);
  };
  console.log(lessonDateList);
  return (
    <Box sx={{ mt: 4, mx: "auto", position: "relative" }}>
      <LoadingOverlay loading={crsLoading && lsnLoading} />
      <Paper elevation={3} sx={{ p: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="course-select-label">Select Course</InputLabel>
          <Select
            labelId="course-select-label"
            onChange={changeCourse}
            label="Select Course"
            value={selectedCourse?.id || ""}
          >
            {filteredCourses.map((course) => (
              <MenuItem key={course.id} value={course.id}>
                {course.course_id} — {course.title} — {course.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ mt: 4 }}>
          {lessonInCourse.map((lesson, index) => (
            <Paper
              key={lesson.id}
              sx={{
                p: 2,
                mb: 2,
                backgroundColor: index % 2 === 0 ? "grey.100" : "grey.200",
              }}
            >
              <EachLessonContentDrip
                lesson={lesson}
                setLessonDateList={setLessonDateList}
                lessonDateList={lessonDateList}
              />
            </Paper>
          ))}
        </Box>
        {selectedCourse?.assigned_lessons?.length ? (
          <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            <LoadingButton
              loading={postLoading}
              name="Update Lessons Release Date"
              disabled={postLoading}
              onClick={updateReleaseDates}
            />
          </Box>
        ) : (
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "error.main", mt: 4 }}
          >
            There are no lessons to update
          </Typography>
        )}
      </Paper>
    </Box>
    // <div className="mt-10 w-9/12 m-auto">
    //   <LoadingOverlay loading={crsLoading && lsnLoading} />
    //   <FormControl fullWidth>
    //     <InputLabel id="course-select-label">Select Course</InputLabel>
    //     <Select
    //       labelId="course-select-label"
    //       onChange={changeCourse}
    //       label="Select Course"
    //       className="mb-10"
    //     >
    //       {filteredCourses.map((course, index) => (
    //         <MenuItem key={index} value={course?.id}>
    //           {course?.course_id} --- {course?.title} --- {course?.name}
    //         </MenuItem>
    //       ))}
    //     </Select>
    //   </FormControl>
    //   <div className="flex flex-col">
    //     {lessonInCourse.map((lesson, index) => (
    //       <div
    //         className={`${index % 2 ? "bg-slate-100" : "bg-slate-200"} px-3`}
    //       >
    //         <EachLessonContentDrip
    //           key={lesson.id}
    //           lesson={lesson}
    //           setLessonDateList={setLessonDateList}
    //           lessonDateList={lessonDateList}
    //         />
    //       </div>
    //     ))}
    //   </div>
    //   {selectedCourse && selectedCourse?.assigned_lessons ? (
    //     <div
    //       className="mt-10 w-72 flex items-center justify-center"
    //       onClick={updateReleaseDates}
    //     >
    //       <LoadingButton
    //         loading={postLoading}
    //         name={"update lessons release date"}
    //         disabled={postLoading}
    //       />
    //     </div>
    //   ) : (
    //     <div className="capitalize text-center text-cu-red">
    //       there is no lessons to update
    //     </div>
    //   )}
    // </div>
  );
};

export default ContentDrip;
