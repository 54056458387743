import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getAccessToken, getUserInfo } from "../firebase.config";

const userInitialState = {
  currentUser: null,
  profile: {
    name: "",
    email: "",
    family: "",
    createAt: "",
    role: "",
    uid: "",
    photoUrl: "",
    accountCreator: "",
    courses: [],
  },
  address: {
    privateEmail: "",
    country: "",
    city: "",
    street: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    homeContactNumber: "",
    unit: "",
  },
  documents: {
    registeryForm: {},
  },
};

export const getUserProfile = createAsyncThunk(
  "/getuserprofile",
  async (currentUser, { rejectWithValue }) => {
    try {
      const idTokenResult = await currentUser.getIdTokenResult();
      const groupCode = idTokenResult.claims.groupCode;
      // const accessToken = await getAccessToken({ uid: currentUser.uid });
      const userInfo = await getUserInfo(currentUser.uid, groupCode);
      const currentUserSerializable = {
        uid: currentUser?.uid,
        email: currentUser?.email,
        displayName: currentUser?.displayName,
        lastLogin: currentUser?.metadata?.lastSignInTime,
        creationTime: currentUser?.metadata?.creationTime,
      };
      return {
        userInfo,
        currentUser: currentUserSerializable,
        accessToken: { token: null, expirationDate: null },
      };
    } catch (error) {
      toast.error(error.message || "Failed to fetch user profile.");
      return rejectWithValue(error.message); // Return error to the rejected case
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    logoutUser: () => {
      return userInitialState; // Reset to initial state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        if (payload && payload.userInfo) {
          return {
            ...payload.userInfo,
            currentUser: payload.currentUser,
            accessToken: payload.accessToken,
          };
        } else {
          console.warn("Invalid payload received for getUserProfile.fulfilled");
        }
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        console.error("Error in getUserProfile:", action.payload);
        toast.error("Unable to load user information.");
      });
  },
});

export const { logoutUser } = userSlice.actions;
export default userSlice.reducer;
