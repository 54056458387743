import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteRealItem,
  deleteStorageRecursively,
} from "../../../firebase.config";
import { toast } from "react-toastify";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getGroupById } from "../../../redux/groupsSlice";
import TimeShow from "../../../shared/utils/TimeShow";
import { useLoadCourses } from "../../../shared/customHooks/useLoadCourses";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";
import { useLoadMajors } from "../../../shared/customHooks/useLoadMajors";
const UserReportInfo = ({ item }) => {
  const navigate = useNavigate();
  const goToEditUser = () => {
    // dispatch(getSelectedUser({ item }));
    navigate(`/adminpanel/editmember/${item.id}/createemail`);
  };
  const profile = item.profile;
  const major = useSelector((state) => getGroupById(state, profile?.groupCode));

  const deleteUser = async () => {
    if (!item.profile.uid || !item.profile.groupCode)
      throw new Error("id is not exists");
    if (window.confirm("are you sure to remove user")) {
      const realPath = `/users/${item.profile.uid}`;
      const notificationPath = `/notifications/${item.profile.uid}`;
      const storagePath = `/members/${item.profile.role}/student/${item.profile.uid}`;
      await deleteRealItem(realPath);
      await deleteRealItem(notificationPath);
      await deleteStorageRecursively(storagePath);
      toast.success("successfully delete user");
    }
  };

  const viewUser = () => {
    navigate(`viewuser/${item.profile.uid}`);
  };

  return (
    <div className=" w-full flex items-center h-52  relative border">
      <Paper variant="outlined" sx={{ padding: 2 }} className="w-full  h-52 ">
        <div className="absolute top-2 right-5">
          <Avatar
            sx={{ width: 80, height: 80 }}
            src={profile?.photoUrl.url}
            alt={profile?.name}
          />
        </div>
        <Box>
          <Typography variant="h6 capitalize">
            {profile?.name} {profile?.family}
          </Typography>
        </Box>

        <div className="flex flex-col gap-y-3 mt-3">
          <Box>
            <Typography variant="body1">Email: {profile?.email}</Typography>
          </Box>
          <Box>
            {profile.role === "student" && (
              <Typography variant="body1">
                SN: {profile?.studentNumber}
              </Typography>
            )}
          </Box>
          <Box>
            {profile?.role === "student" && (
              <Typography variant="body1">Major: {major?.e_title}</Typography>
            )}
          </Box>
        </div>

        <div className="absolute right-5 bottom-2 text-center">
          <Box>
            Account Creation Date:{" "}
            <TimeShow date={new Date(profile?.createAt)} />
          </Box>
        </div>
      </Paper>
    </div>
  );
};

export default UserReportInfo;
