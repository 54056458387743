import React, { useEffect, useState } from "react";
import { changePassword } from "../../firebase.config";
import { toast } from "react-toastify";
import { ReactComponent as PostLoadingComp } from "../../images/svg/postsvgLoading.svg";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
// import LoadingButton from "@mui/lab/LoadingButton";
import cyrusUniLogo from "../../images/logo.webp";
import { isValidCyrusUniversityEmail } from "../../shared/utils/isValidCyrusUniversityEmail";
import axios from "axios";
const AuthenticatePassword = () => {
  const navigate = useNavigate();
  const [googleToken, setGoogleToken] = useState("");
  const user = useSelector((state) => state.user);
  const { email } = useParams();
  console.log(email);
  const handleCredentialResponse = (response) => {
    console.log("Google ID Token:", response.credential);
    setGoogleToken(response.credential); // Save token in state
  };

  useEffect(() => {
    // Initialize Google Identity Services
    window.google.accounts.id.initialize({
      client_id:
        "777209034475-n7kn0j0fob1akp3pd6jokq524mth6cvv.apps.googleusercontent.com",
      callback: handleCredentialResponse,
      auto_select: true,
    });

    // Automatically prompt the user for token (silent refresh if user already logged in)
    window.google.accounts.id.prompt();
  }, []);

  useEffect(() => {
    const callResPass = async () => {
      if (!googleToken) return;
      if (!isValidCyrusUniversityEmail(email)) {
        toast.error("this is not a valid cyrus university email!");
        return;
      }

      const res = await axios.get(
        `https://us-central1-cyrus-university-website.cloudfunctions.net/generateResetLink`,
        {
          headers: {
            Authorization: `Bearer ${googleToken}`, // Send Google token
          },
          params: { email }, // Pass the email as a query parameter
        }
      );
      window.open(res.data, "_blank", "noopener,noreferrer");
      navigate("/login");
    };
    callResPass();
  }, [email, googleToken, navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(4px)",
        }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 5,
        }}
        elevation={6}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img className="contain" src={cyrusUniLogo} alt="Logo" />
          <Typography variant="h5" component="h2" sx={{ mb: 1, mt: 7 }}>
            Set a Password
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default AuthenticatePassword;
