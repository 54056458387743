// import React, { useCallback } from "react";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import EditIcon from "@mui/icons-material/Edit";
// import { toast } from "react-toastify";
// import { removeNotificationFromDb } from "../../../firebase.config";
// import { useNavigate } from "react-router-dom";
// import TimeShow from "../../../shared/utils/TimeShow";
// import ActionsMenu from "../sharedComp/ActionsMenu";

// const NotificationRow = ({ notification, index }) => {
//   const navigate = useNavigate();

//   const removeNotification = useCallback(async () => {
//     if (
//       window.confirm(
//         `are you sure to remove notification ${notification.info.title} ?`
//       )
//     ) {
//       try {
//         await removeNotificationFromDb(notification);
//         toast.success("notification deleted successfully");
//       } catch (error) {
//         console.log(error);
//         toast.error(error.message);
//       }
//     }
//   }, []);

//   const goToEdit = useCallback(() => {
//     navigate(`pusheditnotification/${notification.info.notificationId}`);
//   }, []);

//   const viewNotification = useCallback(() => {
//     navigate(`pushviewnotification/${notification.info.notificationId}`);
//   }, []);

//   return (
//     <div
//       className={`flex justify-between bg-slate-${
//         index % 2 ? "100" : "200"
//       } p-2`}
//     >
//       <p className="w-2/12">{notification.info.notificationId}</p>
//       <p className="w-6/12" dir={notification.info.inputDirction}>
//         {notification.info.title}
//       </p>
//       <p className="w-2/12">
//         {TimeShow({ date: new Date(notification.info.createAt) })}
//       </p>
//       <div className="w-2/12">
//         {/* <button
//           onClick={removeNotification}
//           className="text-cu-red justify-end"
//         >
//           <DeleteForeverIcon color="bg-cu-red" />
//         </button>
//         <button onClick={goToEdit} className="text-cu-blue justify-end">
//           <EditIcon />
//         </button> */}
//         <ActionsMenu
//           onDelete={removeNotification}
//           onEdit={goToEdit}
//           onView={viewNotification}
//         />
//       </div>
//     </div>
//   );
// };

// export default NotificationRow;
// import React, { useCallback, useState } from "react";
// import {
//   Card,
//   CardContent,
//   CardActions,
//   IconButton,
//   Typography,
//   Box,
// } from "@mui/material";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { removeNotificationFromDb } from "../../../firebase.config";
// import TimeShow from "../../../shared/utils/TimeShow";

// const NotificationRow = ({ notification, index }) => {
//   const navigate = useNavigate();

//   const removeNotification = useCallback(async () => {
//     if (
//       window.confirm(
//         `Are you sure you want to delete the notification "${notification.info.title}"?`
//       )
//     ) {
//       try {
//         await removeNotificationFromDb(notification);
//         toast.success("Notification deleted successfully.");
//       } catch (error) {
//         console.error(error);
//         toast.error(error.message);
//       }
//     }
//   }, [notification]);

//   const goToEdit = useCallback(() => {
//     navigate(`pusheditnotification/${notification.info.notificationId}`);
//   }, [navigate, notification]);

//   const viewNotification = useCallback(() => {
//     navigate(`pushviewnotification/${notification.info.notificationId}`);
//   }, [navigate, notification]);

//   return (
//     <Card
//       sx={{
//         marginBottom: 2,
//         backgroundColor: index % 2 === 0 ? "background.paper" : "grey.100",
//         boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
//         borderRadius: 4,
//         display: "flex",
//         flexDirection: "column",
//       }}
//     >
//       <CardContent>
//         <Box display="flex" justifyContent="space-between" alignItems="center">
//           <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
//             {notification.info.notificationId}
//           </Typography>
//           <Typography
//             variant="body2"
//             sx={{ fontStyle: "italic", textAlign: "right" }}
//           >
//             {TimeShow({ date: new Date(notification.info.createAt) })}
//           </Typography>
//         </Box>
//         <Typography
//           variant="body1"
//           sx={{
//             marginTop: 1,
//             textAlign: notification.info.titleDirection === "rtl" ? "right" : "left",
//             wordBreak: "break-word",
//             fontWeight: "bold",
//           }}
//         >
//           {notification.info.title}
//         </Typography>
//         {notification.info.text && (
//           <Typography
//             variant="body2"
//             sx={{
//               marginTop: 1,
//               color: "text.secondary",
//               textAlign: notification.info.titleDirection === "rtl" ? "right" : "left",
//               display: "-webkit-box",
//               WebkitBoxOrient: "vertical",
//               overflow: "hidden",
//               WebkitLineClamp: 2,
//               lineHeight: 1.5,
//               wordBreak: "break-word",
//             }}
//           >
//             {notification.info.text}
//           </Typography>
//         )}
//       </CardContent>
//       <CardActions sx={{ justifyContent: "flex-end" }}>
//         <IconButton
//           color="primary"
//           onClick={viewNotification}
//           aria-label="View Notification"
//         >
//           <VisibilityIcon />
//         </IconButton>
//         <IconButton
//           color="secondary"
//           onClick={goToEdit}
//           aria-label="Edit Notification"
//         >
//           <EditIcon />
//         </IconButton>
//         <IconButton
//           color="error"
//           onClick={removeNotification}
//           aria-label="Delete Notification"
//         >
//           <DeleteForeverIcon />
//         </IconButton>
//       </CardActions>
//     </Card>
//   );
// };

// export default NotificationRow;
import React, { useCallback, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Box,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { removeNotificationFromDb } from "../../../firebase.config";
import TimeShow from "../../../shared/utils/TimeShow";
import { useSelector } from "react-redux";

const NotificationRow = ({ notification, index }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const removeNotification = useCallback(async () => {
    if (
      window.confirm(
        `Are you sure you want to delete the notification "${notification.info.title}"?`
      )
    ) {
      try {
        await removeNotificationFromDb(notification);
        toast.success("Notification deleted successfully.");
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  }, [notification]);

  const goToEdit = useCallback(() => {
    navigate(`pusheditnotification/${notification.info.notificationId}`);
  }, [navigate, notification]);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Card
      sx={{
        marginBottom: 2,
        backgroundColor: index % 2 === 0 ? "background.paper" : "grey.100",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {notification.info.notificationId}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontStyle: "italic", textAlign: "right" }}
          >
            {TimeShow({ date: new Date(notification.info.createAt) })}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            marginTop: 1,
            textAlign:
              notification.info.titleDirection === "rtl" ? "right" : "left",
            wordBreak: "break-word",
            fontWeight: "bold",
          }}
        >
          {notification.info.title}
        </Typography>
        {notification.info.text && (
          <Collapse in={expanded} collapsedSize="2rem">
            <Typography
              variant="body2"
              sx={{
                marginTop: 1,
                color: "text.secondary",
                textAlign:
                  notification.info.textDirection === "rtl" ? "right" : "left",
                lineHeight: 1.5,
                wordBreak: "break-word",
                overflow: "hidden",
                display: expanded ? "block" : "-webkit-box",
                // WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {notification.info.text}
            </Typography>
          </Collapse>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Box>
          <IconButton
            onClick={toggleExpanded}
            aria-label={expanded ? "Collapse text" : "Expand text"}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        {(user.profile.role === "admin" ||
          user.profile.uid === notification.info.creatorId) && (
          <Box>
            <IconButton
              color="secondary"
              onClick={goToEdit}
              aria-label="Edit Notification"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={removeNotification}
              aria-label="Delete Notification"
            >
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        )}
      </CardActions>
    </Card>
  );
};

export default NotificationRow;
