import React, { useState } from "react";
import { Container, Typography, Paper, Grid, Button, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { getCourseById } from "../../redux/coursesSlice";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import ViewFile from "../../layout/ViewFile";
import ShowCourseContent from "./ShowCourseContent";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import { useLoadCourse } from "../../shared/customHooks/useLoadCourse";
import LoadingOverlay from "../../shared/comp/LoadingOverlay";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import LogoImage from "../shared/resourses/LogoImage";
import { useLoadMultipleCourse } from "../../shared/customHooks/useLoadmultipleCourse";
const CoursePage = () => {
  const { courseId } = useParams();
  const user = useSelector((state) => state.user);
  const loading = useLoadMultipleCourse(true, [courseId]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeComp = (comp) => {
    setCurrentComp(comp);
  };
  const [currentComp, setCurrentComp] = useState(
    <ShowCourseContent changeComp={changeComp} />
  );
  const closeModal = () => {
    document.body.style.overflowY = "scroll";
    setIsModalOpen(false);
  };
  const course = useSelector((state) => getCourseById(state, courseId));

  const initGrade =
    (user?.reportCards &&
      course?.course_id &&
      user?.reportCards[course?.course_id] &&
      user?.reportCards[course?.course_id]?.grade) ||
    "";
  const currentStatus =
    initGrade === "" ? "under process" : initGrade >= 60 ? "passed" : "failed";

  const openCourse = () => {
    setIsModalOpen(true);
    setCurrentComp(<ShowCourseContent changeComp={changeComp} />);
  };

  const instructor =
    user?.assignedCourses &&
    user?.assignedCourses[course?.course_id] &&
    user?.assignedCourses[course?.course_id].instructor;
  return (
    <div className=" w-full">
      {/* <div className="relative top-52"> */}
      <LoadingOverlay loading={loading} />
      <Container maxWidth="md">
        <Typography variant="h2" gutterBottom>
          {course?.title}
        </Typography>
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            margin: "30px 0px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid container spacing={2} style={{ position: "relative" }}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Title:</strong> {course?.title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Instructor:</strong> {instructor}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Categories:</strong> {course?.categoriesName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Duration:</strong> {course?.duration}{" "}
                {course?.duration_unit}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Prerequisite:</strong> {course?.prerequisite}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Level:</strong> {course?.level}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Unit:</strong> {course?.unit}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Status:</strong>{" "}
                {currentStatus === "passed" ? (
                  <div>
                    <CheckBoxIcon color="success" />
                    {currentStatus}
                  </div>
                ) : currentStatus === "failed" ? (
                  <div>
                    <CloseIcon sx={{ color: "red" }} />
                    {currentStatus}
                  </div>
                ) : (
                  <div>
                    <HourglassTopIcon />
                    {currentStatus}
                  </div>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Grade:</strong> {initGrade}
              </Typography>
            </Grid>

            <div className="absolute top-3 right-3">
              <LogoImage attachments={course?.course_image} size={100} />
              <Button onClick={openCourse}>
                continue to lessons <KeyboardArrowRightIcon />
              </Button>
              {/* </div> */}

              <ViewFile
                isOpen={isModalOpen}
                onClose={closeModal}
                title={course?.title || ""}
              >
                {currentComp}
              </ViewFile>
            </div>
          </Grid>
        </Paper>
        <div className="file-child-center">
          <ReactQuill value={course?.explain} readOnly={true} theme="bubble" />
        </div>
      </Container>
    </div>
  );
};

export default CoursePage;
