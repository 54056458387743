export function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    // Extract year, month, day, hour, and minutes
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Construct the desired format
    return <div className="text-center"><p>{year}/{day}/{month}</p><p> At {hours}:{minutes}</p></div>;
}
