import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGroupById } from "../../../redux/groupsSlice";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import PdfShow from "../resourses/showFile/PdfShow";
import { getUserById } from "../../../redux/usersSlice";
import LogoItem from "../resourses/LogoItem";
import LogoPdf from "../resourses/LogoPdf";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
const ReviewGroup = () => {
  let { recordId } = useParams();
  const group = useSelector((state) => getGroupById(state, recordId));
  const leadInstructor = useSelector((state) =>
    getUserById(state, group?.lead_instructor)
  );
  const creator = useSelector((state) =>
    getUserById(state, group?.creator_uid)
  );

  return (
    <div>
      <Card className="w-11/12 mx-auto mb-4">
        {/* <img
        src={course.files.courseImage.url}
        alt={title}
        className="w-full h-64 object-contain"
      /> */}
        <CardContent>
          {/* Group One */}
          <Typography
            variant="h3"
            component="div"
            className="text-center"
            sx={{ fontWeight: "600" }}
          >
            {group?.e_title}
          </Typography>
          <Typography variant="h6" component="div" className="pt-3 pb-10">
            Major Details
          </Typography>
          <Typography component="div" className="capitalize">
            active{" "}
            {group?.active ? (
              <CheckIcon className="text-green-700" />
            ) : (
              <ClearIcon className="text-red-700" />
            )}
          </Typography>
          <Grid container spacing={1} className="pt-4">
            <Grid item xs={6}>
              <Typography className="capitalize" variant="body2">
                group code: {group?.code}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                Persian Name : {group?.p_title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="capitalize" variant="body2">
                Lead Instructor : {leadInstructor?.profile?.name}{" "}
                {leadInstructor?.profile?.family}
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography className="capitalize" variant="body2">
                Color : {group.color}
              </Typography>
            </Grid> */}
            <Grid item xs={6}>
              <Typography className="capitalize" variant="body2">
                creator : {creator?.profile?.name} {creator?.profile?.family}
              </Typography>
            </Grid>
          </Grid>
          {group?.catalog_url && (
            <div className="flex items-center justify-center mt-5">
              <LogoPdf size={300} attachments={group.catalog_url} />
            </div>
          )}
        </CardContent>
        {/* <ViewAddedResources /> */}
      </Card>
    </div>
  );
};

export default ReviewGroup;
