import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GroupCodeSection from "../../sharedComp/GroupCodeSection";
import SelectUserComp from "../../sharedComp/SelectUserComp";
import PrerequisiteAssign from "../PrerequisiteAssign";
import CourseImageSettings from "./CourseImageSettings";
import { getAllUsers } from "../../../../redux/usersSlice";
import LessonAssign from "../lessonAssign/LessonAssign";

const CourseGeneralSettings = ({ generalProps }) => {
  const {
    unit,
    setUnit,
    level,
    setLevel,
    catName,
    setCatName,
    necessity,
    setNecessity,
    duration,
    setDuration,
    durationUnit,
    setDurationUnit,
    prerequisites,
    setPrerequisites,
    setAssignedInstructor,
    assignedInstructor,
    groupCode,
    setGroupCode,
    imageTempUrl,
    setImageTempUrl,
    assignedLessons,
    setAssignedLessons,
    initCourseImage,
    initCourseMaterial,
    materialTempUrl,
    setMaterialTempUrl,
  } = generalProps;

  const users = useSelector(getAllUsers);
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const [instructors, setInstructors] = useState([]);
  const [deadline, setDeadline] = useState("none");

  useEffect(() => {
    setInstructors(
      users.filter((user) => {
        return (
          user.profile.role === "instructor" ||
          user.profile.role === "leadInstructor"
        );
      })
    );
  }, [users]);

  useEffect(() => {
    let courseDuration;
    if (durationUnit === "week") {
      courseDuration = 604800000 * duration;
    } else if (durationUnit === "day") {
      courseDuration = 86400000 * duration;
    } else if (durationUnit === "hour") {
      courseDuration = 3600000 * duration;
    }
    if (+duration) {
      setDeadline(courseDuration + Date.now());
    } else {
      setDeadline("none");
    }
  }, [duration, durationUnit]);

  return (
    <Box className="p-10 pt-4 space-y-8">
      {/* Group Code */}
      <Box className="flex items-center space-x-4">
        <InputLabel className="w-44 shrink-0">Group Code</InputLabel>
        <GroupCodeSection
          disabled={false}
          groupCode={groupCode}
          setGroupCode={setGroupCode}
        />
      </Box>

      {/* Assigned Instructor */}
      <Box className="flex items-center space-x-4">
        <InputLabel className="w-44 shrink-0">Assigned Instructor</InputLabel>
        <SelectUserComp
          users={instructors}
          selectedUser={assignedInstructor}
          setSelectedUser={setAssignedInstructor}
        />
      </Box>

      {/* Duration */}
      <Box className="flex items-center space-x-4">
        <InputLabel className="w-44 shrink-0">Duration</InputLabel>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          className="w-20"
          value={+duration}
          onChange={(e) =>
            setDuration(e.target.value >= 0 ? +e.target.value : 0)
          }
        />
        <FormControl size="small" className="w-32">
          <Select
            value={durationUnit}
            onChange={(e) => setDurationUnit(e.target.value)}
          >
            <MenuItem value="hour">Hour(s)</MenuItem>
            <MenuItem value="day">Day(s)</MenuItem>
            <MenuItem value="week">Week(s)</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="caption">Set to 0 for lifetime access.</Typography>
      </Box>

      {/* Deadline */}
      <Box className="flex items-center space-x-4">
        <Typography className="w-44 shrink-0">Course Deadline:</Typography>
        <Typography>
          {deadline !== "none" ? new Date(deadline).toLocaleString() : "None"}
        </Typography>
      </Box>

      {/* Level */}
      <Box className="flex items-center space-x-4">
        <InputLabel className="w-44 shrink-0">Level</InputLabel>
        <FormControl size="small" className="w-32">
          <Select value={level} onChange={(e) => setLevel(e.target.value)}>
            <MenuItem value="all">All levels</MenuItem>
            <MenuItem value="beginner">Beginner</MenuItem>
            <MenuItem value="intermediate">Intermediate</MenuItem>
            <MenuItem value="expert">Expert</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="caption">Choose a difficulty level.</Typography>
      </Box>

      {/* Necessity */}
      <Box className="flex items-center space-x-4">
        <InputLabel className="w-44 shrink-0">Necessity</InputLabel>
        <FormControl size="small" className="w-32">
          <Select
            value={necessity}
            onChange={(e) => setNecessity(e.target.value)}
          >
            <MenuItem value="main">Main</MenuItem>
            <MenuItem value="optional">Optional</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Prerequisites */}
      <Box className="flex items-center space-x-4">
        <InputLabel className="w-44 shrink-0">Prerequisite Courses</InputLabel>
        <PrerequisiteAssign
          prerequisites={prerequisites}
          setPrerequisites={setPrerequisites}
        />
      </Box>

      <Box className="flex items-center space-x-4">
        <InputLabel className="w-44 shrink-0 ">Assigned Lesson</InputLabel>

        <LessonAssign
          assignedLessons={assignedLessons}
          setAssignedLessons={setAssignedLessons}
          groupCode={groupCode}
        />
      </Box>

      {/* Unit */}
      <Box className="flex items-center space-x-4">
        <InputLabel className="w-44 shrink-0">Unit</InputLabel>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          className="w-20"
          value={unit}
          onChange={(e) => setUnit(e.target.value >= 0 ? +e.target.value : 0)}
        />
      </Box>

      {/* Feature Image */}
      <Box className="flex items-center space-x-4">
        <InputLabel className="w-44 shrink-0">Feature Image</InputLabel>
        <CourseImageSettings
          tempUrl={imageTempUrl}
          setTempUrl={setImageTempUrl}
          initFile={initCourseImage}
          acceptedType={
            ".jpeg, .jpg, .png, .gif, .svg, .bmp, .tif, .tiff, .heic"
          }
        />
      </Box>
      <Box className="flex items-center space-x-4">
        <InputLabel className="w-44 shrink-0">Course Material</InputLabel>
        <CourseImageSettings
          tempUrl={materialTempUrl}
          setTempUrl={setMaterialTempUrl}
          initFile={initCourseMaterial}
          acceptedType={".pdf"}
        />
      </Box>
    </Box>
  );
};

export default CourseGeneralSettings;
