import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Box, Button, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";

import { useLoadLessons } from "../../../../shared/customHooks/useLoadLessons";
import { getAllLessons } from "../../../../redux/lessonsSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP + 100,
      width: 250,
    },
  },
  disablePortal: true, // Disable the default search functionality
};

// function arraysEqual(arr1, arr2) {
//   if (arr1.length !== arr2.length) {
//     return false;
//   }
//   const sortedArr1 = arr1.slice().sort();
//   const sortedArr2 = arr2.slice().sort();
//   for (let i = 0; i < sortedArr1.length; i++) {
//     if (sortedArr1[i] !== sortedArr2[i]) {
//       return false;
//     }
//   }
//   return true;
// }
function getStyles(lesson, selectedLesson, theme) {
  return {
    fontWeight: selectedLesson.includes(lesson)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const LessonAssign = ({ assignedLessons, setAssignedLessons, groupCode }) => {
  const [trigger, setTrigger] = useState(false);
  const loading = useLoadLessons(trigger);

  const allLessons = useSelector(getAllLessons);

  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredLessons, setFilteredLessons] = useState(allLessons);
  const inputRef = useRef(null);
  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value && e.target.value;
    setAssignedLessons(value);
  };
  //   const addPrerequisite = () => {};

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpen = () => {
    setTrigger(true);
    setIsDropdownOpen(true);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    setSearchTerm("");
  };

  useEffect(() => {
    const newLessons = allLessons.filter((lesson) =>
      lesson?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );

    if (searchTerm) {
      setFilteredLessons(newLessons);
    } else {
      setFilteredLessons(allLessons);
    }
  }, [allLessons, searchTerm]);

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  console.log(assignedLessons);
  return (
    <FormControl className="w-full">
      <Select
        multiple
        displayEmpty
        value={assignedLessons}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected?.length < 1) {
            return <em>Select Assigned Lessons</em>;
          }
          const selCrs = allLessons.filter((lesson) =>
            selected.includes(lesson?.id)
          );

          return (
            <div className="text-wrap">
              {selCrs.map((crs) => (
                <p>
                  {crs?.title} - {crs?.name}
                </p>
              ))}
            </div>
          );
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        {isDropdownOpen &&
          // <div className="px-3">
          (loading ? (
            <MenuItem disabled>
              <Box display="flex" alignItems="center">
                <CircularProgress size={20} sx={{ marginRight: 1 }} />
                Loading...
              </Box>
            </MenuItem>
          ) : (
            <div
              className="px-3"
              onKeyDown={(e) => e.stopPropagation()}
              onClickCapture={stopImmediatePropagation}
            >
              <TextField
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                variant="outlined"
                fullWidth
                margin="dense"
                inputRef={inputRef}
              />
            </div>
          ))}

        {filteredLessons?.map((lesson) => (
          <MenuItem
            key={lesson?.id}
            value={lesson?.id}
            style={getStyles(lesson, assignedLessons, theme)}
            // onKeyDown={(e) => e.stopPropagation()}
          >
            <Checkbox checked={assignedLessons.indexOf(lesson?.id) > -1} />
            <ListItemText primary={`${lesson?.title} ${lesson?.name}`} />
          </MenuItem>
        ))}

        {/* <div className="flex w-full items-center justify-center sticky bottom-0 bg-white py-1">
          <Button
            variant="contained"
            color="primary"
            onClick={addPrerequisite}
            disabled={arraysEqual(
              [undefined, ...prerequisiteLessons],
              selectedLesson
            )}
          >
            {" "}
            Save Changes{" "}
          </Button>
        </div> */}
      </Select>
    </FormControl>
  );
};

export default LessonAssign;
