import {
  CheckCircle,
  Error,
  ExpandLess,
  ExpandMore,
  Info,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  editNotificationInDb,
  sendNotificationToDb,
} from "../../../firebase.config";
import CourseNotification from "./customizationComps/CourseNotification";
import UserNotification from "./customizationComps/UserNotification";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllCourses } from "../../../redux/coursesSlice";
import { getAllUsers } from "../../../redux/usersSlice";
import SelectRole from "./customizationComps/SelectRole";
import NotificationType from "./customizationComps/NotificationType";
import SelectCourse from "./customizationComps/SelectCourse";
import GroupCodeSection from "../sharedComp/GroupCodeSection";
import SelectMultipleGroupCode from "./customizationComps/SelectMultipleGroupCode";
import { v4 as uuidv4 } from "uuid";
import { getNotificationById } from "../../../redux/notificationsSlice";

import TextAreaNotificationComp from "./TextAreaNotificationComp";
const AddNewNotification = () => {
  const navigate = useNavigate();

  const { notificationId } = useParams();
  const notification = useSelector((state) =>
    getNotificationById(state, notificationId)
  );
  const user = useSelector((state) => state.user);
  const [titleDirection, setTitleDirection] = useState(
    notification?.info?.titleDirection || "rtl"
  );
  const [textDirection, setTextDirection] = useState(
    notification?.info?.textDirection || "rtl"
  );
  const [title, setTitle] = useState(notification?.info?.title || "");
  const [text, setText] = useState(notification?.info?.text || "");
  const users = useSelector(getAllUsers);
  let alreadySentNotification = notification?.users
    ? users.filter((user) => notification.users.includes(user.profile.uid))
    : [];
  const [selectedUsers, setSelectedUsers] = useState(alreadySentNotification);
  const [filteredUsers, setFilteredUsers] = useState(
    users.sort((a, b) => a.profile.name.localeCompare(b.profile.name))
  );
  const [notificationAlert, setNotificationAlert] = useState(
    notification?.notificationAlert || "info"
  );
  const [selectedCourses, setSelectedCourses] = useState(
    notification?.sendList?.selectedCourses || []
  );
  const [selectedRole, setSelectedRole] = useState(
    notification?.sendList?.selectedRole || []
  );
  const [groupCode, setGroupCode] = useState(
    notification?.sendList?.groupCode || []
  );
  const [customizeSelection, setCustomizeSelection] = useState(
    notification?.sendList?.customizeSelection ? true : false
  );

  useEffect(() => {
    let usersToFilter = selectedCourses.length
      ? users.filter((user) => {
          if (!user.assignedCourses) return false;
          return selectedCourses?.some((course) => {
            return Object.keys(user.assignedCourses)?.includes(course);
          });
        })
      : users;

    usersToFilter = selectedRole.length
      ? usersToFilter.filter((user) => selectedRole.includes(user.profile.role))
      : usersToFilter;

    usersToFilter = groupCode.length
      ? usersToFilter.filter((user) =>
          groupCode.includes(user.profile.groupCode)
        )
      : usersToFilter;

    if (
      !groupCode.length &&
      !selectedRole.length &&
      !selectedCourses.length &&
      !customizeSelection
    ) {
      usersToFilter = [];
      setSelectedUsers(usersToFilter);
    }
    if (groupCode.length || selectedRole.length || selectedCourses.length) {
      setCustomizeSelection(false);
      setSelectedUsers(usersToFilter);
    }
  }, [selectedCourses, users, selectedRole, groupCode]);

  useEffect(() => {
    if (customizeSelection) {
      setGroupCode([]);
      setSelectedCourses([]);
      setSelectedRole([]);
    }
  }, [customizeSelection]);

  const handleSearch = (e) => {
    setFilteredUsers(() =>
      users
        .filter(
          (user) =>
            user.profile.name.includes(e.target.value) ||
            user.profile.family.includes(e.target.value)
        )
        .sort((a, b) => {
          return a.profile?.name.localeCompare(b.profile?.name);
        })
    );
  };

  const sendNofication = async () => {
    if (!title || !text) {
      toast.error("please fill title and text and try again.");
      return;
    }
    try {
      await sendNotificationToDb({
        titleDirection,
        textDirection,
        title,
        text,
        selectedUsers,
        notificationAlert,
        creatorId: user.profile.uid,
        // notificationId,
        customizeSelection,
        groupCode,
        selectedCourses,
        selectedRole,
      });
      toast.success("notification sent");
      navigate(`/${user.profile.role}panel/notifications`);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const editNotification = async () => {
    if (!title || !text) {
      toast.error("please fill title and text and try again.");
      return;
    }
    try {
      await editNotificationInDb({
        titleDirection,
        textDirection,
        title,
        text,
        selectedUsers,
        notificationAlert,
        creatorId: user.profile.uid,
        notificationId,
        customizeSelection,
        groupCode,
        selectedCourses,
        selectedRole,
      });
      toast.success("notification sent");
      navigate(`/${user.profile.role}panel/notifications`);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <div className="flex w-full h-full justify-around p-8 gap-x-3">
      <div className="flex flex-col w-6/12">
        {/* <input
          className="w-full border rounded-md mb-5  px-2 py-2"
          type="text"
          placeholder="Add Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            // dispatch(addLessonTitle(e.target.value));
          }}
          dir={titleDirection}
        /> */}
        <TextAreaNotificationComp
          props={{
            title,
            setTitle,
            titleDirection,
            setTitleDirection,
            text,
            setText,
            textDirection,
            setTextDirection,
          }}
        />
        {/* <input
          type="text"
          className="w-full px-2 py-2 border rounded-md mb-5"
          placeholder="Notification Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <textarea
          type="text"
          className="w-full h-80 border p-3"
          placeholder="Text"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          style={{ whiteSpace: "pre-wrap" }}
        /> */}
        {!notificationId ? (
          <Button
            variant="contained"
            onClick={sendNofication}
            style={{
              background: "#002868",
              color: "white",
              marginTop: "30px",
              width: "100px",
            }}

            // endIcon={<Button>1111</Button>}
          >
            send
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={editNotification}
            style={{
              background: "#002868",
              color: "white",
              marginTop: "30px",
              width: "100px",
            }}

            // endIcon={<Button>1111</Button>}
          >
            Edit
          </Button>
        )}
      </div>
      <div className="flex flex-col w-6/12">
        {/* <Button
          onClick={() => {
            navigate("sentnotifications");
          }}
        >
          check sent notifications
        </Button> */}
        <div className="flex flex-col border gap-y-4">
          {/* <div className="flex flex-col items-center justify-center gap-y-2"> */}
          <Typography className="text-center">Notification Send To:</Typography>
          {/* </div> */}
          <div className="flex">
            <div className="w-6/12 p-3 flex flex-col gap-y-4">
              {Boolean(selectedUsers.length) && customizeSelection && (
                <Typography className="text-cu-red">
                  Customize Selection
                </Typography>
              )}
              <SelectRole
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
              />
              <SelectMultipleGroupCode
                groupCode={groupCode}
                setGroupCode={setGroupCode}
              />
              <SelectCourse
                setSelectedCourses={setSelectedCourses}
                selectedCourses={selectedCourses}
              />
              <NotificationType
                notificationAlert={notificationAlert}
                setNotificationAlert={setNotificationAlert}
              />
            </div>
            <div className="w-6/12">
              <div className="border flex flex-col items-center justify-center ">
                <Typography variant="h6">Selected Users</Typography>

                <div className="h-96 overflow-auto">
                  <TextField
                    className=""
                    label="Search"
                    variant="outlined"
                    // value={searchTerm}
                    onChange={handleSearch}
                    size="small"
                  />
                  <FormGroup>
                    {filteredUsers.map((user) => {
                      return (
                        <UserNotification
                          user={user}
                          selectedUsers={selectedUsers}
                          setSelectedUsers={setSelectedUsers}
                          setCustomizeSelection={setCustomizeSelection}
                        />
                      );
                    })}
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewNotification;
