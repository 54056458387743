import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCourseById } from "../../../redux/coursesSlice";
import ReactQuill from "react-quill";
import { getUserById } from "../../../redux/usersSlice";
import TimeShow from "../../../shared/utils/TimeShow";

const ReviewCourse = () => {
  const { courseId } = useParams();
  const course = useSelector((state) => getCourseById(state, courseId));

  const {
    title,
    explain,
    create_at,
    edit_at,
    status,
    duration,
    duration_unit,
    prerequisites,
    level,
    unit,
  } = course;

  // const { courseCreator, instructors } = course.author;
  // const assignedStudents = course.assignedStudents
  //   ? Object.keys(course.assignedStudents)
  //   : [];

  const courseCreator = useSelector((state) =>
    getUserById(state, course.creator_uid)
  );

  const instructorsId = course.assignedInstructor;

  const instructorsArr = useSelector((state) =>
    getUserById(state, instructorsId)
  );

  // const StuAsFull = useSelector((state) =>
  //   getUsersByIds(state, assignedStudents)
  // );
  // const lsnAsFull = useSelector((state) =>
  //   getLessonsByIds(state, assignedLessons)
  // );

  return (
    <Card className="w-11/12 mx-auto my-4">
      <CardContent>
        {/* Group One */}
        <Typography variant="h5" component="div" className="text-center">
          {title}
        </Typography>
        <div className="flex">
          <div className="w-2/3">
            <Grid container spacing={1} className="pt-8">
              <Grid item xs={6}>
                <Typography variant="body2">Course ID: {courseId}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className="flex gap-x-3">
                  Publish Date: <TimeShow date={new Date(create_at)} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className="flex gap-x-3">
                  Edit At: <TimeShow date={new Date(edit_at)} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className="flex gap-x-3">
                  Create At:
                  <TimeShow date={new Date(create_at)} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Status: {status}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Necessity: {course?.general?.necessity}
                </Typography>
              </Grid>
            </Grid>
            {/* Group Two */}
            <Typography variant="h6" component="div" className="pt-8">
              Course Details
            </Typography>
            <Grid container spacing={1} className="mt-2">
              <Grid item xs={6}>
                <Typography variant="body2">
                  Duration: {duration} {duration_unit}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Prerequisites: {prerequisites}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Level: {level}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Unit: {unit}</Typography>
              </Grid>
            </Grid>
            <Typography variant="h6" component="div" className="pt-8">
              Course Creators
            </Typography>
            <Grid container spacing={1} className="mt-2">
              <Grid item xs={6}>
                <Typography variant="body2">
                  Course Creator: {courseCreator?.profile?.name}{" "}
                  {courseCreator?.profile?.family}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Instructors: {instructorsArr}
                </Typography>
              </Grid>
            </Grid>
            {/* <Typography variant="h6" component="div" className="pt-8">
              Assigned Students
            </Typography>
             <Grid container spacing={2} className="mt-2">
              {StuAsFull.map((student) => (
                <Grid item xs={6}>
                  <div className="text-xs text-slate-500" variant="body2">
                    {student?.profile?.email}
                  </div>
                </Grid>
              ))} 
            </Grid> */}
            {/* <Typography variant="h6" component="div" className="pt-8">
              Assigned Lessons
            </Typography>
            <Grid container spacing={1} className="mt-2">
              {lsnAsFull.map((lesson) => (
                <Grid item xs={6}>
                  
                  <div variant="body2" className="text-xs text-slate-500">
                    {lesson?.info?.title}
                  </div>
                </Grid>
              ))}
            </Grid> */}
          </div>
          <div className="w-1/3">
            <img
              src={course?.course_image[0]?.url}
              alt={title}
              className="w-full h-64 object-contain"
            />
          </div>
        </div>
        {/* Group Four */}
        <Typography variant="h4" className="text-center">
          {course.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Explain: <ReactQuill value={explain} readOnly={true} theme="bubble" />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReviewCourse;
