import { Button, FormHelperText, TextField } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import FormatTextdirectionLToRIcon from "@mui/icons-material/FormatTextdirectionLToR";
import FormatTextdirectionRToLIcon from "@mui/icons-material/FormatTextdirectionRToL";

import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Import Quill's CSS
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

import { toast } from "react-toastify";
import "./styles.css";
import { v4 as uuidv4 } from "uuid";
import { addAttachment, sendImagesToDb } from "../../../firebase.config";
import "./styles.css";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
// const Font = Quill.import("formats/font");
// Font.whitelist = ["arial", "roboto", "courier", "times-new-roman"];
// Quill.register(Font, true);

// const icons = Quill.import("ui/icons");
// icons["pdf"] = "pdf";
// icons["audio"] = "audio";
// var Size = ReactQuill.Quill.import("attributors/style/size");
// Size.whitelist = ["12px", "16px", "18px"];
// ReactQuill.Quill.register(Size, true);
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { createRoot } from "react-dom/client"; // Import from react-dom/client
import { useSelector } from "react-redux";

const font = ReactQuill.Quill.import("attributors/style/font");
font.whitelist = ["Arial", "Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];
ReactQuill.Quill.register(font, true);
const safeSetSelection = (quill, range) => {
  if (!quill || !range) return;

  const editorLength = quill.getLength(); // Get total length of editor content

  // Ensure the range index is within bounds
  const safeIndex = Math.min(Math.max(range.index, 0), editorLength - 1);

  // Ensure the range length doesn't exceed bounds
  const safeLength = Math.min(range.length || 0, editorLength - safeIndex);

  try {
    quill.setSelection({ index: safeIndex, length: safeLength });
  } catch (error) {
    console.error("Failed to set selection:", error);
  }
};

const TextAreaComponent = ({ props }) => {
  // const [progressBarContent, setProgressBarContent] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [inputErr, setInputErr] = useState(false);
  const [savedRange, setSavedRange] = useState(null);
  const {
    explain,
    setTitle,
    title,
    setName,
    name,
    nameDirection,
    setNameDirection,
    titleDirection,
    setTitleDirection,
    setExplain,
  } = props;
  const quillRef = useRef(null);
  // const handleFileUpload = useCallback(
  //   async (file, type) => {
  //     try {
  //       const quill = quillRef.current?.getEditor();

  //       if (!quill) throw new Error("Quill editor not initialized");

  //       let range = quill.getSelection(); // Get the current selection
  //       if (!range) {
  //         range = { index: quill.getLength(), length: 0 }; // Default to the end of the content
  //       }
  //       if (!range) throw new Error("No selection in editor");

  //       // Save the selection range
  //       const savedRange = range;
  //       setSavedRange(savedRange);
  //       // Focus back on the editor after any asynchronous operation
  //       quill.focus();

  //       // Perform the upload (simulate with your actual implementation)
  //       const record = await addAttachment({
  //         tn: sectionName,
  //         recId,
  //         fieldName: attachmentFieldName,
  //         file,
  //         setRecId,
  //       });
  //       const recFields = record?.fields;
  //       const url =
  //         recFields?.explain_image[recFields?.explain_image?.length - 1]?.url;

  //       if (!url) throw new Error("File upload failed");
  //       quill.focus();

  //       // Restore the saved range
  //       safeSetSelection(savedRange);

  //       // Insert the embed (e.g., image)
  //       // quill.insertEmbed(savedRange.index, type, url);

  //       // Additional handling
  //       getRecordInfo(record.fields);
  //     } catch (error) {
  //       console.error(error.message);
  //       toast.error("Failed to upload file");
  //     }
  //   },
  //   [recId]
  // );

  // const handleImageUpload = useCallback(() => {
  //   const input = document.createElement("input");
  //   input.setAttribute("type", "file");
  //   input.setAttribute("accept", "image/*");
  //   input.click();
  //   input.onchange = () => handleFileUpload(input.files[0], "image");
  // }, [handleFileUpload]);

  const openImageModal = useCallback(() => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      const range = quill.getSelection(); // Get the current selection
      setSavedRange(range); // Save the selection
    }
    setOpenModal(true);
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ font: font.whitelist }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ direction: "rtl" }],
        // ["image"],
        ["custom-show-all"],
      ],
      handlers: {
        // image: handleImageUpload,
        "custom-show-all": openImageModal,
        // audio: handleAudioUpload,
        // pdf: handlePdfUpload,
      },
    },
  };

  //custom icons
  // useEffect(() => {
  //   // Add Material-UI icon to the custom toolbar button
  //   const customButton = document.querySelector(".ql-custom-show-all");
  //   if (customButton) {
  //     customButton.innerHTML = ""; // Clear existing content

  //     // Create a container for rendering the Material-UI icon
  //     const iconContainer = document.createElement("span");
  //     customButton.appendChild(iconContainer);

  //     // Render the Material-UI icon using React's createRoot API
  //     const root = createRoot(iconContainer);
  //     root.render(
  //       <ImageSearchIcon style={{ fontSize: "20px", cursor: "pointer" }} />
  //     );

  //     // Optional: Add an event listener to handle clicks
  //     customButton.addEventListener("click", () => {
  //       console.log("Image icon clicked!");
  //     });
  //   }
  // }, []);
  useEffect(() => {
    // Ensure the editor is initialized
    const quill = quillRef.current?.getEditor();
    if (!quill) {
      console.warn("Quill editor not initialized yet.");
      return;
    }

    // Add Material-UI icon to the custom toolbar button
    const customButton = document.querySelector(".ql-custom-show-all");
    if (customButton) {
      customButton.innerHTML = ""; // Clear existing content

      // Create a container for rendering the Material-UI icon
      const iconContainer = document.createElement("span");
      customButton.appendChild(iconContainer);

      // Render the Material-UI icon using React's createRoot API
      const root = createRoot(iconContainer);
      root.render(
        <ImageSearchIcon style={{ fontSize: "20px", cursor: "pointer" }} />
      );

      // Optional: Add an event listener to handle clicks
      customButton.addEventListener("click", () => {
        // Check if the editor has valid focus and selection before any operation
        const range = quill.getSelection();
        if (range && range.index <= quill.getLength()) {
          console.log("Image icon clicked with valid selection!", range);
        } else {
          console.warn("Invalid or missing selection, resetting range.");
          safeSetSelection(quill.getLength(), 0); // Default to the end of content
        }
      });
    }
  }, [quillRef]);

  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "list",
    "bullet",
    "align",
    "link",
    "image",
    "video",
    "audio",
    "pdf",
    "direction",
  ];

  const handleImageSelect = useCallback(
    (url) => {
      const quill = quillRef.current?.getEditor();
      if (!quill) return;

      try {
        quill.focus(); // Ensure the editor is focused
        if (savedRange) {
          const editorLength = quill.getLength();
          if (savedRange.index > editorLength) {
            console.warn(
              "Saved range is invalid, resetting to end of content."
            );
            setSavedRange({ index: editorLength, length: 0 });
          }
          safeSetSelection(quill, savedRange); // Restore the saved selection
        }
        quill.insertEmbed(savedRange?.index || 0, "image", url); // Insert the image
      } catch (error) {
        console.error("Error during image selection:", error);
      }

      setOpenModal(false); // Close the modal
    },
    [savedRange]
  );

  return (
    <div>
      {" "}
      <div className="flex w-full">
        <TextField
          className="w-full"
          sx={{ marginBottom: "10px" }}
          size="small"
          label="Code"
          value={title}
          dir={titleDirection}
          onChange={(e) => {
            setTitle(e.target.value);
            setInputErr(e.target.value.length < 4);
          }}
          error={inputErr}
          helperText={
            inputErr ? `Title must be at least 4 characters long` : ""
          }
        />
        <div>
          {titleDirection === "ltr" ? (
            <Button
              onClick={() => {
                setTitleDirection("rtl");
              }}
            >
              <FormatTextdirectionLToRIcon />
            </Button>
          ) : (
            <Button
              onClick={() => {
                setTitleDirection("ltr");
              }}
            >
              <FormatTextdirectionRToLIcon />
            </Button>
          )}
        </div>
      </div>
      <div className="flex w-full">
        <TextField
          className="w-full"
          sx={{ marginBottom: "10px" }}
          size="small"
          label="Name"
          value={name}
          dir={nameDirection}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <div>
          {nameDirection === "ltr" ? (
            <Button
              onClick={() => {
                setNameDirection("rtl");
              }}
            >
              <FormatTextdirectionLToRIcon />
            </Button>
          ) : (
            <Button
              onClick={() => {
                setNameDirection("ltr");
              }}
            >
              <FormatTextdirectionRToLIcon />
            </Button>
          )}
        </div>
      </div>
      <div>
        <ReactQuill
          theme="snow"
          className="h-96 mb-20"
          value={explain}
          onChange={setExplain}
          modules={modules}
          formats={formats}
          ref={quillRef}
        />
      </div>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Select an Image</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {/* handle resource select here */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TextAreaComponent;
