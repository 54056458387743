import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import LogoPdf from "../resourses/LogoPdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const ExistedResourceFile = ({ initResource, text, setEnEditRe }) => {
  return (
    <Grid item xs={12} className="flex justify-between border p-3">
      <div className="flex">
        <div className="w-28 flex items-center">
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className="w-24"
          >
            {text}
          </Typography>
          <PictureAsPdfIcon color="disabled" />
        </div>
        <div className="flex items-center justify-center ms-4">
          <LogoPdf attachments={initResource} size={80} />
        </div>
      </div>
      <Button
        onClick={() => {
          setEnEditRe(true);
        }}
      >
        Change file
      </Button>
    </Grid>
  );
};

export default ExistedResourceFile;
