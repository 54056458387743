import { Box, Button, LinearProgress, styled, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import React, { useRef, useState } from "react";
import axios from "axios";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const UploadFileComp = ({
  setFileSize,
  setTempFileUrl,
  tempFileUrl,
  acceptedType,
}) => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState(null);
  const inputRef = useRef();

  const onInputFile = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      uploadFile(selectedFile);
    }
  };
  const uploadFile = async (file) => {
    setUploadingFile(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("https://file.io/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const percent = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(Math.round(percent));
          }
        },
      });

      if (response.data.success) {
        if (setFileSize) {
          setFileSize(response.data.size);
        }
        setTempFileUrl(response.data.link);
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploadingFile(false);
    }
  };

  return (
    <div className="flex flex-col">
      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        size="small"
        sx={{ fontWeight: "900" }}
      >
        <CloudUploadIcon sx={{ fontSize: "40px" }} className="text-cu-blue" />
        <VisuallyHiddenInput
          type="file"
          onChange={onInputFile}
          accept={acceptedType}
          multiple={false}
          ref={inputRef}
        />
        <span className="ms-4 text-cu-blue">Upload File</span>
      </Button>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {uploadingFile ? (
          <Box sx={{ width: "100%", marginBottom: 1 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginTop: 1 }}
            >
              Uploading: {uploadProgress}%
            </Typography>
          </Box>
        ) : tempFileUrl ? (
          <Typography
            variant="body2"
            color={"green"}
            sx={{ marginTop: 1 }}
            className="capitaliz"
          >
            File {file.name} successfully uploaded!
            {/* <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                {fileUrl}
              </a> */}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginTop: 2 }}
          >
            No file selected
          </Typography>
        )}

        {/* {uploadingFile && (
            <CircularProgress
              size={24}
              sx={{ position: "absolute", marginLeft: 2 }}
            />
          )} */}
      </Box>
    </div>
  );
};

export default UploadFileComp;
