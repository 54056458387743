/**
 * Simplifies an Airtable-style attachment MIME type.
 * @param {string} mimeType - The MIME type (e.g., "application/pdf", "image/jpeg").
 * @returns {string} - The simplified type (e.g., "pdf" for "application/pdf", "image" for "image/jpeg").
 */
export function simplifyAttachmentType(mimeType = "") {
    if (typeof mimeType !== 'string') {
        throw new Error("Invalid MIME type: input must be a string");
    }

    const [type, subtype] = mimeType.split('/');
    if (!type || !subtype) {
        throw new Error("Invalid MIME type format");
    }

    // Return custom simplifications if necessary
    switch (type) {
        case 'application':
            if (subtype === 'pdf') return 'pdf';
            return 'application';
        case 'image':
            return 'image';
        case 'video':
            return 'video';
        case 'audio':
            return 'audio';
        case 'text':
            return 'text';
        default:
            return 'other';
    }
}

