import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import HeaderGuide from "./components/HeaderGuide";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`bg-white relative top-0 z-20 ${
        location.pathname === "/" ? "h-24" : "h-32"
      }`}
    >
      <div className="fixed top-0 w-full ">
        <Navbar />
        <div
          className={`${
            isScrolled ? "bg-slate-50 bg-opacity-50" : "bg-transparent"
          }`}
        >
          <div className="m-auto max-w-6xl">
            {/* <MiddleHeader /> */}
            <HeaderGuide />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
