import { createTheme } from "@mui/material";

export const tableTheme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: "60px", // Default row height
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "48px", // Default row height
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "4px",
          "&:first-of-type": {
            paddingLeft: "16px", // Add extra padding to the first cell
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: "40px",
          height: "40px",
          marginRight: "8px", // Equivalent to `marginRight: 2` in `sx`
          border: "1px solid rgba(0, 0, 0, 0.1)", // Light border
        },
      },
    },
  },
});
