import React, { useEffect, useState } from "react";
import { db } from "../../../firebase.config";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";

import ShowAllCourses from "../../shared/courses/ShowAllCourses";
import AddNewLesson from "../../shared/lessons/AddNewLesson";
import ShowAllLessons from "../../shared/lessons/ShowAllLessons";
import AddNewNotification from "../../shared/notifications/AddNewNotification";
import ReviewCourse from "../../shared/courses/ReviewCourse";
import ReviewLesson from "../../shared/lessons/ReviewLesson";
import { onValue, ref } from "firebase/database";

// import CourseAssign from "../assignCourseToStudent/CourseAssign";

import GradeToStudent from "../../shared/reportCards/GradeToStudent";
import Shahname from "../../../pages/resourses/Shahname";
import Parsiban from "../../../pages/resourses/Parsiban";
import Pubmed from "../../../pages/resourses/Pubmed";
import AvestaKeyboard from "../../../pages/resourses/AvestaKeyboard";
import TableShow from "../../shared/resourses/showFile/TableShow";
import ShowAllGroups from "../../shared/group/ShowAllGroups";
// import ShowAllProfiles from "../profiles/ShowAllProfiles";

import { toast } from "react-toastify";
import InstructorProfile from "../../instructor/InstructorProfile";
// import AddNewGroup from "../group/AddNewGroup";
import ShowResources from "../../shared/resourses/ShowResources";
import EditTable from "../../shared/resourses/tables/EditTable";
import ShowAllTables from "../../shared/resourses/tables/ShowAllTables";
import AddTable from "../../shared/resourses/tables/AddTable";
import ContentDrip from "../../shared/contentDrip/ContentDrip";
import Categories from "../../shared/category/Categories";
import ShowAllNotifications from "../../shared/notifications/ShowAllNotifications";
import { addNotifications } from "../../../redux/notificationsSlice";
import PanelLayout from "../../layout/PanelLayout";
import AddNewGroup from "../../shared/group/AddNewGroup";
import ShowAllProfiles from "../../admin/profiles/ShowAllProfiles";
import ReviewUser from "../../admin/members/ReviewUser";
import ShowAllUsers from "../../admin/members/ShowAllUsers";
import AddNewMember from "../../admin/members/AddNewMember";
import CourseAssign from "../../admin/assignCourseToStudent/CourseAssign";
import ReviewGroup from "../../shared/group/ReviewGroup";
import Library from "../../shared/Library/Library";
import ALLPublicInfo from "../../admin/publicInfo/ALLPublicInfo";
import AddNewCourse from "../../shared/courses/AddNewCourse";
import AddNewResource from "../../shared/resourses/AddNewResource";

const AdminPanel = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const docRef = ref(db, "/categories");
  //   return onValue(
  //     docRef,
  //     (snap) => {
  //       const categories = [];
  //       let index = 0;
  //       if (snap.val()) {
  //         snap.forEach((childsnap) => {
  //           index++;
  //           // if (childsnap.val().profile.groupCode === user.profile.groupCode) {
  //           categories.push({ id: index, ...childsnap.val() });
  //           // }
  //         });
  //         dispatch(addCategories(categories));
  //       }
  //     },
  //     (error) => {
  //       toast.error(error.message);
  //       console.log(error);
  //     }
  //   );
  // }, []);

  useEffect(() => {
    const docRef = ref(db, "/notification/manage");
    return onValue(
      docRef,
      (snap) => {
        if (snap.val()) {
          const notifications = [];
          snap.forEach((childsnap) => {
            // if (childsnap.val().profile.groupCode === user.profile.groupCode) {
            notifications.push({
              id: childsnap.key,
              ...childsnap.val(),
            });
            // }
          });
          console.log(notifications);
          notifications && dispatch(addNotifications(notifications));
        }
      },
      (error) => {
        toast.error(error.message);
        console.log(error);
      }
    );
  }, []);

  return (
    <Routes>
      <Route path="/" element={<PanelLayout />}>
        <Route path="publicinfo" element={<ALLPublicInfo />} />
        <Route path="members" element={<ShowAllUsers />} />
        <Route path="members/viewuser/:uid" element={<ReviewUser />} />
        <Route path="addnewmember/:uid/*" element={<AddNewMember />} />
        <Route path="editmember/:uid/*" element={<AddNewMember />} />
        {/* <Route path="editmember/:uid/*" element={<EditMember />} /> */}

        <Route path="profile" element={<InstructorProfile />} />

        <Route path="students/reportcards" element={<GradeToStudent />} />
        {/* <Route path="students/assignments" element={<CourseAssign />} /> */}

        <Route path="courses" element={<ShowAllCourses />} />
        <Route path="courses/viewcourse/:recordId" element={<ReviewCourse />} />
        <Route
          path="addnewcourse/:recordId?/:resourceId?/*"
          element={<AddNewCourse />}
        />
        <Route
          path="editcourse/:recordId/:resourceId?/*"
          element={<AddNewCourse />}
        />

        <Route path="contentdrip" element={<ContentDrip />} />

        <Route path="lessons" element={<ShowAllLessons />} />
        <Route path="lessons/viewlesson/:recordId" element={<ReviewLesson />} />
        <Route path="addnewlesson" element={<AddNewLesson />} />
        <Route
          path="editlesson/:recordId/:resourceId?/*"
          element={<AddNewLesson />}
        />

        <Route path="assigncoursetostudent" element={<CourseAssign />} />

        <Route path="categories" element={<Categories />} />

        <Route path="tables" element={<ShowAllTables />} />
        <Route path="tables/addnewtable/:tableId" element={<AddTable />} />
        <Route path="tables/edittable/:tableId" element={<EditTable />} />
        <Route path="tables/TableShow/:tableId" element={<TableShow />} />

        <Route path="groups" element={<ShowAllGroups />} />
        <Route path="addnewgroup" element={<AddNewGroup />} />
        <Route path="editgroup/:recordId" element={<AddNewGroup />} />
        <Route path="viewgroup/:recordId" element={<ReviewGroup />} />

        <Route path="resources" element={<ShowResources />} />
        <Route path="resources/addnewresource" element={<AddNewResource />} />
        <Route
          path="resources/editresource/:recordId?"
          element={<AddNewResource />}
        />

        <Route path="notifications" element={<ShowAllNotifications />} />
        <Route
          path="notifications/pushnewnotification"
          element={<AddNewNotification />}
        />
        <Route
          path="notifications/pusheditnotification/:notificationId"
          element={<AddNewNotification />}
        />
        <Route path="library" element={<Library />} />

        <Route path="tools/shahname" element={<Shahname />} />
        <Route path="tools/parsiban" element={<Parsiban />} />
        <Route path="tools/pubmed" element={<Pubmed />} />
        <Route path="tools/avestakb" element={<AvestaKeyboard />} />
        {/* <Route path='addcourse' element={<AddNewCourse />} /> */}

        <Route path="profiles" element={<ShowAllProfiles />} />
      </Route>
    </Routes>
  );
};

export default AdminPanel;
