import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation } from "swiper/modules";
const ImageShow = ({ images }) => {
  console.log(images);
  return (
    <div className="h-full flex flex-col">
      {images.length > 0 && (
        <div className="h-full flex justify-center items-center">
          <div className="w-96">
            <Swiper navigation={true} modules={[Navigation]}>
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  {/* <div className="flex flex-col items-center"> */}
                  <img
                    className="w-96"
                    src={image?.url}
                    alt={image?.filename}
                  />
                  {/* <p>{`${image?.filename} ${index}`}</p> */}
                  {/* </div> */}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}

      <div className="flex justify-center mt-8">
        {/* Add modal or other content here */}
      </div>
    </div>
  );
};

export default ImageShow;
