import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteRealItem, deleteSignleRecord } from "../../../firebase.config";
import { toast } from "react-toastify";
import { Avatar, TableCell, TableRow } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import { useSelector } from "react-redux";
import { getUserById } from "../../../redux/usersSlice";
import ActionsMenu from "../sharedComp/ActionsMenu";
import ActionsGroupMenu from "./ActionsGroupMenu";
import { removegroup } from "../../../redux/groupsSlice";
const GroupRow = ({ item }) => {
  const [deletingLoading, setDeletingLoading] = useState(false);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.profile.role);
  const navigate = useNavigate();
  const goToEditGroup = () => {
    // dispatch(getSelectedGroup({ item }));
    navigate(`/${role}panel/editgroup/${item.id}`);
  };

  const leadInstructor = useSelector((state) =>
    getUserById(state, item?.lead_instructor)
  );

  const deleteGroup = async () => {
    if (window.confirm(`are you sure to delete major ${item.e_title} ?`)) {
      setDeletingLoading(true);
      try {
        await deleteSignleRecord({ tn: "majors", recId: item.id });
        dispatch(removegroup(item.id));
        toast.success("major successfully deleted");
      } catch (error) {
        console.log(error);
        toast.success(error.message);
      } finally {
        setDeletingLoading(false);
      }
    }
  };

  const viewGroup = () => {
    navigate(`/${role}panel/viewgroup/${item.id}`);
  };

  return (
    <TableRow
      hover
      className="flex items-center border-b h-12 text-center relative"
    >
      {/* Actions */}

      {/* Color Avatar */}
      <TableCell>
        <Avatar className="text-stroke" sx={{ bgcolor: `${item.color}` }}>
          {item.code}
        </Avatar>
      </TableCell>

      {/* Name */}
      <TableCell>
        <p>{item.e_title}</p>
      </TableCell>

      {/* Persian Name */}
      <TableCell>
        <p>{item.p_title}</p>
      </TableCell>

      {/* Lead Instructor */}
      <TableCell>
        <div className="flex items-center">
          <Avatar
            // sx={{ width: "56px", height: "56px" }}
            src={leadInstructor?.profile?.photoUrl?.url}
            alt={leadInstructor?.profile?.name}
          />
          <p className="ml-2">
            {leadInstructor?.profile?.name} {leadInstructor?.profile?.family}
          </p>
        </div>
      </TableCell>
      <TableCell>
        {/* ActionsGroupMenu Component */}
        <ActionsGroupMenu
          onView={() => viewGroup(item)}
          onEdit={() => goToEditGroup(item)}
          onDelete={() => deleteGroup(item)}
          group={item}
          deletingLoading={deletingLoading}
        />
      </TableCell>
    </TableRow>
  );
};

export default GroupRow;
