import React, { useState, useEffect, useRef } from "react";
import { Button, Slider, Typography } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
const VideoShow = ({ url }) => {
  const [fullscreen, setFullScreen] = useState(false);
  const playerRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [play, setPlay] = useState(true);
  function getYouTubeVideoId(url) {
    const regex = /youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  const ytVideoId = getYouTubeVideoId(url);

  useEffect(() => {
    if (!url?.includes("youtube")) return;
    const onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player("player", {
        height: "390",
        width: "640",
        videoId: ytVideoId,
        playlist: ytVideoId,
        playerVars: {
          playsinline: 1,
          autoplay: 0,
          mute: 0,
          showinfo: 0,
          loop: 0,
          controls: 0, // Show basic controls
          color: "white",
          modestbranding: 1,
          rel: 0,
          enablejsapi: 1,
          disablekb: 0,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    };

    if (!window.YT) {
      // Load the YouTube IFrame API script
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    } else {
      onYouTubeIframeAPIReady();
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [ytVideoId]);

  useEffect(() => {
    if (!url?.includes("youtube") || !duration) return;
    const interval = setInterval(() => {
      if (playerRef.current && !isPaused) {
        const time = playerRef.current.getCurrentTime();
        setCurrentTime(time);
      }
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, [isPaused]);

  const onPlayerReady = (event) => {
    // Player is ready
    const videoDuration = playerRef.current.getDuration();
    setDuration(videoDuration);
  };

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PAUSED) {
      setIsPaused(true);
    } else {
      setIsPaused(false);
    }
  };

  const playVideo = (e) => {
    e.stopPropagation();
    setPlay(true);
    playerRef.current.playVideo();
  };

  const pauseVideo = () => {
    setPlay(false);
    playerRef.current.pauseVideo();
  };

  const seekTo = (seconds) => {
    playerRef.current.seekTo(seconds, true);
  };

  const handleSliderChange = (event, newValue) => {
    event.stopPropagation();
    seekTo(newValue);
    setCurrentTime(newValue);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours !== 0 ? hours + ":" : ""}${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      {url?.includes("youtube") ? (
        <div
          onClick={play ? pauseVideo : playVideo}
          onDoubleClick={() => setFullScreen(!fullscreen)}
          style={{
            position: "relative",
            width: fullscreen ? "100%" : "640px",
            height: fullscreen ? "100%" : "390px",
          }}
          className="unselectable z-30"
        >
          <div
            id="player"
            className="yt-embed-holder w-full h-full"
            ref={playerRef}
          ></div>
          {isPaused && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button variant="contain">
                <PlayCircleFilledIcon sx={{ fontSize: "40px" }} />
              </Button>
              {/* Video Paused */}
            </div>
          )}
          <div className="group relative">
            <div
              className="w-full px-4 bg-white absolute bottom-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Your existing content */}
              <div className="flex justify-between items-center">
                <div className="flex gap-x-2 items-center">
                  {!play ? (
                    <button onClick={playVideo}>
                      <PlayArrowIcon />
                    </button>
                  ) : (
                    <button onClick={pauseVideo}>
                      <PauseIcon />
                    </button>
                  )}
                  <Typography variant="body1">
                    {formatTime(currentTime)} / {formatTime(duration)}
                  </Typography>
                </div>
                <button onClick={() => setFullScreen((prev) => !prev)}>
                  <FullscreenIcon />
                </button>
              </div>
              <Slider
                value={currentTime}
                min={0}
                max={duration}
                onChange={handleSliderChange}
                aria-labelledby="continuous-slider"
              />
            </div>
          </div>
        </div>
      ) : (
        <video
          width="1080"
          height="720"
          controls
          src={url}
          controlsList="nodownload"
        >
          Your browser does not support the video tag.
        </video>
      )}

      {/* {!fullscreen && videoData?.videoId && (
        <ShowModalGroupButton
          itemId={videoData?.videoId}
          type={"video"}
          itemCreatorId={videoData?.creatorId}
          groupCode={videoData?.groupCode}
        />
      )} */}
    </div>
  );
};

export default VideoShow;

// import React, { useState, useEffect, useRef } from "react";
// import { useLocation } from "react-router-dom";
// import ShowModalGroupButton from "../../sharedComp/ShowModalGroupButton";

// const VideoShow = ({ videoData }) => {
//   const location = useLocation();
//   const [open, setOpen] = useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const playerRef = useRef(null);

//   function getYouTubeVideoId(url) {
//     const regex = /youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;
//     const match = url.match(regex);
//     return match ? match[1] : null;
//   }

//   const ytVideoId = getYouTubeVideoId(videoData?.file?.url?.video);
//   // src={`${videoData?.file?.url?.video}&autoplay=1&mute=1&showinfo=0&loop=1&controls=0&color=white&modestbranding=0&rel=0&playsinline=1&enablejsapi=1&playlist=${ytVideoId}`}

//   useEffect(() => {
//     const onYouTubeIframeAPIReady = () => {
//       playerRef.current = new window.YT.Player("player", {
//         height: "390",
//         width: "640",
//         videoId: ytVideoId,
//         playerVars: {
//           modestbranding: 1,
//           rel: 0,
//           controls: 0,
//           disablekb: 1,
//           Loop: 1,
//           playsinline: 1,
//           mute: 1,
//           enablejsapi: 1,
//           playlist: ytVideoId,
//         },
//         events: {
//           onReady: onPlayerReady,
//           onStateChange: onPlayerStateChange,
//         },
//       });
//     };

//     if (!window.YT) {
//       // Load the YouTube IFrame API script
//       const tag = document.createElement("script");
//       tag.src = "https://www.youtube.com/iframe_api";
//       const firstScriptTag = document.getElementsByTagName("script")[0];
//       firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
//       window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
//     } else {
//       onYouTubeIframeAPIReady();
//     }

//     return () => {
//       if (playerRef.current) {
//         playerRef.current.destroy();
//       }
//     };
//   }, [ytVideoId]);

//   const onPlayerReady = (event) => {
//     // Player is ready
//   };

//   const onPlayerStateChange = (event) => {
//     // Handle player state changes
//   };

//   const playVideo = () => {
//     playerRef.current.playVideo();
//   };

//   const pauseVideo = () => {
//     playerRef.current.pauseVideo();
//   };

//   const seekTo = (seconds) => {
//     playerRef.current.seekTo(seconds, true);
//   };

//   return (
//     <div className="flex flex-col items-center justify-center w-full h-full">
//       {videoData?.file?.url?.video.includes("youtube") ? (
//         <div style={{ position: "relative", width: "100%", height: "100%" }}>
//           <div className="yt-embed-holder " id="player"></div>
//           <div
//             style={{
//               position: "absolute",
//               top: 0,
//               left: 0,
//               width: "100%",
//               height: "100%",
//               backgroundColor: "transparent",
//               pointerEvents: "none",
//             }}
//           ></div>
//         </div>
//       ) : (
//         <video
//           width="1080"
//           height="720"
//           controls
//           src={videoData?.file?.url?.video}
//           controlsList="nodownload"
//         >
//           Your browser does not support the video tag.
//         </video>
//       )}

//       <button onClick={playVideo}>Play</button>
//       <button onClick={pauseVideo}>Pause</button>
//       <input type="number" id="seekTime" placeholder="Seconds" />
//       <button onClick={() => seekTo(document.getElementById("seekTime").value)}>
//         Seek
//       </button>

//       <ShowModalGroupButton
//         itemId={videoData.info.videoId}
//         type={"video"}
//         itemCreatorId={videoData.info.creatorId}
//         groupCode={videoData.info.groupCode}
//       />
//     </div>
//   );
// };

// export default VideoShow;
