import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";

import resourcesReducer from "./resourcesSlice";
import coursesReducer from "./coursesSlice";
import usersReducer from "./usersSlice";
import lessonsReducer from "./lessonsSlice";
import tablesReducer from "./tablesSlice";
import groupsReducer from "./groupsSlice";
import notificationsReducer from "./notificationsSlice";
import publicInfoReducer from "./publicInfoSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    resources: resourcesReducer,
    users: usersReducer,
    courses: coursesReducer,
    lessons: lessonsReducer,
    tables: tablesReducer,
    notifications: notificationsReducer,
    groups: groupsReducer,
    publicInfo: publicInfoReducer,
  },
  //   middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware({
  //       serializableCheck: false,
  //     }),
});

export default store;
