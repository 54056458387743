import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { readTable } from "../../firebase.config";
import { addgroups, getAllGroups } from "../../redux/groupsSlice";

export const useLoadMajors = (trigger) => {
  const dispatch = useDispatch();
  const groups = useSelector(getAllGroups);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (groups && groups.length) return;
    if (!trigger) return;
    const readMajorTable = async () => {
      setLoading(true);
      try {
        const majors = await readTable({ tn: "majors" });
        dispatch(addgroups(majors));
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    readMajorTable();
  }, [groups, dispatch, trigger]);
  return loading;
};
