// import React, { useState, useRef, useEffect } from "react";
// import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useNavigate } from "react-router-dom";
// import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
// import { toast } from "react-toastify";
// import {
//   deleteRealItem,
//   deleteStorageRecursively,
// } from "../../../firebase.config";
// import PreviewIcon from "@mui/icons-material/Preview";
// import { getUsersByIds } from "../../../redux/usersSlice";
// import { useSelector } from "react-redux";
// import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
// import { Avatar, Button, Typography } from "@mui/material";
// import TimeShow from "../../../shared/utils/TimeShow";
// import { getGroupById } from "../../../redux/groupsSlice";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import EditIcon from "@mui/icons-material/Edit";
// const CourseRow = ({ item }) => {
//   const user = useSelector((state) => state.user);
//   const courseId = ite.courseId;
//   const groupCode = ite.groupCode;
//   const navigate = useNavigate();
//   const [menuOpen, setMenuOpen] = useState(false);
//   const menuRef = useRef(null);

//   const goToEditCourse = () => {
//     navigate(`/${user.profile.role}panel/editcourse/${courseId}`);
//   };

//   const group = useSelector((state) => getGroupById(state, groupCode));
//   const assignedInstructorsId = item.assignedInstructor
//     ? Object.keys(item.assignedInstructor)
//     : [];
//   const instructorsUnfiltered = useSelector((state) =>
//     getUsersByIds(state, assignedInstructorsId)
//   );
//   const instructors = instructorsUnfiltered.filter((ins) => ins !== undefined);

//   const deleteCourse = async () => {
//     if (!courseId || !groupCode) {
//       toast.error("can not find course ID");
//       return;
//     }
//     if (window.confirm("are you sure to remove course?")) {
//       const realCoursePath = `/lact/${groupCode}/courses/${courseId}`;
//       const storageCoursePath = `/courses/${courseId}`;
//       const usersId = item.assignedStudents
//         ? Object.keys(item.assignedStudents)
//         : [];
//       const usersCourseDeletePromise = usersId?.map((uid) => {
//         if (!uid || !groupCode) {
//           toast.error(`uid isnt exists, an error take place please check db`);
//           throw new Error("id not found");
//         } else {
//           const userPath = `/lact/${groupCode}/users/${uid}/assignedCourses/${courseId}`;
//           return deleteRealItem(userPath);
//         }
//       });
//       const lessonsId = item.assignedLessons
//         ? Object.keys(item.assignedLessons)
//         : [];
//       const lessonsCourseDeletePromise = lessonsId?.map((li) => {
//         if (!li) {
//           toast.error(
//             `lesson ID isnt exists, an error take place please check db`
//           );
//           throw new Error("id not found");
//         } else {
//           const lessonPath = `lact/${groupCode}/lessons/${li}/assignedCourses/${courseId}`;
//           return deleteRealItem(lessonPath);
//         }
//       });
//       try {
//         await Promise.all(lessonsCourseDeletePromise);
//         await Promise.all(usersCourseDeletePromise);
//         await deleteRealItem(realCoursePath);
//         await deleteStorageRecursively(storageCoursePath);
//         toast.success("successfully delete course");
//       } catch (error) {
//         toast.error(error.message);
//         console.log(error);
//       }
//     }
//   };

//   const viewCourse = () => {
//     navigate(
//       `/${user.profile.role}panel/courses/viewcourse/${ite.courseId}`
//     );
//   };

//   const openMenu = () => {
//     setMenuOpen(true);
//   };

//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         closeMenu();
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <tr className="border-b h-12 flex items-center relative text-center">
//       <div className="absolute right-0 top-0 text-blue-600 h-full me-1 flex items-center cursor-pointer">
//         <Button onClick={openMenu}>
//           <MoreHorizIcon className="text-cu-blue" />
//         </Button>
//         {menuOpen && (
//           <div
//             ref={menuRef}
//             className="absolute top-full right-0 bg-white shadow-lg p-1 z-40 w-32 flex flex-col gap-y-2 items-start rounded-xl border"
//           >
//             {/* <div className=""> */}
//             <Button
//               onClick={viewCourse}
//               sx={{
//                 fontSize: "10px",
//                 display: "flex",
//                 justifyContent: "start",
//               }}
//               className="gap-x-2 w-full "
//             >
//               <PreviewIcon className="text-blue-500" sx={{ width: "20px" }} />{" "}
//               <span>Review</span>
//             </Button>
//             {/* </div> */}
//             {/* <div className="border-b-2 border-b-cu-red w-full flex items-start"> */}
//             <Button
//               onClick={goToEditCourse}
//               sx={{
//                 fontSize: "10px",
//                 display: "flex",
//                 justifyContent: "start",
//               }}
//               className="w-full gap-x-2"
//             >
//               <EditIcon sx={{ width: "20px" }} />
//               <span>Edit</span>
//             </Button>
//             {/* </div> */}
//             <Button
//               onClick={deleteCourse}
//               sx={{
//                 fontSize: "10px",
//                 display: "flex",
//                 justifyContent: "start",
//                 color: "#bf0a30",
//               }}
//               className=" w-full gap-x-2"
//             >
//               <DeleteForeverOutlinedIcon sx={{ width: "20px" }} /> Delete
//             </Button>
//           </div>
//         )}
//       </div>
//       <td className="w-1/12">
//         <Avatar
//           className="rounded-lg ms-3 h-full"
//           sx={{ objectFit: "contain" }}
//           src={item?.files?.courseImage?.url}
//           alt={item?.title}
//           variant="rounded"
//         >
//           {!item?.files?.courseImage?.url && (
//             <InsertPhotoIcon sx={{ fontSize: "40px" }} />
//           )}
//         </Avatar>
//       </td>
//       <td className="w-1/6">
//         <p>{ite?.title}</p>
//       </td>
//       <td className="w-1/6">
//         <p>{ite?.name || "---"}</p>
//       </td>
//       <td className="flex flex-col gap-y-2 w-1/6">
//         {item?.categoriesName?.length
//           ? item?.categoriesName?.map((catName) => (
//               <span key={catName}>{catName}</span>
//             ))
//           : "---"}
//       </td>
//       <td className="flex flex-col gap-y-2 w-1/6">
//         {instructors.length &&
//           instructors?.map(
//             (ins) => ins?.profile?.name + " " + ins?.profile?.family
//           )}
//       </td>
//       <td className="w-1/12">
//         {item?.createAt && (
//           <TimeShow
//             onlyDate={true}
//             date={new Date(item?.createAt)}
//           />
//         )}
//       </td>
//       <td className="w-1/12">
//         <Avatar
//           className="rounded-full border m-auto"
//           sx={{ bgcolor: `${group.color}`, width: "15px", height: "15px" }}
//         >
//           {/* {group.id} */}
//         </Avatar>
//       </td>
//     </tr>
//   );
// };

// export default CourseRow;

import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteRealItem,
  deleteSignleRecord,
  deleteStorageRecursively,
} from "../../../firebase.config";
import { getUserById, getUsersByIds } from "../../../redux/usersSlice";
import { useSelector } from "react-redux";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Avatar, TableCell, TableRow, Typography } from "@mui/material";
import TimeShow from "../../../shared/utils/TimeShow";
import { getGroupById } from "../../../redux/groupsSlice";
import ActionsMenu from "../sharedComp/ActionsMenu";
import ActionsCourseMenu from "./ActionsCourseMenu";
import ViewFile from "../../../layout/ViewFile";
import ImageShow from "../resourses/showFile/ImageShow";
import { formatDate } from "../../../shared/utils/formatTime";
import { useDispatch } from "react-redux";
import { removeCourse } from "../../../redux/coursesSlice";

const CourseRow = ({ item }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const recId = item.id;
  const navigate = useNavigate();
  const [imageOpen, setImageOpen] = useState(false);
  const assignedInstructorsId = item.assigned_instructor;
  const instructors = useSelector((state) =>
    getUserById(state, assignedInstructorsId)
  );
  const closeModal = () => {
    setImageOpen(false);
  };
  const goToEditCourse = useCallback(() => {
    navigate(`/${user.profile.role}panel/editcourse/${recId}`);
  }, []);

  const deleteCourse = useCallback(async () => {
    if (
      window.confirm(
        `are you willing to proceed removing course ${item?.title} ${item?.name} ?`
      ) === false
    ) {
      return;
    }
    try {
      const removedId = await deleteSignleRecord({ tn: "courses2", recId });
      toast.success(`record ${removedId} successfully deleted`);
      dispatch(removeCourse(item.id));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  }, []);

  const viewCourse = useCallback(() => {
    navigate(`/${user.profile.role}panel/courses/viewcourse/${item.id}`);
  }, [navigate, user.profile.role, item.id]);

  return (
    <TableRow hover>
      <TableCell className="h-full">
        <Avatar
          className="h-full cursor-pointer"
          src={
            item?.course_image && item?.course_image[0]?.thumbnails?.small?.url
          }
          alt={item?.title}
          variant="rounded"
          sx={{
            width: 72,
            objectFit: "contain",
          }}
          onClick={() => {
            setImageOpen(true);
          }}
        >
          {!item?.course_image && <InsertPhotoIcon fontSize="large" />}
        </Avatar>
        <ViewFile
          isOpen={imageOpen}
          onClose={closeModal}
          title={(item?.course_image && item?.course_image[0].filename) || ""}
        >
          <ImageShow images={item?.course_image} />
        </ViewFile>
      </TableCell>
      <TableCell>{item?.title}</TableCell>
      <TableCell>{item?.name || "---"}</TableCell>
      <TableCell>
        {item?.categories?.length
          ? item?.categories.map((catName) => (
              <Typography key={catName}>{catName}</Typography>
            ))
          : "---"}
      </TableCell>
      <TableCell>
        {`${instructors?.profile?.name} ${instructors?.profile?.family}` ||
          "---"}
      </TableCell>
      <TableCell>{formatDate(item.create_at)}</TableCell>
      <TableCell>
        <Avatar
          sx={{
            bgcolor: item?.group?.color,
            width: 15,
            height: 15,
            mx: "auto",
          }}
        />
      </TableCell>
      <TableCell>
        <ActionsCourseMenu
          onView={viewCourse}
          onEdit={goToEditCourse}
          onDelete={deleteCourse}
          course={item}
        />
      </TableCell>
    </TableRow>
  );
};

export default CourseRow;
