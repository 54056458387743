import React, { useCallback, useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { db, readTable } from "../../../firebase.config";
import { get, ref, update } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  addCourses,
  getAllCourses,
  getCoursesByIds,
} from "../../../redux/coursesSlice";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  Paper,
} from "@mui/material";
import { useLoadCourses } from "../../../shared/customHooks/useLoadCourses";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";
import { tableTheme } from "../sharedComp/tableTheme";
import { ThemeProvider } from "@emotion/react";
import SearchBox from "../sharedComp/SearchBox";
import LessonRow from "./LessonRow";
import { getAllLessons } from "../../../redux/lessonsSlice";
import { useLoadLessons } from "../../../shared/customHooks/useLoadLessons";

const ShowAllLessons = () => {
  const loading = useLoadLessons(true);
  const courseLoading = useLoadCourses(true);
  const user = useSelector((state) => state.user);
  const lessons = useSelector(getAllLessons);
  const [searchText, setSearchText] = useState("");
  const [filteredLessons, setFilteredLessons] = useState(lessons);
  const navigate = useNavigate();

  const addNewLesson = async () => {
    navigate(`/${user.profile.role}panel/addnewlesson`);
  };

  useEffect(() => {
    setFilteredLessons(
      lessons.filter(
        (lesson) =>
          lesson?.title?.toLocaleLowerCase()?.includes(searchText) ||
          lesson?.name?.toLocaleLowerCase()?.includes(searchText)
      )
    );
  }, [lessons, searchText]);

  return (
    <div className="w-7xl mx-auto mt-10 flex flex-col px-10">
      <LoadingOverlay loading={loading || courseLoading} />

      <div className="flex justify-between">
        <div className="mb-5 flex items-center">
          <span className="text-lg font-semibold">Lessons:</span>
          <IconButton onClick={addNewLesson} className="ms-3">
            <AddCircleIcon className="text-blue-500" />
          </IconButton>
        </div>
        <div className="w-60">
          <SearchBox setSearchText={setSearchText} />
        </div>
      </div>

      {/* Courses Table */}
      <ThemeProvider theme={tableTheme}>
        <TableContainer component={Paper} className="shadow-md">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Code</strong>
                </TableCell>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Row Id</strong>
                </TableCell>
                <TableCell>
                  <strong>Assigned Courses</strong>
                </TableCell>
                <TableCell>
                  <strong>Creation Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Group</strong>
                </TableCell>
                <TableCell>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLessons?.map((item) => (
                <LessonRow key={`itemname${item.id}`} item={item} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  );
};

export default ShowAllLessons;
