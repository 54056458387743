import { Button, Slider } from "@mui/material";
import React from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ResourcesHeader = ({ size, setSize }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const handleSizeChange = (event, newValue) => {
    setSize(newValue);
  };

  return (
    <div className="flex justify-between mb-5">
      <Button
        onClick={() =>
          navigate(`/${user.profile.role}panel/resources/addnewresource`)
        }
      >
        Add New <AddCircleIcon />
      </Button>

      <Slider
        value={size}
        onChange={handleSizeChange}
        aria-labelledby="image-size-slider"
        min={100}
        max={300}
        valueLabelDisplay="auto"
        sx={{ width: 300 }} // Set the width to 300px
      />
    </div>
  );
};

export default ResourcesHeader;
