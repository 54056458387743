export function areArraystEqual(array1, array2) {
  // Check if both arrays are of the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Compare each element in the arrays
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  // If all elements match, the arrays are equal
  return true;
}