import React, { useCallback, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  IconButton,
  CardContent,
  Card,
  styled,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddResourceFile from "./AddResourceFile";
import AddResourceLink from "./AddResourceLink";
import axios from "axios";
import ExistedResourceFile from "./ExistedResourceFile";

const AddLessonResources = ({
  formData,
  setFormData,
  initAssignment,
  initResource1,
  initResource3,
}) => {
  const [enEditRe1, setEnEditRe1] = useState(false);
  const [enEditAs, setEnEditAs] = useState(false);
  const [enEditRe3, setEnEditRe3] = useState(false);
  const handleInputChange = useCallback(
    (link) => {
      setFormData({ ...formData, videoLink: link });
    },
    [formData, setFormData]
  );

  return (
    <Box>
      <Grid container spacing={2}>
        {/* Book 1 */}
        {initResource1 && !enEditRe1 ? (
          <ExistedResourceFile
            initResource={initResource1}
            setEnEditRe={setEnEditRe1}
            text={"book1"}
          />
        ) : (
          <AddResourceFile
            // clearSelectedFile={clearSelectedFile}
            // handleFileChange={handleFileChange}
            enEditRe={enEditRe1}
            setEnEditRe={setEnEditRe1}
            initResource={initResource1}
            formData={formData}
            setFormData={setFormData}
            text={"book1"}
          />
        )}
        {initResource3 && !enEditRe3 ? (
          <ExistedResourceFile
            initResource={initResource3}
            setEnEditRe={setEnEditRe3}
            text={"book2"}
          />
        ) : (
          <AddResourceFile
            // clearSelectedFile={clearSelectedFile}
            // handleFileChange={handleFileChange}
            enEditRe={enEditRe3}
            setEnEditRe={setEnEditRe3}
            initResource={initResource3}
            formData={formData}
            setFormData={setFormData}
            text={"book2"}
          />
        )}
        <AddResourceLink
          handleInputChange={handleInputChange}
          formData={formData}
          text={"videoLink"}
        />
        {initAssignment && !enEditAs ? (
          <ExistedResourceFile
            initResource={initAssignment}
            setEnEditRe={setEnEditAs}
            text={"assignment"}
          />
        ) : (
          <AddResourceFile
            // clearSelectedFile={clearSelectedFile}
            // handleFileChange={handleFileChange}
            enEditRe={enEditAs}
            setEnEditRe={setEnEditAs}
            initResource={initAssignment}
            formData={formData}
            setFormData={setFormData}
            text={"assignment"}
          />
        )}
      </Grid>
    </Box>
  );
};

export default AddLessonResources;
