import React from "react";
import {
  Select,
  MenuItem,
  Button,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useLoadCourses } from "../../../shared/customHooks/useLoadCourses";
import { useLoadMajors } from "../../../shared/customHooks/useLoadMajors";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";
const ReportCardSelect = ({ users, changeUser }) => {
  const crsloading = useLoadCourses(true);
  const grpLoading = useLoadMajors(true);
  const modifiedUser = users.filter((user) => user.profile.role === "student");
  return (
    <>
      <LoadingOverlay loading={crsloading && grpLoading} />

      <div className="w-8/12 m-auto">
        <FormControl fullWidth>
          <InputLabel id="user-select-label">Select User</InputLabel>
          <Select
            labelId="user-select-label"
            onChange={changeUser}
            label="Select User"
            className="mb-10"
          >
            {modifiedUser?.map((user, index) => (
              <MenuItem key={index} value={user.profile?.uid}>
                {user.profile?.groupCode && user.profile?.groupCode}
                ---
                {user.profile?.email} ---{" "}
                {user.profile?.studentNumber && user.profile?.studentNumber}{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default ReportCardSelect;
