import React, { useMemo } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getCoursesByIds } from "../../redux/coursesSlice";
import { useSelector } from "react-redux";
import CourseRowSt from "./CourseRowSt";
import { useLoadCourse } from "../../shared/customHooks/useLoadCourse";
import { useLoadMultipleCourse } from "../../shared/customHooks/useLoadmultipleCourse";
import LoadingOverlay from "../../shared/comp/LoadingOverlay";

const ShowCourses = () => {
  //   const { courseId } = useParams();
  const user = useSelector((state) => state.user);
  const courseIds = useMemo(() => {
    return user.assignedCourses
      ? Object.values(user.assignedCourses).map((ascrs) => ascrs.id)
      : [];
  }, []);
  console.log(courseIds);
  const loading = useLoadMultipleCourse(true, courseIds);
  // const course = useSelector((state) => getCourseById(state, courseId));
  const courses = useSelector((state) => getCoursesByIds(state, courseIds));

  return (
    <Container>
      <LoadingOverlay loading={loading} />
      <TableContainer component={Paper} className="mt-8">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Instructor</TableCell>
              {/* <TableCell>Categories</TableCell> */}
              {/* <TableCell>Publish Date</TableCell> */}
              <TableCell>Duration</TableCell>
              {/* <TableCell>Prerequisites</TableCell> */}
              <TableCell>Level</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Grade</TableCell>
              <TableCell>View Course</TableCell>
              <TableCell>Course Content</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses?.map((course, index) => (
              <CourseRowSt course={course} key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ShowCourses;
