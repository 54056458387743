import {
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ImageListItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useState } from "react";
import LogoPdf from "./LogoPdf";
import LogoVideo from "./LogoVideo";
import LogoImage from "./LogoImage";
import LogoBroken from "./LogoBroken";
import { useNavigate } from "react-router-dom";
import { userAuthChange } from "../../../firebase.config";
import { useSelector } from "react-redux";
import ResourceMenuAction from "./ResourceMenuAction";

const EachResource = ({
  recordId = "",
  file = "",
  imageUrl = "",
  fileUrl = "",
  name = "",
  type,
  size,
}) => {
  return (
    <Grid
      item
      // spacing={14}
      className="group relative transition-all hover:shadow-lg hover:scale-105"
    >
      {/* Three-dot menu */}
      <ResourceMenuAction recordId={recordId} />

      {/* Conditional rendering for content */}
      {type === "pdf" ? (
        <LogoPdf
          name={name}
          attachments={file}
          size={size}
          recordId={recordId}
        />
      ) : type === "video" ? (
        <LogoVideo
          recordId={recordId}
          videoUrl={fileUrl}
          name={name}
          imageSrc={imageUrl}
          size={size}
        />
      ) : type === "image" ? (
        <LogoImage
          name={name}
          attachments={file}
          size={size}
          recordId={recordId}
        />
      ) : (
        <LogoBroken />
      )}
    </Grid>
    // <Grid
    //   item
    //   spacing={14}
    //   className="group transition-all hover:shadow-lg hover:scale-105"
    // >
    //   {type === "pdf" ? (
    //     <LogoPdf
    //       name={name}
    //       attachments={file}
    //       size={size}
    //       recordId={recordId}
    //     />
    //   ) : type === "video" ? (
    //     <LogoVideo
    //       recordId={recordId}
    //       videoUrl={fileUrl}
    //       name={name}
    //       imageSrc={imageUrl}
    //       size={size}
    //     />
    //   ) : type === "image" ? (
    //     <LogoImage
    //       name={name}
    //       attachments={file}
    //       size={size}
    //       recordId={recordId}
    //     />
    //   ) : (
    //     <LogoBroken />
    //   )}
    // </Grid>
  );
};

export default EachResource;

{
  /* <Box
  sx={{
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    backgroundColor: "#fff",
  }}
  className="group-hover:opacity-90"
>
</Box>
<Typography
  sx={{
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: "4px",
    color: "white",
    backgroundColor: "rgba(0,0,0,0.6)",
    fontSize: "0.8rem",
    textAlign: "center",
  }}
  className="opacity-0 group-hover:opacity-100 transition-opacity"
>
  Image
</Typography> */
}
