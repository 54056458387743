import React, { useState } from "react";
import CategoryAdd from "./CategoryAdd";
import Category from "./Category";
import { db } from "../../../firebase.config";
import { ref, set } from "firebase/database";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// import { getAllCategories } from "../../../redux/categoriesSlice";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Typography,
  Paper,
} from "@mui/material";

const Categories = () => {
  const creatorId = useSelector((state) => state.user.profile.uid);
  const [categoryInput, setCategoryInput] = useState("");
  const [selectedPathCategory, setSelectedPathCategory] = useState("/");
  // const categories = useSelector(getAllCategories);

  const addNewCategory = async () => {
    if (!categoryInput.trim()) {
      toast.error("Category cannot be empty");
      return;
    }
    const docRef = ref(
      db,
      `/categories${selectedPathCategory === "/" ? "" : selectedPathCategory}/${
        selectedPathCategory === "/" ? "" : "subCategories/"
      }${categoryInput}`
    );
    await set(docRef, {
      name: categoryInput,
      path: `${selectedPathCategory}${
        selectedPathCategory === "/" ? "" : "/subCategories/"
      }${categoryInput}`,
      subCategories: {},
      creatorId,
    });
  };

  const flattenCategories = (categories) => {
    let result = [];
    categories.forEach((category) => {
      result.push(category);
      if (
        category.subCategories &&
        Object.values(category.subCategories).length > 0
      ) {
        result = result.concat(
          flattenCategories(Object.values(category.subCategories))
        );
      }
    });
    return result;
  };

  // const flattenedData = flattenCategories(categories);

  return (
    <Paper className="border p-5 m-5">
      <Box className="flex justify-between">
        <Typography variant="h6" className="border-b">
          Categories:
        </Typography>
      </Box>
      <Box className="h-80 w-full overflow-auto mb-5 px-1">
        {/* {flattenedData?.map((category, index) => (
          <Category key={index} index={index} category={category} />
        ))} */}
      </Box>
      <Box className="flex flex-col gap-y-3">
        <TextField
          variant="outlined"
          label="Category Name"
          value={categoryInput}
          onChange={(e) => setCategoryInput(e.target.value)}
          className="border ps-2 py-1"
        />
        <FormControl variant="outlined" className="py-1">
          <InputLabel id="parent_Category">Parent Category</InputLabel>
          <Select
            labelId="parent_Category"
            // className="border rounded-lg p-3"
            value={selectedPathCategory}
            onChange={(e) => setSelectedPathCategory(e.target.value)}
            label="Parent Category"
          >
            <MenuItem value="/">-- Root Level --</MenuItem>
            {/* {flattenedData?.map((category, index) => {
              const len = category.path.split("/").length;
              return (
                <MenuItem value={category.path}>
                  {"-".repeat(len - 2)}
                  {category.name}
                </MenuItem>
              );
            })} */}
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          color="primary"
          onClick={addNewCategory}
          className="text-blue-500 border border-blue-500"
        >
          Add New Category
        </Button>
      </Box>
    </Paper>
  );
};

export default Categories;
