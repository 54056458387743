import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  changeCourseActive,
  updateSignleRecord,
} from "../../../firebase.config";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { updateCourse } from "../../../redux/coursesSlice";
import { useDispatch } from "react-redux";

const ActionsCourseMenu = ({ onView, onEdit, onDelete, course }) => {
  const [activeLoading, setActiveLoading] = useState(false);
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const user = useSelector((state) => state.user);
  const [active, setActive] = useState(course?.info?.active ?? false);
  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeActive = async () => {
    setActiveLoading(true);
    try {
      await updateSignleRecord({
        tn: "courses2",
        recId: course.id,
        data: { active: !active },
      });
      setActive(!active);
      dispatch(updateCourse({ id: course.id, changes: { active: !active } }));
      toast.success(`course ${course.e_title} activation status changed`);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setActiveLoading(false);
    }
  };

  return (
    <div className="relative right-0 top-0 text-blue-600 h-full me-1 flex items-center cursor-pointer">
      <Button onClick={openMenu}>
        <MoreHorizIcon className="text-cu-blue" />
      </Button>
      {menuOpen && (
        <div
          ref={menuRef}
          className="absolute top-full right-0 bg-white shadow-lg p-1 z-40 w-36 flex flex-col gap-y-2 items-start rounded-xl border"
        >
          <Button
            onClick={onView}
            sx={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "start",
            }}
            className="gap-x-2 w-full "
          >
            <PreviewIcon className="text-blue-500" sx={{ width: "20px" }} />
            <span>Review</span>
          </Button>
          {(user.profile.role === "admin" ||
            (user.profile.role === "leadInstructor" &&
              user.profile.groupCode === course.info.groupCode) ||
            (user.profile.role === "instructor" &&
              user.profile.uid === course.info.creatorId)) && (
            <>
              <Button
                onClick={onEdit}
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "start",
                }}
                className="w-full gap-x-2"
              >
                <EditIcon sx={{ width: "20px" }} />
                <span>Edit</span>
              </Button>

              <Button
                onClick={onDelete}
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "start",
                  color: "#bf0a30",
                }}
                className=" w-full gap-x-2"
              >
                <DeleteForeverOutlinedIcon sx={{ width: "20px" }} /> Delete
              </Button>
              <div className="flex">
                <FormControlLabel
                  label="Active"
                  labelPlacement="start"
                  control={
                    !activeLoading ? (
                      <Switch
                        color="success"
                        checked={active}
                        onChange={changeActive}
                        name="Active"
                      />
                    ) : (
                      <CircularProgress className="ml-3" size={24} />
                    )
                  }
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionsCourseMenu;
