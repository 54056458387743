import {
  faArrowDown,
  faArrowDown19,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUp,
  faArrowUpAZ,
  faClock,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import GroupRow from "./GroupRow";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addgroups, getAllGroups } from "../../../redux/groupsSlice";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  ThemeProvider,
} from "@mui/material";
import { readTable } from "../../../firebase.config";
import { tableTheme } from "../sharedComp/tableTheme";
import { useLoadMajors } from "../../../shared/customHooks/useLoadMajors";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";

const ShowAllGroups = () => {
  const loading = useLoadMajors(true);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const role = user?.profile?.role;
  const groups = useSelector(getAllGroups);
  const dispatch = useDispatch();

  const addNewGroup = () => {
    navigate("/adminpanel/addnewgroup");
  };

  return (
    // <div className="w-7xl m-auto mt-10 flex flex-col mx-10">
    //   <div className="mb-5 ">
    //     Groups:
    //     <IconButton onClick={addNewGroup} className="ms-3">
    //       <AddCircleIcon className="text-blue-500" />
    //     </IconButton>
    //   </div>

    //   <table className="table-auto ">
    //     <thead>
    //       <tr className="flex items-center border py-3">
    //         {/* <th className="flex px-3 py-2 gap-3">
    //           <button>Profile</button>
    //         </th> */}
    //         <th className="w-1/5">
    //           <button>Color</button>
    //         </th>
    //         {/* <th className="w-1/5">
    //           <button>Code</button>
    //         </th> */}
    //         <th className="w-1/5">
    //           <button>Name</button>
    //         </th>
    //         <th className="w-1/5">
    //           <button>نام</button>
    //         </th>
    //         <th className="w-1/5">
    //           <button>Lead Instructor</button>
    //         </th>
    //       </tr>
    //     </thead>
    //     <tbody className="border">
    //       {groups?.map((item, index) => {
    //         return     <tr className="border-b h-12 flex items-center relative text-center">
    //         <ActionsGroupMenu
    //           onView={viewGroup}
    //           onEdit={goToEditGroup}
    //           onDelete={deleteGroup}
    //           group={item}
    //         />

    //         <td className="w-1/5 flex items-center justify-center">
    //           <Avatar
    //             className="rounded-full border w-14 h-14 text-stroke"
    //             sx={{ bgcolor: `${item.color}` }}
    //           >
    //             {item.code}
    //           </Avatar>
    //           {/* <td className="w-1/5">
    //             <p>{item.code}</p>
    //           </td> */}
    //         </td>
    //         <td className="w-1/5">
    //           <p>{item.e_title}</p>
    //         </td>
    //         <td className="w-1/5">
    //           <p>{item.p_title}</p>
    //         </td>
    //         <td className="w-1/5 flex items-center">
    //           <Avatar
    //             className="rounded-full border w-14 h-14 object-contain"
    //             src={leadInstructor?.profile?.photoUrl?.url}
    //             alt={leadInstructor?.profile?.name}
    //           />
    //           <p className="ms-4">
    //             {leadInstructor?.profile?.name} {leadInstructor?.profile?.family}
    //           </p>
    //         </td>
    //       </tr>;
    //       })}
    //     </tbody>
    //   </table>
    // </div>
    <div className="w-7xl mx-auto mt-10 flex flex-col px-10">
      {/* Header Section */}
      {/* <LoadingOverlay isLoading={loading} /> */}
      <LoadingOverlay loading={loading} />
      <div className="mb-5 flex items-center">
        <span className="text-lg font-semibold">Groups:</span>
        <IconButton onClick={addNewGroup} className="ms-3">
          <AddCircleIcon className="text-blue-500" />
        </IconButton>
      </div>

      {/* Table Section */}
      <ThemeProvider theme={tableTheme}>
        <TableContainer component={Paper} className="shadow-md">
          <Table>
            {/* Table Head */}
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Color</strong>
                </TableCell>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>نام</strong>
                </TableCell>
                <TableCell>
                  <strong>Lead Instructor</strong>
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {groups?.map((item, index) => {
                return <GroupRow key={`itemname${index}`} item={item} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  );
};

export default ShowAllGroups;
