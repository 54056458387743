import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getAllGroups } from "../../../redux/groupsSlice";
import { useLoadMajors } from "../../../shared/customHooks/useLoadMajors";

const GroupCodeSection = ({ groupCode, setGroupCode, disabled }) => {
  const groups = useSelector(getAllGroups);
  const [trigger, setTrigger] = useState(false);
  const loading = useLoadMajors(trigger);

  const handleOpen = () => {
    if (groups && groups.length) return;
    setTrigger(true);
  };

  const handleChane = (e) => {
    const group = groups.find((g) => g.code === e.target.value);
    setGroupCode(group.code);
  };

  return (
    <div>
      <Box className={"w-full "} sx={{ margin: "auto" }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="group-select-label">Group</InputLabel>
          <Select
            labelId="group-select-label"
            value={groupCode}
            onChange={handleChane}
            onOpen={handleOpen}
            label="Group"
            disabled={Boolean(disabled)}
          >
            {loading ? (
              <MenuItem disabled>
                <Box display="flex" alignItems="center">
                  <CircularProgress size={20} sx={{ marginRight: 1 }} />
                  Loading...
                </Box>
              </MenuItem>
            ) : (
              groups.map((group) => (
                <MenuItem
                  key={group.id}
                  value={group.code}
                  // selected={groupCode === group.id}
                >
                  {group.code + " - " + group.e_title}
                </MenuItem>
              ))
            )}
          </Select>

          {!groupCode && (
            <FormHelperText sx={{ color: "#bf0a30" }}>
              You must specify group code
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </div>
  );
};

export default GroupCodeSection;
