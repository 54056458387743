import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Login from "./pages/auth/login/Login"
import AdminPanel from './panels/layout/adminLayout/AdminPanel';
import ChangePassword from './pages/auth/ChangePassword';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { base, db, chatWithGapi, userAuthChange, listSpaces, readTable, updateSignleRecord, readAllTable } from './firebase.config';
import { getUserProfile } from './redux/userSlice';
import { get, onValue, ref, set, update } from 'firebase/database';
import StudentPanel from './panels/layout/studentLayout/StudentPanel';
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandlerComp from './errorHandler/ErrorHandlerComp';
import GeneralPanel from './pages/GeneralPanel';
import { addUsers } from './redux/usersSlice';
import AuthenticatePassword from './pages/auth/AuthenticatePassword';


// import InstructorPanel from './panels/instructor/instructorLayout/InstructorPanel';


function App() {
  const [token, setToken] = useState("")
  const [loading, isLoading] = useState(true)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);
  const profile = user?.profile
  const role = user?.profile?.role

  // window.addEventListener('unhandledrejection', (event) => {
  //   console.error('Unhandled promise rejection:', event.promise, 'reason:', event.reason);
  //   // Handle the error globally
  // });

  // useEffect(() => {
  //   const myf = async () => {
  //     const res = await readAllTable({ tn: "resources" })
  //     console.log(res)
  //     const allp = res.map((r) => {
  //       if (r.type === "image") {
  //         updateSignleRecord({ tn: "resources", recId: r.id, data: { file: [{ url: r.image_url }] } })
  //       }
  //     })
  //     await Promise.all(allp)
  //     console.log("success")
  //   }
  //   myf()

  // }, [])




  useEffect(() => {
    const unsubscribe = userAuthChange(async (user) => {
      if (!user) return
      if (!profile?.email) {
        dispatch(getUserProfile(user))
      }
    })
    return unsubscribe
  }, [dispatch, profile?.email])

  useEffect(() => {
    return onValue(ref(db, "users"), (snap) => {
      const usersArr = Object.values(snap.val())
      dispatch(addUsers(usersArr.map(u => ({ id: u.profile.uid, ...u }))))
    })
  }, [])

  //upload files in airtable
  // useEffect(() => {
  //   // Define the base and table
  //   const myf = async () => {
  //     let courses = await readTable({ tn: "courses2" })
  //     // Fetch records with a URL but no attachment


  //     for (let record of courses) {


  //       // If there's a URL and no existing attachment
  //       // if (url && (!attachments || attachments.length === 0)) {
  //       try {
  //         // Save the attachment to the Attachment_Field
  //         if (record.course_image) {
  //           await updateSignleRecord({
  //             tn: "courses2", recId: record.id, data: {
  //               test: [{ url: record.course_image }],
  //             }
  //           });

  //         }
  //       } catch (error) {
  //         // console.error(`Failed to save attachment for record ${record.id}: ${error}`);
  //       }
  //     }
  //     // }
  //   }
  //   myf()

  // }, []);



  // useEffect(() => {
  //   const readAllTables = async () => {
  //     try {
  //       const majors = await readTable({ tn: "majors" })
  //       dispatch(addgroups(majors));
  //       // const users = await readTable({ tn: "users" })
  //       // dispatch(addUsers(users));
  //       const courses2 = await readTable({ tn: "courses2" })
  //       dispatch(addCourses(courses2));
  //       const lessons = await readTable({ tn: "lessons" })
  //       dispatch(addCourses(lessons));
  //       const assignments = await readTable({ tn: "assignments" })
  //       dispatch(addCourses(assignments));
  //       const resources = await readTable({ tn: "resources" })
  //       dispatch(addResources(resources));
  //     } catch (error) {
  //       console.log(error)
  //       toast.error(error.message)
  //     }
  //   }
  //   readAllTables()
  // }, [])

  // useEffect(() => {
  //   const resRef = ref(db, "/courses");
  //   return onValue(resRef, (snap) => {
  //     const coursesInGroup = Object.values(snap.val());

  //     const courses = coursesInGroup
  //       .map((g) => {
  //         if (g) return Object.values(g);
  //       })
  //       .flat()
  //       .filter((course) => course);

  //     courses && dispatch(addCourses(courses));
  //   });
  // }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorHandlerComp} onReset={() => {
      window.location.href = '/';
    }}>
      <Router>
        <Routes>
          {/* <Route path='/viewfile' element={<ViewFile />} /> */}
          <Route path={`/${role}panel/*`} element={role === "student" ? <StudentPanel /> : <AdminPanel />} />


          {/* {user?.profile?.role === "student" && <Route path='/studentpanel/*' element={<StudentPanel />} />}
          {user?.profile?.role === "instructor" && <Route path='/instructorpanel/*' element={<InstructorPanel />} />}
          {user?.profile?.role === "leadInstructor" && <Route path='/leadInstructorpanel/*' element={<LeadInstructorPanel />} />}
          {user?.profile?.role === "staff" && <Route path='/staffpanel/*' element={<StaffPanel />} />} */}
          <Route path="/login" element={<Login />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/authenticatepassword/:email" element={<AuthenticatePassword />} />
          {/* <Route path='/oauth/callback' element={<OAuthCallback />} /> */}
          <Route path='/*' element={<GeneralPanel />} />
          {/* <Route element={<Layout />}>
          <Route path='/register' element={<Register />} />
          <Route index element={<Home />} />
          <Route path='/resourses/*' element={<Resourses />} />
        </Route> */}
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;

