import {
  faArrowDown,
  faArrowDown19,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUp,
  faArrowUpAZ,
  faClock,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import UserRow from "./UserRow";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { getAllUsers } from "../../../redux/usersSlice";
import {
  Table,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  createTheme,
  FormControl,
  InputLabel,
  Box,
  ThemeProvider,
} from "@mui/material";

import { Search as SearchIcon } from "@mui/icons-material";
// import { ThemeProvider } from "@emotion/react";
import { tableTheme } from "../../shared/sharedComp/tableTheme";
const ShowAllUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortUsers, setSortedUsers] = useState([]);
  const [sortType, setSortType] = useState("createAt");
  const [order, setOrder] = useState(true);
  const [role, setRole] = useState("all");
  //   const [filteredUser, setFilteredUser] = useState([]);
  const [search, setSearch] = useState("");
  const searchRef = useRef();
  const users = useSelector(getAllUsers);
  const [filteredUsers, setFilteredUsers] = useState(users);

  const clickOnSorting = (e) => {
    if (sortType === e.target.value) {
      setOrder((prev) => !prev);
    } else {
      setSortType(e.target.value);
      setOrder(true);
    }
  };

  const addNewMember = () => {
    navigate("/adminpanel/addnewmember/createemail");
  };

  useEffect(() => {
    setSortedUsers((prev) => {
      if (!prev?.length) return;
      if (order) {
        const sortedData = prev.sort((a, b) =>
          b.profile[sortType]
            .toString()
            .localeCompare(a.profile[sortType].toString(), undefined, {
              numeric: true,
            })
        );
        return sortedData;
      } else {
        const sortedData = prev.sort((a, b) =>
          a.profile[sortType]
            .toString()
            .localeCompare(b.profile[sortType].toString(), undefined, {
              numeric: true,
            })
        );
        return sortedData;
      }
    });
  }, [sortType, order]);

  const searchUser = (e) => {
    setFilteredUsers(
      users.filter(
        (user) =>
          user.profile.name.toLocaleLowerCase().includes(e.target.value) ||
          user.profile.family.toLocaleLowerCase().includes(e.target.value)
      )
    );
  };

  return (
    <div className="w-full m-auto my-10 flex flex-col px-4">
      {/* Header Section */}
      <div className="mb-5 mx-6 flex items-center justify-between">
        <div>
          <strong>Members:</strong>
          <IconButton onClick={addNewMember} color="primary">
            <PersonAddIcon />
          </IconButton>
        </div>

        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={searchUser}
        />
      </div>

      {/* Table */}
      <ThemeProvider theme={tableTheme}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Email</strong>
                </TableCell>
                <TableCell>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small" // Adjust height of the FormControl
                  >
                    <InputLabel id="select-role">Role</InputLabel>
                    <Select
                      labelId="select-role"
                      label="Role"
                      onChange={(e) => setRole(e.target.value)}
                      variant="outlined"
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="student">Student</MenuItem>
                      <MenuItem value="professor">Professor</MenuItem>
                      <MenuItem value="staff">Staff</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <strong>Student Number</strong>
                </TableCell>
                <TableCell>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers
                ?.filter((user) =>
                  role === "all" ? user : user.profile.role === role
                )
                ?.map((item, index) => {
                  return <UserRow key={`itemname${index}`} item={item} />;
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  );
};

export default ShowAllUsers;
