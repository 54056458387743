import React, { useEffect, useState } from "react";
import { changePassword } from "../../firebase.config";
import { toast } from "react-toastify";
import { ReactComponent as PostLoadingComp } from "../../images/svg/postsvgLoading.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Paper,
} from "@mui/material";
// import LoadingButton from "@mui/lab/LoadingButton";
import cyrusUniLogo from "../../images/logo.webp";
import { isValidCyrusUniversityEmail } from "../../shared/utils/isValidCyrusUniversityEmail";
import axios from "axios";
const ChangePassword = () => {
  const navigate = useNavigate();
  const [googleToken, setGoogleToken] = useState("");
  const user = useSelector((state) => state.user);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(user?.profile?.email || "");

  const reqToChangePass = async () => {
    try {
      if (newPassword !== repeatNewPassword) {
        toast.error("Your Password And Repeated Password Are Different");
        return;
      }
      if (newPassword.length < 8) {
        toast.error("Minimum password length is 8 characters");
        return;
      }

      setLoading(true);
      await changePassword({
        email: user.profile.email,
        password: oldPassword,
        newPassword,
      });
      toast.success("Password successfully changed");
      if (user.profile.role === "student") {
        navigate("/studentpanel/profile");
      } else if (user.profile.role === "admin") {
        navigate("/adminpanel");
      } else if (user.profile.role === "instructor") {
        navigate("/instructorpanel/profile");
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const handleCredentialResponse = (response) => {
    console.log("Google ID Token:", response.credential);
    setGoogleToken(response.credential); // Save token in state
  };

  useEffect(() => {
    // Initialize Google Identity Services
    window.google.accounts.id.initialize({
      client_id:
        "777209034475-n7kn0j0fob1akp3pd6jokq524mth6cvv.apps.googleusercontent.com",
      callback: handleCredentialResponse,
      auto_select: true,
    });

    // Automatically prompt the user for token (silent refresh if user already logged in)
    window.google.accounts.id.prompt();
  }, []);

  const sendResetPasswordEmail = async () => {
    if (!isValidCyrusUniversityEmail(email)) {
      toast.error("this is not a valid cyrus university email!");
      return;
    }

    const res = await axios.get(
      `https://us-central1-cyrus-university-website.cloudfunctions.net/generateResetLink`,
      {
        headers: {
          Authorization: `Bearer ${googleToken}`, // Send Google token
        },
        params: { email }, // Pass the email as a query parameter
      }
    );
    window.open(res.data, "_blank", "noopener,noreferrer");
    navigate("/login");
  };
  return (
    <Container component="main" maxWidth="xs">
      <Paper
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 5,
        }}
        elevation={6}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img className="contain" src={cyrusUniLogo} alt="Logo" />
          <Typography variant="h5" component="h2" sx={{ mb: 1, mt: 7 }}>
            Change Password
          </Typography>
          {user.profile.email && (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Old Password"
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                size="small"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                size="small"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Repeat New Password"
                type="password"
                value={repeatNewPassword}
                onChange={(e) => setRepeatNewPassword(e.target.value)}
                size="small"
              />
              <Button
                onClick={reqToChangePass}
                loading={loading}
                variant="contained"
                color="primary"
                fullWidth
                disabled={!oldPassword || !newPassword || !repeatNewPassword}
                sx={{ mt: 2 }}
              >
                {loading && <PostLoadingComp />}
                Change Password
              </Button>
              <Typography sx={{ mt: 2 }}>Or</Typography>
            </>
          )}
          {/* New Button for Reset Password Email */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={user.profile.email}
          />
          <Button
            onClick={sendResetPasswordEmail} // Function to handle reset email
            variant="text"
            color="secondary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Send Reset Password Email
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ChangePassword;
