import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const lessonsAdapter = createEntityAdapter();

const initialState = lessonsAdapter.getInitialState();

const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {
    addLessons: lessonsAdapter.setAll,
    addLesson: lessonsAdapter.setOne,
    addMultipleLesson: lessonsAdapter.setMany,
    removeLesson: lessonsAdapter.removeOne,
    updateLesson: lessonsAdapter.updateOne,
  },
});

export const {
  addLessons,
  addLesson,
  removeLesson,
  updateLesson,
  addMultipleLesson,
} = lessonsSlice.actions;
export const getAllLessons = (state) =>
  lessonsAdapter.getSelectors().selectAll(state.lessons);
export const getLessonIds = (state) =>
  lessonsAdapter.getSelectors((state) => state.lessons).selectIds(state);

export const getLessonById = (state, lessonId) =>
  lessonsAdapter.getSelectors().selectById(state.lessons, lessonId);

export const getLessonEntities = (state) =>
  lessonsAdapter.getSelectors().selectEntities(state.lessons);

export const getLessonsByIds = createSelector(
  [getLessonEntities, (state, ids) => ids],
  (entities, ids) =>
    ids.map((id) => entities[id]).sort((a, b) => b?.create_at - a?.create_at)
);

export default lessonsSlice.reducer;
