import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { readAllTable } from "../../firebase.config";
import { addResources, getAllResources } from "../../redux/resourcesSlice";

export const useLoadResources = (trigger) => {
  const dispatch = useDispatch();
  const resources = useSelector(getAllResources);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      // images &&
      // images.length &&
      // pdfs &&
      // pdfs.length &&
      // videos &&
      // videos.length
      resources &&
      resources.length
    )
      return;
    if (!trigger) return;
    const readAllResources = async () => {
      setLoading(true);
      try {
        const allResources = await readAllTable({ tn: "resources" });
        dispatch(addResources(allResources));
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    readAllResources();
  }, [dispatch, resources, trigger]);
  return loading;
};
