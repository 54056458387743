import { Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import ViewFile from "../../../layout/ViewFile";
import VideoShow from "./showFile/VideoShow";
import PdfShow from "./showFile/PdfShow";
import ImageShow from "./showFile/ImageShow";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";

const LogoPdf = ({ attachments, size, name, recordId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    document.body.style.overflowY = "hidden";
    setIsModalOpen(true);
  };
  const closeModal = () => {
    document.body.style.overflowY = "scroll";
    setIsModalOpen(false);
  };

  return (
    <div className="flex wrap  overflow-x-auto">
      {attachments.map((att) => (
        <Grid
          item
          className="cursor-pointer"
          sx={{
            width: size,
            height: size * 1.5,
            position: "relative",
            overflow: "hidden",
            flexShrink: 0,
          }}
        >
          <div
            title={att?.filename}
            className="flex flex-col h-full w-full text-center  rounded-md p-1"
          >
            <div className="h-5/6 flex item-center justify-center">
              {att.thumbnails?.small?.url ? (
                <img
                  src={att.thumbnails?.large?.url}
                  alt="logo"
                  onClick={openModal}
                  className="contain m-auto max-h-full"
                  // style={{ maxHeight: "90%" }}
                />
              ) : (
                <div
                  className="flex flex-col items-center justify-center"
                  onClick={openModal}
                >
                  <BrokenImageIcon sx={{ fontSize: `${size / 2}px` }} />
                  <Typography>image not found</Typography>
                </div>
              )}
            </div>
            <div className="h-1/6 flex items-center justify-center ">
              <Typography noWrap>{att?.filename}</Typography>
            </div>
          </div>

          <ViewFile
            isOpen={isModalOpen}
            onClose={closeModal}
            title={att?.filename || ""}
          >
            <PdfShow pdf={att} />
          </ViewFile>
        </Grid>
      ))}
    </div>
  );
};

export default LogoPdf;
