import React, { useRef, useState } from "react";

import AddNamesInput from "../sharedComp/AddNamesInput";
import { toast } from "react-toastify";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import GroupCodeSection from "../sharedComp/GroupCodeSection";
import LoadingButton from "../../../shared/comp/LoadingButton";

import AddLinkIcon from "@mui/icons-material/AddLink";
import { getResourceById, updateResource } from "../../../redux/resourcesSlice";
import EachResource from "./EachResource";
import { addAttachment } from "../../../firebase.config";
import { useDispatch } from "react-redux";
import { simplifyAttachmentType } from "../../../shared/utils/simplifyAttachmentType";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddNewResource = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { recordId } = useParams();
  const [recId, setRecId] = useState(recordId);
  const resource = useSelector((state) => getResourceById(state, recId));
  const navigate = useNavigate();
  const [postLoading, setPostLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [name, setName] = useState(resource?.name || "");
  const [category, setCategory] = useState(resource?.category || []);
  const [explain, setExplain] = useState(resource?.explain || "");
  const [authors, setAuthors] = useState(resource?.authors || []);
  const [labels, setLabels] = useState(resource?.labels || []);
  const [imageUrl, setImageUrl] = useState(resource?.image_url || []);
  const [fileUrl, setFileUrl] = useState(resource?.file_url || []);
  const [attachment, setAttachment] = useState(resource?.file);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [groupCode, setGroupCode] = useState(
    resource?.group_code ||
      (user.profile.role === "instructor" ||
      user.profile.role === "leadInstructor"
        ? user.profile.groupCode
        : "")
  );

  //   const [openModal, setOpenModal] = useState(false);
  const [link, setLink] = useState(resource?.file_url);

  const handleClickOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleOk = () => {
    if (!link.includes("youtube")) {
      toast.error("only video url accepted");
      return;
    }
    setFileUrl(link);
    handleClose();
  };

  const onInputFile = async (e) => {
    try {
      setSelectedFile(e.target.files[0]);
      const record = await addAttachment({
        recId,
        setRecId,
        tn: "resources",
        fieldName: "file",
        file: e.target.files[0],
      });
      setAttachment(record.fields?.file);
      dispatch(updateResource(record.fields));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  console.log(recId);

  const removeOneSelectedFile = () => {};

  const submitData = async (e) => {
    e.preventDefault();

    try {
      setPostLoading(true);

      setPostLoading(false);
      navigate(`/${user.profile.role}panel/resources/video`);
    } catch (error) {
      toast.error(error.message);
      setPostLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={submitData} className="w-11/12 m-auto">
        <div style={{ padding: "10px", marginTop: "20px" }}>
          <Box className="flex items-center gap-x-3 mb-5">
            <Typography>RESOURCE ID : {resource?.resource_id}</Typography>
            <Button
              component="label"
              role={undefined}
              tabIndex={-1}
              size="small"
            >
              <CloudUploadIcon
                sx={{ fontSize: "40px" }}
                className="text-cu-blue"
              />
              <VisuallyHiddenInput
                type="file"
                onChange={onInputFile}
                accept={"/*"}
                multiple={false}
                ref={inputRef}
              />
            </Button>
            <button
              type="button"
              title="add video link"
              onClick={handleClickOpen}
            >
              <AddLinkIcon
                sx={{ fontSize: "40px" }}
                className="text-cu-blue cursor-pointer"
              />
            </button>
            <div className="ml-auto w-80">
              {/* {user.profile.role === "admin" && ( */}
              <GroupCodeSection
                groupCode={groupCode}
                setGroupCode={setGroupCode}
                disabled={
                  user.profile.role === "instructor" ||
                  user.profile.role === "leadInstructor"
                }
              />
              {/* )} */}
            </div>
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label="Name"
                  variant="outlined"
                  fullWidth
                  // required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  label="Category"
                  variant="outlined"
                  fullWidth
                  // required
                />
              </Grid>
            </Grid>
          </Box>

          <div className="flex justify-between">
            <Box mt={5} display="flex" flexDirection="column" gap={2}>
              <AddNamesInput
                placeholder="Authors"
                parentList={authors}
                setParentList={setAuthors}
              />
              <AddNamesInput
                placeholder="Labels"
                parentList={labels}
                setParentList={setLabels}
              />
            </Box>
            {(fileUrl || attachment) && (
              <EachResource
                file={resource?.file}
                type={
                  resource?.file[0]?.type
                    ? simplifyAttachmentType(resource?.file[0]?.type)
                    : ""
                }
                imageUrl={resource?.image_url}
                fileUrl={resource?.file_url}
                size={100}
              />
            )}
          </div>

          <TextField
            value={explain}
            onChange={(e) => setExplain(e.target.value)}
            label="Explanation"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />

          <div className="w-96 m-auto">
            <LoadingButton
              loading={postLoading}
              disabled={!fileUrl}
              name={"SUBMIT"}
            />
          </div>
        </div>
      </form>
      {/* Modal for link input */}
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle>Enter Video Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide the URL for the video you want to upload.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Video URL"
            type="url"
            fullWidth
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewResource;
