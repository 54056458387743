import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Box, Button, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";
import { getAllCourses } from "../../../redux/coursesSlice";
import { useLoadCourses } from "../../../shared/customHooks/useLoadCourses";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP + 100,
      width: 250,
    },
  },
  disablePortal: true, // Disable the default search functionality
};

// function arraysEqual(arr1, arr2) {
//   if (arr1.length !== arr2.length) {
//     return false;
//   }
//   const sortedArr1 = arr1.slice().sort();
//   const sortedArr2 = arr2.slice().sort();
//   for (let i = 0; i < sortedArr1.length; i++) {
//     if (sortedArr1[i] !== sortedArr2[i]) {
//       return false;
//     }
//   }
//   return true;
// }
function getStyles(course, selectedCourse, theme) {
  return {
    fontWeight: selectedCourse.includes(course)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const PrerequisiteAssign = ({ prerequisites, setPrerequisites }) => {
  const allCourses = useSelector(getAllCourses);
  const theme = useTheme();
  const [trigger, setTrigger] = useState(false);

  const loading = useLoadCourses(trigger);

  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredCourses, setFilteredCourses] = useState(allCourses);
  const inputRef = useRef(null);
  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value && e.target.value;
    setPrerequisites(value);
  };
  //   const addPrerequisite = () => {};

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpen = () => {
    setIsDropdownOpen(true);
    setTrigger(true);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    setSearchTerm("");
  };

  useEffect(() => {
    const newCourses = allCourses.filter((course) =>
      course?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    console.log(newCourses);
    if (searchTerm) {
      setFilteredCourses(newCourses);
    } else {
      setFilteredCourses(allCourses);
    }
  }, [allCourses, searchTerm]);

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <FormControl className="w-full">
      <Select
        multiple
        displayEmpty
        value={prerequisites}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length < 1) {
            return <em>Select Prerequisites For Course</em>;
          }
          const selCrs = allCourses.filter((course) =>
            selected.includes(course?.id)
          );

          return (
            <div className="text-wrap">
              {selCrs.map((crs) => (
                <p>
                  {crs?.title} - {crs?.name}
                </p>
              ))}
            </div>
          );
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        {
          isDropdownOpen &&
            // <div className="px-3">
            (loading ? (
              <MenuItem disabled>
                <Box display="flex" alignItems="center">
                  <CircularProgress size={20} sx={{ marginRight: 1 }} />
                  Loading...
                </Box>
              </MenuItem>
            ) : (
              <div
                className="px-3"
                onKeyDown={(e) => e.stopPropagation()}
                onClickCapture={stopImmediatePropagation}
              >
                <TextField
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  inputRef={inputRef}
                />
              </div>
            ))
          // </div>
        }
        {filteredCourses?.map((course) => (
          <MenuItem
            key={course?.id}
            value={course?.id}
            style={getStyles(course, prerequisites, theme)}
            // onKeyDown={(e) => e.stopPropagation()}
          >
            <Checkbox checked={prerequisites.indexOf(course?.id) > -1} />
            <ListItemText primary={`${course?.title} ${course?.name}`} />
          </MenuItem>
        ))}

        {/* <div className="flex w-full items-center justify-center sticky bottom-0 bg-white py-1">
          <Button
            variant="contained"
            color="primary"
            onClick={addPrerequisite}
            disabled={arraysEqual(
              [undefined, ...prerequisiteCourses],
              selectedCourse
            )}
          >
            {" "}
            Save Changes{" "}
          </Button>
        </div> */}
      </Select>
    </FormControl>
  );
};

export default PrerequisiteAssign;
