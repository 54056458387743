import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { readSingleRecord } from "../../firebase.config";
import {
  addLesson,
  addMultipleLesson,
  getLessonIds,
} from "../../redux/lessonsSlice";

export const useLoadMultipleLesson = (trigger, ids) => {
  const dispatch = useDispatch();
  const lessonIds = useSelector(getLessonIds); // Adjust according to your state shape

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!trigger) return;
    const idsToGet = ids.filter((id) => !lessonIds.includes(id));

    const readMultipleLesson = async () => {
      setLoading(true);
      try {
        const lessonsPromise = idsToGet.map(async (id) => {
          return await readSingleRecord({ tn: "lessons", recId: id });
        });
        const lessons = await Promise.all(lessonsPromise);
        dispatch(addMultipleLesson(lessons));
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    readMultipleLesson();
  }, [lessonIds, dispatch, ids, trigger]);

  return loading;
};
