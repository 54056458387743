import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useLoadResources } from "../../../shared/customHooks/useLoadResources";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";
import { useSelector } from "react-redux";
import { getAllResources } from "../../../redux/resourcesSlice";
import ResourcesHeader from "./ResourcesHeader";
import EachResource from "./EachResource";
import { simplifyAttachmentType } from "../../../shared/utils/simplifyAttachmentType";

const ShowResources = () => {
  const loading = useLoadResources(true);
  const resources = useSelector(getAllResources);
  const [size, setSize] = useState(150);

  return (
    <div>
      <Box className={"w-11/12 m-auto"}>
        <LoadingOverlay loading={loading} />
      </Box>
      <ResourcesHeader size={size} setSize={setSize} />

      <Box
        sx={{
          padding: 2,
          backgroundColor: "#f5f5f5", // Light gray background
        }}
        className="flex justify-center"
      >
        <Grid className="flex gap-3" container>
          {resources.map((resource, index) => (
            // <Grid
            //   item
            //   xs={6}
            //   sm={4}
            //   md={3}
            //   sx={{
            //     // position: "relative",
            //     // paddingBottom: "100%", // This ensures a square shape
            //     width: size,
            //     height: size,
            //     backgroundColor: "#f5f5f5",
            //     borderRadius: 2,
            //     overflow: "hidden",
            //     boxShadow: 2,
            //   }}
            //   className="group transition-all hover:shadow-lg hover:scale-105"
            // >
            <EachResource
              recordId={resource.id}
              file={resource.file}
              type={
                resource?.type ||
                simplifyAttachmentType(resource?.file[0]?.type)
              }
              name={resource?.name || resource?.file[0]?.filename}
              imageUrl={resource?.image_url}
              fileUrl={resource?.file_url}
              size={size}
            />
            // </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default ShowResources;
