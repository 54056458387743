import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { readTable } from "../../firebase.config";
import { addLessons, getAllLessons } from "../../redux/lessonsSlice";

export const useLoadLessons = (trigger) => {
  const dispatch = useDispatch();
  const allLessons = useSelector(getAllLessons); // Adjust according to your state shape
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (allLessons && allLessons.length) return;
    if (!trigger) return;
    const readLessonsTable = async () => {
      setLoading(true);
      try {
        const lessons = await readTable({ tn: "lessons" });
        dispatch(addLessons(lessons));
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    readLessonsTable();
  }, [allLessons, dispatch, trigger]);

  return loading;
};
