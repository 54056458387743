import React, { useState } from "react";
import ViewFile from "../../../layout/ViewFile";
import VideoShow from "./showFile/VideoShow";
import { Grid } from "@mui/material";

const LogoVideo = ({ imageSrc, videoUrl, name, size, recordId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    document.body.style.overflowY = "hidden";
    setIsModalOpen(true);
  };
  const closeModal = () => {
    document.body.style.overflowY = "scroll";
    setIsModalOpen(false);
  };

  return (
    <Grid
      item
      className="cursor-pointer"
      sx={{
        width: size * 1.5,
        height: size,
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* <div
          style={{
            width: size,
            height: size,
            position: "relative",
            border: "1px solid #999",
            overflow: "hidden",
          }}
        > */}
      <div
        title={name}
        className="flex flex-col h-full w-full text-center  rounded-md p-1"
      >
        <div className="h-5/6 flex items-center justify-center">
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="logo"
              onClick={openModal}
              className="contain m-auto max-h-full"
              // style={{ maxHeight: "90%" }}
            />
          ) : (
            <div className="w-full h-full" onClick={openModal}>
              <iframe
                // width="1080"
                // height="720"
                src={videoUrl}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;modestbranding: 1; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                className="h-full w-full"
              ></iframe>
              <div className="absolute inset-0 w-full h-full bg-transparent cursor-pointer"></div>
            </div>
          )}
        </div>
        <div>{name}</div>
        <ViewFile isOpen={isModalOpen} onClose={closeModal} title={name || ""}>
          <VideoShow url={videoUrl} />
        </ViewFile>
      </div>
    </Grid>
  );
};

export default LogoVideo;
