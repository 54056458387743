import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ImageListItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { deleteSignleRecord } from "../../../firebase.config";
import { toast } from "react-toastify";

const ResourceMenuAction = ({ recordId }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/${user.profile.role}panel/resources/editresource/${recordId}`);
    handleMenuClose();
  };

  const handleDelete = async () => {
    try {
      if (window.confirm("are you sure to delete this resource ?") === false)
        return;
      await deleteSignleRecord({ tn: "resources", recId: recordId });
      handleMenuClose();
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  return (
    <>
      <IconButton
        size="small"
        aria-label="more"
        aria-controls="menu"
        aria-haspopup="true"
        sx={{
          position: "absolute",
          top: "4px",
          right: "4px",
          zIndex: "20",
          backgroundColor: "#eee",
          color: "black",
        }}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default ResourceMenuAction;
