import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { sendGrade } from "../../../firebase.config";
import { useSelector } from "react-redux";
import {
  getAllUsers,
  getUserById,
  getUsersByIds,
} from "../../../redux/usersSlice";

import {
  Select,
  MenuItem,
  Button,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
} from "@mui/material";
import {} from "@mui/material";
import CustomConfirm from "../sharedComp/CustomConfirm";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import TimeShow from "../../../shared/utils/TimeShow";
import { useLoadCourses } from "../../../shared/customHooks/useLoadCourses";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";
import { getAllGroups, getGroupById } from "../../../redux/groupsSlice";
import { getCourseById } from "../../../redux/coursesSlice";

const EachGradeToStudentRow = ({ asCourse, selectedUser }) => {
  const user = useSelector((state) => state.user);
  const course = useSelector((state) => getCourseById(state, asCourse.id));
  const initGrade =
    (selectedUser?.reportCards &&
      selectedUser?.reportCards[asCourse?.Course_ID] &&
      selectedUser?.reportCards[asCourse?.Course_ID]?.grade) ||
    "";
  const [currentGrade, setCurrentGrade] = useState(initGrade);
  const [currentStatus, setCurrentStatus] = useState(
    initGrade === "" ? "under process" : initGrade >= 60 ? "passed" : "failed"
  );
  const [change, setChange] = useState(false);

  const [grade, setGrade] = useState(initGrade);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const sendGradeToDb = async () => {
    try {
      await sendGrade({
        uid: selectedUser.profile.uid,
        grade,
        gradeEmailGiver: user.profile.email,
        courseId: asCourse.Course_ID,
      });
      toast.success("grade successfully changes");
      setCurrentGrade(grade);
      setCurrentStatus(grade >= 60 ? "passed" : "failed");
    } catch (error) {
      toast.error(error.message);
    }
    // Handle the confirmation action here
    setChange(false);
    setOpen(false);
  };

  const handleConfirm = async () => {
    setOpen(true);
  };

  // useEffect(() => {
  //   setCurrentStatus(
  //     selectedUser?.reportCards &&
  //       selectedUser?.reportCards[asCourse?.course_id] &&
  //       selectedUser?.reportCards[asCourse?.course_id]?.grade >= 60
  //       ? "passed"
  //       : selectedUser?.reportCards[asCourse?.course_id]?.grade < 60
  //       ? "failed"
  //       : "under process"
  //   );
  // }, [asCourse?.course_id, selectedUser]);

  return (
    <TableRow key={asCourse?.course_id} className="h-28 capitalize">
      <TableCell>{asCourse?.title}</TableCell>
      <TableCell>
        {
          <Box>
            <Typography className="capitalize">
              {asCourse.instructor}
            </Typography>
          </Box>
        }
      </TableCell>
      <TableCell>
        <TimeShow date={new Date(course?.create_at)} />
      </TableCell>
      <TableCell>
        {course?.duration} {course?.duration_unit}
      </TableCell>
      <TableCell>{course?.unit}</TableCell>
      <TableCell>
        {currentStatus === "passed" ? (
          <div>
            <CheckBoxIcon color="success" />
            {currentStatus}
          </div>
        ) : currentStatus === "failed" ? (
          <div>
            <CloseIcon sx={{ color: "red" }} />
            {currentStatus}
          </div>
        ) : (
          <div>
            <HourglassTopIcon />
            {currentStatus}
          </div>
        )}
      </TableCell>
      {user.profile.role === "admin" ||
      ((user.profile.role === "instructor" ||
        user.profile.role === "leadInstructor") &&
        user?.assignedCourses &&
        Object.keys(user?.assignedCourses)?.includes(asCourse?.course_id)) ? (
        <TableCell className="w-60">
          {!change ? (
            <div className="flex items-center gap-x-4">
              <p>{currentGrade}</p>
              <Button onClick={() => setChange(true)}>Edit</Button>
            </div>
          ) : (
            <div className="flex items-center gap-x-4">
              <TextField
                type="number"
                id="outlined-basic"
                label="Grade"
                variant="outlined"
                size="small"
                className="w-20"
                onChange={(e) => setGrade(e.target.value)}
                value={grade}
              />
              <div className="flex flex-col">
                <Button onClick={handleConfirm}>Ok</Button>
                <Button onClick={() => setChange(false)}>Cancle</Button>
              </div>
            </div>
          )}
        </TableCell>
      ) : (
        <TableCell className="w-60">
          {currentGrade && currentGrade >= 90
            ? "A"
            : currentGrade >= 80
            ? "B+"
            : currentGrade >= 70
            ? "B"
            : currentGrade >= 60
            ? "C"
            : currentGrade < 60
            ? "D"
            : ""}
        </TableCell>
      )}
      <CustomConfirm
        open={open}
        onClose={handleClose}
        onConfirm={sendGradeToDb}
        text={`you are going to set grade ${grade} for course ${course.name} for ${selectedUser.profile.email}. are you want to proceed ?`}
      />
    </TableRow>
  );
};

export default EachGradeToStudentRow;
